import { observable, decorate } from 'mobx';

class StoreEasyShop {
    isEnabled = false
    isEasyShopForm = false
    easyChoice = 1
    baID = ''
    referralCode = ''
    referralBy = ''
    referralValue = ''
    referralText = ''
    referenceDetail = ''
    referralAddress = {
        delivery: {},
        pickUp: {},
        aramex: {}
    }
}

decorate(
    StoreEasyShop, {
        isEnabled: observable.struct,
        isEasyShopForm: observable.struct,
        easyChoice: observable.struct,
        baID: observable.struct,
        referralCode: observable.struct,
        referralBy: observable.struct,
        referralValue: observable.struct,
        referralText: observable.struct,
        referenceDetail: observable.struct,
        referralAddress: observable.struct,
    }
)

export var storeEasyShop = new StoreEasyShop()