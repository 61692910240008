/* eslint-disable */
import { useState } from 'react'
import * as K from './configs/Keywords'
import { CountryCoordinates, CountryOfflineProduction, CountryAvailable, CountryCode3toFull, CountryCodeToFull, CountryCode } from './configs/Country'
import { isProduction, isDevelopment, isLocalhost } from './configs/ConfigsHeader'
import { isNothing, testArray } from './utils/Utils'
import { store } from '../stores/MainStore'
import { getCountryByPoint, getNearestCountry } from './utils/GeolocationUtils'

/* const geolocationConfigs = {
    maximumAge: 0, 
    timeout: Infinity, 
    enableHighAccuracy: true
} */
const hideOfflineCountryWhen = isProduction() // when we should hide offline country

let coords = {latitude: null, longitude: null}

const setCoords = (newCoords) => {
    coords = {[K.Latitude]: newCoords.latitude, [K.Longitude]: newCoords.longitude}
}

const calculateDistance = (pointA, pointB) => {
    const deg2rad = (deg) => {
        return deg * (Math.PI / 180)
    }

    const lat1 = pointA.latitude
    const lon1 = pointA.longitude

    const lat2 = pointB.latitude
    const lon2 = pointB.longitude

    const R = 6371 // Radius of the earth in km
    const Δφ = deg2rad(lat2 - lat1)
    const Δλ = deg2rad(lon2 - lon1)
    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return d
}

const redirectToUshop = () => {
    window.location = 'https://ushop.unicity.com'
    throw ': Redirect to ushop.'
}

const onBrowserGeolocationSuccess = (response) => {
    setCoords({[K.Latitude]: response.latitude, [K.Longitude]: response.longitude})
    //console.log('onBrowserGeolocationSuccess')
    const countryCode = response.country_code
    const country = CountryCodeToFull[countryCode]
    if (testArray(country, CountryAvailable)) {
        // removed offline country from available list
        if (hideOfflineCountryWhen && CountryOfflineProduction[country] === true) {
            redirectToUshop()
        } else {
            window.location.href = `/${country}`
        }
    } else {
        redirectToUshop()
    }
    throw ': Redirect to nearest country.'
}

const onBrowserGeolocationFail = (error) => {
    console.log('API GeolocationFail:', error)
    window.location.href = '/malaysia'
    /* switch (error.code) {
        case error.TIMEOUT:
            console.error('Browser geolocation error, Timeout.')
            break
        case error.PERMISSION_DENIED:
            console.error('Browser geolocation error, Permission denied.')
            break
        case error.POSITION_UNAVAILABLE:
            console.error('Browser geolocation error, Position unavailable.')
            break
        default:
            console.error('Browser geolocation error,', error)
            break
    } */
}

const callAPI = (doneCallback, errorCallback) => {
    $.ajax({
        'type': 'GET',
        'async': false,
        'url': 'https://api.ipstack.com/check?access_key=16ea76bbc5cb14d33d5f5e5bb36690b3',
        'success': (response) => {
            doneCallback(response, true)
        },
        'error': (response) => {
            errorCallback(response, true)
        }
    });
}

const detectGeolocation = (country) => {
    let knowWhere = false

    if (isNothing(country) === false) {
        CountryAvailable.forEach(avail => {
            if (avail === country) knowWhere = true
        })
        if (hideOfflineCountryWhen && CountryOfflineProduction[country] === true) {
            knowWhere = false
        }
    }

    console.log(knowWhere ? 'I know where it is.' : 'I have no idea where it is.' )

    if (knowWhere === false) {
        redirectToUshop()
    } else {
        store.isCheckCountry = true
    }
}

export { detectGeolocation, calculateDistance }