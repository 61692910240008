import React, { Component } from 'react';

class Spinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="container">
                <div className="sk-folding-cube loading-image-container">
                    <div className="loadingImage" />
                </div>
            </div>
        )
    }
}

export default Spinner;
