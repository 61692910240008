// Country
export const All = 'all'
export const Thailand = 'thailand'
export const Malaysia = 'malaysia'
export const Singapore = 'singapore'
export const Philippines = 'philippines'
export const Indonesia = 'indonesia'
export const Australia = 'australia'
export const Newzealand = 'newzealand'
export const Japan = 'japan'
export const Korea = 'korea'
export const Hongkong = 'hongkong'
export const Taiwan = 'taiwan'
export const MENA = 'mena'
export const Vietnam = 'vietnam'
export const Myanmar = 'myanmar'
export const Cambodia = 'cambodia'
export const Laos = 'laos'
export const Algeria = 'algeria'
export const Bahrain = 'bahrain'
export const Egypt = 'egypt'
export const Iran = 'iran'
export const Iraq = 'iraq'
export const Israel = 'israel'
// Language
export const Arabic = 'arabic'
export const Bahasa = 'bahasa'
export const Japanese = 'japanese'
export const Korean = 'korean'
export const Thai = 'thai'
export const Vietnamese = 'vietnamese'
export const Khmer = 'khmer'
export const Burmese = 'burmese'
export const Lao = 'lao'
// Stage
export const Production = 'production'
export const Development = 'development'
export const Localhost = 'localhost'
// Footer
export const English = 'english'
export const Native = 'native'
// Payment
export const OCBC = 'ocbc'
export const UnionPay = 'unionpay'
export const Hydra = 'hydra'
// Geolocation
export const Longitude = 'longitude'
export const Latitude = 'latitude'
// Variables
export const Unknown = 'unknown'

// Price Level
export const WholesalePrice = 'wholesale_price'
export const RetailPrice = 'retail_price'
export const PreferredPrice = 'preferred_price'
export const EmployeePrice = 'employee_price'
// Customer Type for API
export const Associate = 'Associate'
export const Customer = 'Customer'
export const PreferredCustomer = 'PreferredCustomer'
export const Aramex = 'Aramex'
export const Family = 'Family'
// Shipping Method for API
export const Expedited = 'Expedited'
export const Economy = 'Economy'