import { observable, action } from 'mobx';

export const FS = observable({
    core: undefined,

    /** Initiated the core of Fullstory API.
     * @param {(value: any) => void} value */
    init: action(function(value){ this.core = value }),
    get identify() { return this.core.identify },
    get shutdown() { return this.core.shutdown },
    get restart() { return this.core.restart }
})