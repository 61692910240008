import Keywords from './Keywords'
import { StoreConfigs } from './Configs';

/** Get products from API server.
 *  End-point: `https://member-calls4.unicity.com/api/unishop/v1/global/getproducts?country_code=[country_code]&secret=yes` */
export const Products = 'https://member-calls4.unicity.com/api/unishop/v1/global/getproducts?country_code=XXX&secret=yes'
/** Get products from API server.
 *  End-point: `https://member-calls4.unicity.com/api/unishop/v1/global/getproducts?country_code=[country_code]` */
export const ProductsFinal = 'https://member-calls4.unicity.com/api/unishop/v1/global/getproducts?country_code=XXX'


export const XAUDeliveryRemark = 'xau_remote_code_remark'
export const XAURemoteCode = [
    "0832",
    "0835",
    "0840",
    "0845",
    "0850",
    "0852",
    "0860",
    "0870",
    "0871",
    "0872",
    "0880",
    "0881",
    "0885",
    "0886",
    "2310",
    "2311",
    "2312",
    "2321",
    "2324",
    "2328",
    "2333",
    "2350",
    "2365",
    "2369",
    "2379",
    "2386",
    "2390",
    "2395",
    "2408",
    "2415",
    "2420",
    "2439",
    "2443",
    "2452",
    "2460",
    "2462",
    "2469",
    "2482",
    "2483",
    "2484",
    "2487",
    "2488",
    "2527",
    "2528",
    "2529",
    "2530",
    "2533",
    "2534",
    "2535",
    "2536",
    "2537",
    "2538",
    "2539",
    "2540",
    "2541",
    "2545",
    "2546",
    "2548",
    "2549",
    "2550",
    "2551",
    "2575",
    "2576",
    "2577",
    "2578",
    "2579",
    "2580",
    "2581",
    "2582",
    "2583",
    "2584",
    "2585",
    "2586",
    "2587",
    "2588",
    "2590",
    "2594",
    "2621",
    "2622",
    "2623",
    "2624",
    "2625",
    "2626",
    "2627",
    "2628",
    "2630",
    "2631",
    "2632",
    "2633",
    "2640",
    "2641",
    "2642",
    "2643",
    "2644",
    "2645",
    "2646",
    "2647",
    "2648",
    "2649",
    "2650",
    "2651",
    "2652",
    "2653",
    "2655",
    "2656",
    "2658",
    "2659",
    "2660",
    "2661",
    "2663",
    "2665",
    "2666",
    "2668",
    "2669",
    "2671",
    "2672",
    "2675",
    "2678",
    "2680",
    "2681",
    "2700",
    "2701",
    "2702",
    "2703",
    "2705",
    "2706",
    "2707",
    "2710",
    "2711",
    "2712",
    "2713",
    "2714",
    "2715",
    "2716",
    "2717",
    "2720",
    "2721",
    "2722",
    "2725",
    "2726",
    "2727",
    "2729",
    "2730",
    "2731",
    "2732",
    "2733",
    "2734",
    "2735",
    "2736",
    "2737",
    "2738",
    "2739",
    "2785",
    "2786",
    "2787",
    "2790",
    "2791",
    "2792",
    "2793",
    "2794",
    "2795",
    "2797",
    "2798",
    "2799",
    "2800",
    "2803",
    "2804",
    "2805",
    "2806",
    "2807",
    "2808",
    "2809",
    "2810",
    "2820",
    "2821",
    "2823",
    "2824",
    "2825",
    "2827",
    "2828",
    "2829",
    "2830",
    "2831",
    "2832",
    "2833",
    "2834",
    "2835",
    "2836",
    "2839",
    "2840",
    "2842",
    "2843",
    "2844",
    "2845",
    "2846",
    "2847",
    "2848",
    "2849",
    "2850",
    "2852",
    "2864",
    "2865",
    "2866",
    "2867",
    "2868",
    "2869",
    "2870",
    "2871",
    "2873",
    "2874",
    "2875",
    "2876",
    "2877",
    "2878",
    "2879",
    "2880",
    "2898",
    "2899",
    "3222",
    "3223",
    "3224",
    "3225",
    "3226",
    "3227",
    "3230",
    "3231",
    "3232",
    "3235",
    "3236",
    "3237",
    "3238",
    "3239",
    "3240",
    "3241",
    "3243",
    "3249",
    "3260",
    "3265",
    "3267",
    "3268",
    "3269",
    "3277",
    "3278",
    "3279",
    "3280",
    "3281",
    "3282",
    "3283",
    "3284",
    "3285",
    "3286",
    "3287",
    "3289",
    "3292",
    "3293",
    "3294",
    "3300",
    "3301",
    "3303",
    "3304",
    "3305",
    "3309",
    "3310",
    "3311",
    "3312",
    "3314",
    "3315",
    "3317",
    "3318",
    "3319",
    "3321",
    "3322",
    "3323",
    "3324",
    "3325",
    "3328",
    "3329",
    "3330",
    "3331",
    "3332",
    "3333",
    "3334",
    "3335",
    "3338",
    "3340",
    "3342",
    "3345",
    "3351",
    "3352",
    "3353",
    "3354",
    "3357",
    "3360",
    "3361",
    "3363",
    "3364",
    "3370",
    "3371",
    "3373",
    "3375",
    "3377",
    "3381",
    "3384",
    "3385",
    "3387",
    "3388",
    "3390",
    "3391",
    "3392",
    "3393",
    "3395",
    "3396",
    "3400",
    "3401",
    "3407",
    "3409",
    "3412",
    "3413",
    "3414",
    "3415",
    "3418",
    "3419",
    "3420",
    "3423",
    "3424",
    "3432",
    "3433",
    "3434",
    "3435",
    "3437",
    "3438",
    "3440",
    "3441",
    "3442",
    "3444",
    "3446",
    "3447",
    "3448",
    "3450",
    "3451",
    "3453",
    "3455",
    "3458",
    "3460",
    "3461",
    "3462",
    "3463",
    "3465",
    "3467",
    "3468",
    "3469",
    "3472",
    "3477",
    "3478",
    "3480",
    "3482",
    "3483",
    "3485",
    "3487",
    "3488",
    "3489",
    "3490",
    "3491",
    "3494",
    "3496",
    "3498",
    "3500",
    "3501",
    "3502",
    "3505",
    "3506",
    "3507",
    "3509",
    "3512",
    "3515",
    "3516",
    "3517",
    "3518",
    "3520",
    "3521",
    "3522",
    "3523",
    "3525",
    "3527",
    "3529",
    "3530",
    "3531",
    "3533",
    "3537",
    "3540",
    "3542",
    "3544",
    "3546",
    "3549",
    "3552",
    "3556",
    "3557",
    "3558",
    "3559",
    "3561",
    "3562",
    "3563",
    "3564",
    "3565",
    "3566",
    "3567",
    "3568",
    "3570",
    "3571",
    "3572",
    "3573",
    "3575",
    "3576",
    "3579",
    "3580",
    "3581",
    "3583",
    "3584",
    "3585",
    "3586",
    "3588",
    "3589",
    "3590",
    "3591",
    "3594",
    "3595",
    "3596",
    "3597",
    "3599",
    "3607",
    "3608",
    "3610",
    "3612",
    "3614",
    "3615",
    "3616",
    "3617",
    "3618",
    "3619",
    "3620",
    "3621",
    "3622",
    "3623",
    "3624",
    "3629",
    "3630",
    "3631",
    "3632",
    "3633",
    "3634",
    "3635",
    "3636",
    "3637",
    "3638",
    "3639",
    "3640",
    "3641",
    "3643",
    "3644",
    "3646",
    "3647",
    "3649",
    "3658",
    "3659",
    "3660",
    "3661",
    "3662",
    "3663",
    "3664",
    "3665",
    "3666",
    "3669",
    "3670",
    "3671",
    "3672",
    "3673",
    "3675",
    "3676",
    "3677",
    "3678",
    "3682",
    "3683",
    "3685",
    "3686",
    "3687",
    "3688",
    "3689",
    "3690",
    "3691",
    "3694",
    "3695",
    "3697",
    "3698",
    "3699",
    "3700",
    "3701",
    "3704",
    "3705",
    "3707",
    "3708",
    "3709",
    "3711",
    "3712",
    "3713",
    "3714",
    "3715",
    "3717",
    "3718",
    "3719",
    "3720",
    "3722",
    "3723",
    "3724",
    "3725",
    "3726",
    "3727",
    "3728",
    "3730",
    "3732",
    "3733",
    "3735",
    "3736",
    "3737",
    "3738",
    "3739",
    "3740",
    "3741",
    "3744",
    "3746",
    "3747",
    "3749",
    "3750",
    "3751",
    "3753",
    "3754",
    "3755",
    "3756",
    "3757",
    "3758",
    "3759",
    "3760",
    "3761",
    "3762",
    "3763",
    "3764",
    "3765",
    "3766",
    "3767",
    "3770",
    "3775",
    "3777",
    "3778",
    "3779",
    "3781",
    "3782",
    "3797",
    "3799",
    "3810",
    "3812",
    "3813",
    "3814",
    "3815",
    "3816",
    "3818",
    "3820",
    "3821",
    "3822",
    "3823",
    "3824",
    "3825",
    "3833",
    "3844",
    "3850",
    "3851",
    "3852",
    "3853",
    "3854",
    "3856",
    "3857",
    "3858",
    "3859",
    "3860",
    "3862",
    "3864",
    "3865",
    "3869",
    "3870",
    "3871",
    "3873",
    "3874",
    "3875",
    "3878",
    "3880",
    "3882",
    "3885",
    "3886",
    "3887",
    "3888",
    "3889",
    "3890",
    "3891",
    "3892",
    "3893",
    "3895",
    "3896",
    "3898",
    "3900",
    "3902",
    "3903",
    "3904",
    "3909",
    "3916",
    "3918",
    "3919",
    "3920",
    "3921",
    "3922",
    "3923",
    "3925",
    "3926",
    "3927",
    "3928",
    "3929",
    "3931",
    "3932",
    "3933",
    "3934",
    "3935",
    "3944",
    "3945",
    "3946",
    "3950",
    "3951",
    "3953",
    "3954",
    "3956",
    "3957",
    "3958",
    "3959",
    "3960",
    "3962",
    "3964",
    "3965",
    "3966",
    "3967",
    "3971",
    "3979",
    "3980",
    "3981",
    "3984",
    "3987",
    "3988",
    "3990",
    "3991",
    "3992",
    "3995",
    "3996",
    "4287",
    "4352",
    "4353",
    "4354",
    "4355",
    "4356",
    "4357",
    "4358",
    "4359",
    "4360",
    "4361",
    "4362",
    "4363",
    "4364",
    "4365",
    "4370",
    "4371",
    "4372",
    "4373",
    "4374",
    "4375",
    "4376",
    "4377",
    "4378",
    "4380",
    "4381",
    "4382",
    "4383",
    "4384",
    "4385",
    "4387",
    "4388",
    "4390",
    "4400",
    "4401",
    "4402",
    "4403",
    "4404",
    "4405",
    "4406",
    "4407",
    "4408",
    "4410",
    "4411",
    "4412",
    "4413",
    "4415",
    "4416",
    "4417",
    "4418",
    "4419",
    "4420",
    "4421",
    "4422",
    "4423",
    "4424",
    "4425",
    "4426",
    "4427",
    "4428",
    "4454",
    "4455",
    "4461",
    "4462",
    "4465",
    "4467",
    "4468",
    "4470",
    "4471",
    "4472",
    "4474",
    "4475",
    "4477",
    "4478",
    "4479",
    "4480",
    "4481",
    "4482",
    "4486",
    "4487",
    "4488",
    "4489",
    "4490",
    "4491",
    "4492",
    "4493",
    "4494",
    "4496",
    "4497",
    "4498",
    "4568",
    "4569",
    "4580",
    "4581",
    "4600",
    "4601",
    "4605",
    "4606",
    "4608",
    "4610",
    "4611",
    "4612",
    "4613",
    "4614",
    "4615",
    "4620",
    "4621",
    "4625",
    "4626",
    "4627",
    "4630",
    "4650",
    "4655",
    "4659",
    "4660",
    "4662",
    "4670",
    "4671",
    "4673",
    "4674",
    "4676",
    "4677",
    "4678",
    "4680",
    "4694",
    "4695",
    "4697",
    "4698",
    "4699",
    "4700",
    "4701",
    "4702",
    "4703",
    "4704",
    "4705",
    "4706",
    "4707",
    "4709",
    "4712",
    "4713",
    "4714",
    "4715",
    "4716",
    "4717",
    "4718",
    "4719",
    "4720",
    "4721",
    "4722",
    "4723",
    "4724",
    "4725",
    "4726",
    "4727",
    "4728",
    "4730",
    "4731",
    "4732",
    "4733",
    "4735",
    "4736",
    "4737",
    "4738",
    "4739",
    "4740",
    "4741",
    "4742",
    "4743",
    "4744",
    "4745",
    "4746",
    "4750",
    "4751",
    "4753",
    "4754",
    "4756",
    "4757",
    "4798",
    "4799",
    "4800",
    "4802",
    "4803",
    "4804",
    "4805",
    "4806",
    "4807",
    "4808",
    "4809",
    "4813",
    "4816",
    "4819",
    "4820",
    "4821",
    "4822",
    "4823",
    "4824",
    "4825",
    "4828",
    "4829",
    "4830",
    "4849",
    "4850",
    "4852",
    "4854",
    "4855",
    "4856",
    "4857",
    "4858",
    "4859",
    "4860",
    "4861",
    "4865",
    "4872",
    "4873",
    "4874",
    "4875",
    "4876",
    "4880",
    "4882",
    "4883",
    "4885",
    "4886",
    "4890",
    "4891",
    "5174",
    "5202",
    "5203",
    "5204",
    "5210",
    "5211",
    "5212",
    "5213",
    "5214",
    "5220",
    "5221",
    "5222",
    "5223",
    "5235",
    "5237",
    "5238",
    "5253",
    "5254",
    "5255",
    "5256",
    "5259",
    "5260",
    "5261",
    "5262",
    "5263",
    "5264",
    "5265",
    "5266",
    "5267",
    "5268",
    "5269",
    "5270",
    "5271",
    "5272",
    "5273",
    "5275",
    "5276",
    "5277",
    "5278",
    "5279",
    "5280",
    "5290",
    "5291",
    "5301",
    "5302",
    "5303",
    "5304",
    "5306",
    "5307",
    "5308",
    "5309",
    "5310",
    "5311",
    "5320",
    "5321",
    "5322",
    "5330",
    "5331",
    "5332",
    "5333",
    "5340",
    "5341",
    "5342",
    "5343",
    "5344",
    "5345",
    "5346",
    "5350",
    "5351",
    "5352",
    "5353",
    "5354",
    "5355",
    "5356",
    "5357",
    "5360",
    "5371",
    "5372",
    "5373",
    "5374",
    "5381",
    "5400",
    "5401",
    "5410",
    "5411",
    "5412",
    "5413",
    "5414",
    "5415",
    "5416",
    "5417",
    "5418",
    "5419",
    "5420",
    "5421",
    "5422",
    "5431",
    "5432",
    "5433",
    "5434",
    "5440",
    "5451",
    "5452",
    "5453",
    "5454",
    "5455",
    "5460",
    "5461",
    "5462",
    "5464",
    "5470",
    "5471",
    "5472",
    "5473",
    "5480",
    "5481",
    "5482",
    "5483",
    "5485",
    "5490",
    "5491",
    "5493",
    "5495",
    "5501",
    "5502",
    "5510",
    "5511",
    "5512",
    "5513",
    "5514",
    "5515",
    "5516",
    "5517",
    "5518",
    "5519",
    "5520",
    "5521",
    "5522",
    "5523",
    "5540",
    "5550",
    "5552",
    "5554",
    "5555",
    "5556",
    "5558",
    "5560",
    "5570",
    "5571",
    "5572",
    "5573",
    "5575",
    "5576",
    "5577",
    "5580",
    "5581",
    "5582",
    "5583",
    "5600",
    "5601",
    "5602",
    "5603",
    "5604",
    "5605",
    "5606",
    "5607",
    "5608",
    "5609",
    "5630",
    "5631",
    "5632",
    "5633",
    "5640",
    "5641",
    "5642",
    "5650",
    "5651",
    "5652",
    "5653",
    "5654",
    "5655",
    "5660",
    "5661",
    "5670",
    "5671",
    "5680",
    "5690",
    "5700",
    "5701",
    "5710",
    "5713",
    "5715",
    "5717",
    "5719",
    "5720",
    "5722",
    "5723",
    "5724",
    "5725",
    "5730",
    "5731",
    "5732",
    "5733",
    "5734",
    "6037",
    "6041",
    "6042",
    "6043",
    "6044",
    "6125",
    "6126",
    "6161",
    "6207",
    "6208",
    "6213",
    "6214",
    "6215",
    "6220",
    "6221",
    "6223",
    "6224",
    "6225",
    "6226",
    "6227",
    "6228",
    "6229",
    "6230",
    "6231",
    "6232",
    "6233",
    "6236",
    "6237",
    "6239",
    "6240",
    "6243",
    "6244",
    "6251",
    "6252",
    "6253",
    "6254",
    "6255",
    "6256",
    "6258",
    "6260",
    "6262",
    "6271",
    "6275",
    "6280",
    "6281",
    "6282",
    "6288",
    "6290",
    "6302",
    "6304",
    "6306",
    "6308",
    "6309",
    "6311",
    "6312",
    "6313",
    "6315",
    "6316",
    "6317",
    "6318",
    "6320",
    "6321",
    "6322",
    "6323",
    "6324",
    "6326",
    "6327",
    "6328",
    "6330",
    "6331",
    "6333",
    "6335",
    "6336",
    "6337",
    "6338",
    "6341",
    "6343",
    "6346",
    "6348",
    "6350",
    "6351",
    "6352",
    "6353",
    "6355",
    "6356",
    "6357",
    "6358",
    "6359",
    "6361",
    "6363",
    "6365",
    "6367",
    "6368",
    "6369",
    "6370",
    "6372",
    "6373",
    "6375",
    "6383",
    "6384",
    "6385",
    "6386",
    "6390",
    "6391",
    "6392",
    "6393",
    "6394",
    "6395",
    "6396",
    "6397",
    "6398",
    "6401",
    "6403",
    "6405",
    "6407",
    "6409",
    "6410",
    "6411",
    "6412",
    "6413",
    "6414",
    "6415",
    "6418",
    "6419",
    "6420",
    "6421",
    "6422",
    "6423",
    "6424",
    "6425",
    "6426",
    "6427",
    "6428",
    "6429",
    "6430",
    "6431",
    "6432",
    "6433",
    "6434",
    "6436",
    "6437",
    "6438",
    "6440",
    "6442",
    "6443",
    "6445",
    "6446",
    "6447",
    "6448",
    "6450",
    "6452",
    "6460",
    "6461",
    "6462",
    "6463",
    "6465",
    "6466",
    "6467",
    "6468",
    "6470",
    "6472",
    "6473",
    "6475",
    "6476",
    "6477",
    "6479",
    "6480",
    "6484",
    "6485",
    "6487",
    "6488",
    "6489",
    "6490",
    "6501",
    "6502",
    "6503",
    "6504",
    "6505",
    "6506",
    "6507",
    "6509",
    "6510",
    "6511",
    "6512",
    "6513",
    "6514",
    "6515",
    "6516",
    "6517",
    "6518",
    "6519",
    "6520",
    "6521",
    "6522",
    "6525",
    "6528",
    "6530",
    "6532",
    "6535",
    "6536",
    "6537",
    "6560",
    "6562",
    "6564",
    "6566",
    "6567",
    "6568",
    "6569",
    "6571",
    "6572",
    "6574",
    "6575",
    "6603",
    "6605",
    "6606",
    "6608",
    "6609",
    "6612",
    "6613",
    "6614",
    "6616",
    "6620",
    "6623",
    "6625",
    "6627",
    "6628",
    "6630",
    "6631",
    "6632",
    "6635",
    "6638",
    "6639",
    "6640",
    "6642",
    "6646",
    "6701",
    "6705",
    "6707",
    "6710",
    "6711",
    "6712",
    "6713",
    "6714",
    "6716",
    "6718",
    "6720",
    "6721",
    "6722",
    "6725",
    "6726",
    "6728",
    "6731",
    "6733",
    "6740",
    "6743",
    "6751",
    "6753",
    "6754",
    "6760",
    "6762",
    "6765",
    "6770",
    "6798",
    "6799",
    "6905",
    "6910",
    "6952",
    "6983",
    "7017",
    "7018",
    "7020",
    "7021",
    "7022",
    "7023",
    "7024",
    "7025",
    "7026",
    "7027",
    "7030",
    "7051",
    "7052",
    "7054",
    "7055",
    "7109",
    "7112",
    "7113",
    "7116",
    "7117",
    "7119",
    "7120",
    "7139",
    "7140",
    "7150",
    "7155",
    "7162",
    "7163",
    "7171",
    "7172",
    "7173",
    "7174",
    "7175",
    "7176",
    "7177",
    "7178",
    "7179",
    "7180",
    "7182",
    "7183",
    "7184",
    "7185",
    "7186",
    "7187",
    "7190",
    "7209",
    "7210",
    "7211",
    "7212",
    "7213",
    "7214",
    "7215",
    "7216",
    "7248",
    "7250",
    "7252",
    "7253",
    "7254",
    "7255",
    "7256",
    "7257",
    "7259",
    "7260",
    "7261",
    "7262",
    "7263",
    "7264",
    "7265",
    "7267",
    "7268",
    "7270",
    "7275",
    "7276",
    "7277",
    "7290",
    "7291",
    "7292",
    "7300",
    "7301",
    "7302",
    "7303",
    "7304",
    "7305",
    "7306",
    "7307",
    "7310",
    "7316",
    "7320",
    "7321",
    "7322",
    "7325",
    "7330",
    "7331",
    "7466",
    "7467",
    "7468",
    "7469",
    "7470"
]