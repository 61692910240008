import { checkOutStore } from "../../stores/MainStore"
import { CountryConfigs } from "../configs/Configs"
import { randomString, randomNumber } from "../GlobalHelpers"

export const processSleepyTest = () => {
    switch (CountryConfigs.CountryCode()) {
        case 'KR':
            checkOutStore.billingAddress.delivery.firstNameEn = 'Yukiko Ryunosuke'
            checkOutStore.billingAddress.delivery.lastNameEn = ''
            checkOutStore.billingAddress.delivery.fullName = 'Yukiko Ryunosuke'
            checkOutStore.billingAddress.delivery.fullNameNative = '雪子 龍之介'
            checkOutStore.billingAddress.delivery.address1 = '285-1052, Ikanikeisaiganaibaai'
            checkOutStore.billingAddress.delivery.address2 = 'Sammu-shi, Chiba'
            checkOutStore.billingAddress.delivery.address1Native = '285-1052 イカニケイサイガナイバアイ'
            checkOutStore.billingAddress.delivery.address2Native = 'チバケン サンムシ'
            checkOutStore.billingAddress.delivery.zip = '289130'
            checkOutStore.billingAddress.delivery.email = 'yukikomoe2000@yahoo.co.kr'
            checkOutStore.billingAddress.delivery.mobilePhone = randomNumber(11, true)
            checkOutStore.billingAddress.delivery.city = 'Test City'
            checkOutStore.billingAddress.delivery.state = 'Test State'
            checkOutStore.billingAddress.delivery.comment = 'I am the legend.'
            checkOutStore.billingAddress.delivery.cityNative = 'チバケン'
            checkOutStore.billingAddress.delivery.stateNative = 'サンムシ'
            checkOutStore.billingAddress.delivery.commentNative = 'カニケイサイガナイ'
            break
        case 'JP':
            checkOutStore.billingAddress.delivery.firstNameEn = 'Yukiko Ryunosuke'
            checkOutStore.billingAddress.delivery.lastNameEn = ''
            checkOutStore.billingAddress.delivery.fullNameNative = '雪子 龍之介'
            checkOutStore.billingAddress.delivery.address1 = '285-1052, Ikanikeisaiganaibaai'
            checkOutStore.billingAddress.delivery.address2 = 'Sammu-shi, Chiba'
            checkOutStore.billingAddress.delivery.address1Native = '285-1052 イカニケイサイガナイバアイ'
            checkOutStore.billingAddress.delivery.address2Native = 'チバケン サンムシ'
            checkOutStore.billingAddress.delivery.zip = '2891300'
            checkOutStore.billingAddress.delivery.email = 'yukikomoe2000@yahoo.co.jp'
            checkOutStore.billingAddress.delivery.mobilePhone = randomNumber(11, true)
            checkOutStore.billingAddress.delivery.city = ''
            checkOutStore.billingAddress.delivery.state = ''
            checkOutStore.billingAddress.delivery.comment = ''
            break
        case 'AU':
            checkOutStore.billingAddress.delivery.firstNameEn = 'Charlotte Flynnwoods'
            checkOutStore.billingAddress.delivery.lastNameEn = ''
            checkOutStore.billingAddress.delivery.address1 = '309/1, Fisherman Bay'
            checkOutStore.billingAddress.delivery.address2 = 'Grand Park'
            checkOutStore.billingAddress.delivery.zip = '2991'
            checkOutStore.billingAddress.delivery.email = 'charlotte.flynn@gmail.com'
            checkOutStore.billingAddress.delivery.mobilePhone = randomNumber(10, true)
            checkOutStore.billingAddress.delivery.city = 'Kangkaroo City'
            checkOutStore.billingAddress.delivery.state = ''
            checkOutStore.billingAddress.delivery.comment = ''
            break
        case 'NZ':
            checkOutStore.billingAddress.delivery.firstNameEn = 'Imogen Sweetwater'
            checkOutStore.billingAddress.delivery.lastNameEn = ''
            checkOutStore.billingAddress.delivery.address1 = '52/160, Fisherman Bay'
            checkOutStore.billingAddress.delivery.address2 = 'Alpaca Hills'
            checkOutStore.billingAddress.delivery.zip = '8996'
            checkOutStore.billingAddress.delivery.email = 'imogen2019@gmail.com'
            checkOutStore.billingAddress.delivery.mobilePhone = randomNumber(10, true)
            checkOutStore.billingAddress.delivery.city = 'Auckland'
            checkOutStore.billingAddress.delivery.state = 'WKO'
            checkOutStore.billingAddress.delivery.comment = ''
            break
        case 'ME':
            checkOutStore.billingAddress.aramex.firstName = 'Mohd. Umair Tanvir'
            checkOutStore.billingAddress.aramex.email = randomString(6, true, false, true) + '@gmail.com'
            checkOutStore.billingAddress.aramex.mobilePhone = randomNumber(8, true)
            checkOutStore.billingAddress.aramex.sns1 = 'ARD'
            checkOutStore.billingAddress.aramex.sns2 = '44902'
            break
        default: break
    }
}