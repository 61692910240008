import * as K from './Keywords'

// Offline Production
export const CountryOfflineProduction = {
    [K.MENA]: true,
}

/** List of all country we have. */
export const CountryAvailable = [
    K.Australia, 
    // K.Indonesia, 
    K.Japan,
    // K.Malaysia,
    K.Newzealand,
    K.Philippines,
    K.MENA,
    K.Hongkong,
    // K.Korea
]

// Latitude and Longitude use decimal coordinates base. -> https://gps-coordinates.org/coordinate-converter.php
export const CountryCoordinates = {
    [K.Australia]:   { [K.Latitude]: -23.116667, [K.Longitude]: 132.133333 }, // Centre points of Australia
    [K.Indonesia]:   { [K.Latitude]: -6.1323089118625,  [K.Longitude]: 106.65656089782715 },
    [K.Japan]:       { [K.Latitude]: 35.689506,   [K.Longitude]: 139.6917 },
    // [K.Malaysia]:    { [K.Latitude]: 3.9886395,   [K.Longitude]: 102.0640547 },
    [K.Newzealand]:  { [K.Latitude]: -41.838875,  [K.Longitude]: 171.7799 },
    [K.Philippines]: { [K.Latitude]: 12.4155121,  [K.Longitude]: 123.4310376 },
    [K.MENA]:        { [K.Latitude]: 12.4155121,  [K.Longitude]: 123.4310376 }, // mockup lat lon
    [K.Hongkong]:    { [K.Latitude]: 12.4155121,  [K.Longitude]: 123.4310376 }, // mockup lat lon
    [K.Korea]:       { [K.Latitude]: 12.4155121,  [K.Longitude]: 123.4310376 } // mockup lat lon
}

export const CountryTimeZones = {
    [K.All]: 'Asia/Singapore',
    [K.Thailand]: 'Asia/Bangkok',
    [K.Singapore]: 'Asia/Singapore',
    [K.Japan]: 'Asia/Tokyo',
    // [K.Malaysia]: 'Asia/Kuala_Lumpur',
    [K.Philippines]: 'Asia/Manila',
    [K.Vietnam]: 'Asia/Ho_Chi_Minh',
    [K.Indonesia]: 'Asia/Jakarta',
    [K.MENA]: 'Asia/Dubai',
    [K.Taiwan]: 'Asia/Taipei',
    [K.Hongkong]: 'Asia/Hong_Kong',
    [K.Cambodia]: 'Asia/Bangkok',
    [K.Laos]: 'Asia/Bangkok',
    [K.Myanmar]: 'Asia/Bangkok',
    [K.Australia]: 'Australia/Sydney',
    [K.Newzealand]: 'Pacific/Auckland',
    [K.Korea]: 'Asia/Seoul'
}

export const countryNumeralFormat = {
    [K.Australia]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0'
    },
    [K.Indonesia]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0.00'
    },
    [K.Japan]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0'
    },
    /* [K.Malaysia]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0.00'
    }, */
    [K.Newzealand]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0'
    },
    [K.Philippines]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0'
    },
    [K.MENA]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0'
    },
    [K.Hongkong]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0'
    },
    [K.Korea]: {
        numeralFormat: '0,0',
        numeralDecimalFormat: '0,0'
    },
}

export const CountryCode = {
    [K.Thailand]: 'TH',
    // [K.Malaysia]: 'MY',
    [K.Singapore]: 'SG',
    [K.Philippines]: 'PH',
    [K.Indonesia]: 'ID',
    [K.Australia]: 'AU',
    [K.Newzealand]: 'NZ',
    [K.Japan]: 'JP',
    [K.Hongkong]: 'HK',
    [K.Korea]: 'KR',
    [K.Vietnam]: 'VN',
    // MENA
    [K.MENA]: 'ME',
    [K.Algeria]: 'DZ',
    [K.Bahrain]: 'BH',
    [K.Egypt]: 'EG',
    [K.Iran]: 'IR',
    [K.Iraq]: 'IQ',
    [K.Israel]: 'IL',
}

export const CountryCode3 = {
    [K.Thailand]: 'THA',
    // [K.Malaysia]: 'MYS',
    [K.Singapore]: 'SGP',
    [K.Philippines]: 'PHL',
    [K.Indonesia]: 'IDN',
    [K.Australia]: 'AUS',
    [K.Newzealand]: 'NZL',
    [K.Japan]: 'JPN',
    [K.Hongkong]: 'HKG',
    [K.Korea]: 'KOR',
    [K.MENA]: 'MDE',
    [K.Vietnam]: 'VNM',
    // MENA
    [K.Algeria]: 'DZA',
    [K.Bahrain]: 'BHR',
    [K.Egypt]: 'EGY',
    [K.Iran]: 'IRN',
    [K.Iraq]: 'IRQ',
    [K.Israel]: 'ISR',
}

export const CountryCodeExpress = {
    // [K.Malaysia]: 'XMY',
    [K.Philippines]: 'XPH',
    [K.Indonesia]: 'XID',
    [K.Australia]: 'XAU',
    [K.Newzealand]: 'XNZ',
    [K.Japan]: 'XJP',
    [K.Hongkong]: 'XHK',
    [K.Korea]: 'XKR',
    // MENA
    [K.MENA]: 'XME',
    [K.Algeria]: 'XDZ',
    [K.Bahrain]: 'XBH',
    [K.Egypt]: 'XEG',
    [K.Iran]: 'XIR',
    [K.Iraq]: 'XIQ',
    [K.Israel]: 'XIL',
}

export const CountryFullDisplay = {
    [K.Thailand]: 'Thailand',
    // [K.Malaysia]: 'Malaysia',
    [K.Singapore]: 'Singapore',
    [K.Philippines]: 'Philippines',
    [K.Indonesia]: 'Indonesia',
    [K.Australia]: 'Australia',
    [K.Newzealand]: 'New Zealand',
    [K.Japan]: 'Japan',
    [K.Hongkong]: 'Hong Kong',
    [K.Korea]: 'Korea',
    [K.Vietnam]: 'Vietnam',
    // MENA
    [K.MENA]: 'MENA',
    [K.Algeria]: 'Algeria',
    [K.Bahrain]: 'Bahrain',
    [K.Egypt]: 'Egypt',
    [K.Iran]: 'Iran',
    [K.Iraq]: 'Iraq',
    [K.Israel]: 'Israel',
}

export const CountryFull = {
    [K.Thailand]: 'thailand',
    // [K.Malaysia]: 'malaysia',
    [K.Singapore]: 'singapore',
    [K.Philippines]: 'philippines',
    [K.Indonesia]: 'indonesia',
    [K.Australia]: 'australia',
    [K.Newzealand]: 'newzealand',
    [K.Japan]: 'japan',
    [K.Hongkong]: 'hongkong',
    [K.Korea]: 'korea',
    [K.Vietnam]: 'vietnam',
    // MENA
    [K.MENA]: 'mena',
    [K.Algeria]: 'algeria',
    [K.Bahrain]: 'bahrain',
    [K.Egypt]: 'egypt',
    [K.Iran]: 'iran',
    [K.Iraq]: 'iraq',
    [K.Israel]: 'israel',
}

export const CountryCodeToFull = {
    [CountryCode[K.Thailand]]: K.Thailand,
    // [CountryCode[K.Malaysia]]: K.Malaysia,
    [CountryCode[K.Singapore]]: K.Singapore,
    [CountryCode[K.Philippines]]: K.Philippines,
    [CountryCode[K.Indonesia]]: K.Indonesia,
    [CountryCode[K.Australia]]: K.Australia,
    [CountryCode[K.Newzealand]]: K.Newzealand,
    [CountryCode[K.Japan]]: K.Japan,
    [CountryCode[K.Hongkong]]: K.Hongkong,
    [CountryCode[K.Korea]]: K.Korea,
    [CountryCode[K.Vietnam]]: K.Vietnam,
    // MENA
    [CountryCode[K.MENA]]: K.MENA,
    [CountryCode[K.Algeria]]: K.Algeria,
    [CountryCode[K.Bahrain]]: K.Bahrain,
    [CountryCode[K.Egypt]]: K.Egypt,
    [CountryCode[K.Iran]]: K.Iran,
    [CountryCode[K.Iraq]]: K.Iraq,
    [CountryCode[K.Israel]]: K.Israel,
}

export const CountryCode3toFull = {
    [CountryCode3[K.Thailand]]: K.Thailand,
    // [CountryCode3[K.Malaysia]]: K.Malaysia,
    [CountryCode3[K.Singapore]]: K.Singapore,
    [CountryCode3[K.Philippines]]: K.Philippines,
    [CountryCode3[K.Indonesia]]: K.Indonesia,
    [CountryCode3[K.Australia]]: K.Australia,
    [CountryCode3[K.Newzealand]]: K.Newzealand,
    [CountryCode3[K.Japan]]: K.Japan,
    [CountryCode3[K.Hongkong]]: K.Hongkong,
    [CountryCode3[K.Korea]]: K.Korea,
    [CountryCode3[K.Vietnam]]: K.Vietnam,
    // MENA
    [CountryCode3[K.MENA]]: K.MENA,
    [CountryCode3[K.Algeria]]: K.Algeria,
    [CountryCode3[K.Bahrain]]: K.Bahrain,
    [CountryCode3[K.Egypt]]: K.Egypt,
    [CountryCode3[K.Iran]]: K.Iran,
    [CountryCode3[K.Iraq]]: K.Iraq,
    [CountryCode3[K.Israel]]: K.Israel,
}

export const DialingCode = {
    [K.Thailand]: '66',
    // [K.Malaysia]: '60',
    [K.Singapore]: '65',
    [K.Philippines]: '63',
    [K.Indonesia]: '62',
    [K.Australia]: '61',
    [K.Newzealand]: '64',
    [K.Japan]: '81',
    [K.Vietnam]: '84',
    [K.Hongkong]: '852',
    [K.Korea]: '82',
    [K.Myanmar]: '95',
    [K.Cambodia]: '855',
    [K.Laos]: '856',
}

export const CountryCodeToDialingCode = {
    [CountryCode[K.Thailand]]: DialingCode.thailand,
    // [CountryCode[K.Malaysia]]: DialingCode.malaysia,
    [CountryCode[K.Singapore]]: DialingCode.singapore,
    [CountryCode[K.Philippines]]: DialingCode.philippines,
    [CountryCode[K.Indonesia]]: DialingCode.indonesia,
    [CountryCode[K.Australia]]: DialingCode.australia,
    [CountryCode[K.Newzealand]]: DialingCode.newzealand,
    [CountryCode[K.Japan]]: DialingCode.japan,
    [CountryCode[K.Vietnam]]: DialingCode.vietnam,
    [CountryCode[K.Hongkong]]: DialingCode.hongkong,
    [CountryCode[K.Korea]]: DialingCode.korea,
    [CountryCode[K.Myanmar]]: DialingCode.myanmar,
    [CountryCode[K.Cambodia]]: DialingCode.Cambodia,
    [CountryCode[K.Laos]]: DialingCode.Laos,
}