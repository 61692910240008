import * as K from './components/configs/Keywords';

import React, { useState, useEffect, Suspense } from 'react';
import { observer } from 'mobx-react';
import './renewCss.css';
import './antd.css';
import { getCountryCode3, isDevelopmentOrLocalhost, getCountryCode, dictionary, isProduction, decimalController, getParameterByName, getCountryExpress, getCountry, updateLanguageSetting, isCountryPhilippines, isCountryKorea } from './components/GlobalHelpers';
import AppRouter from './components/AppRouter';
import QuickShopNav from './components/shopping/QuickShopNav';
import LoadDBProducts from './components/shopping/LoadDBProducts';
import { getFooter, getTranslationsList, getStaticFooter, getBannerFromDatabase, getSiteVersion, getAwsStaticFooter } from './services/Network';
import Maintenance from './Maintenance';
import { appConfig } from './components/config';
import ReactGA from 'react-ga';
import Redirect from './components/Redirect';
import { logi, loge, logn, logtime } from './components/utils/PikaLog'
import Configs, { StoreConfigs } from './components/configs/Configs';
import { staticFooter, store, shopStore, enrollStore, storeManager, checkOutStore, language } from './stores/MainStore'
import { isNullOrUndefined, isNothing, isLength, isUndefined, justOneOf, isEmptyObject } from './components/utils/Utils'
import { getCustomerID, isCustomerLogin } from './components/utils/Customer'
import { When, If, Then, Else } from 'react-if';
import XKRPopup from './XKRPopup';
import { useDictionaries } from './components/dictionary/useDictionaries';


ReactGA.initialize([{
    trackingId: 'UA-119346265-8',
    debug: true,
}, {
    trackingId: appConfig.GACode[appConfig.country],
    gaOptions: { name: appConfig.country },
    debug: true,
}])

const App = observer(props => {
    // Maintenance Control
    const [isMaintenance, setMaintenance] = useState(null)

    // componentDidMount
    useEffect(() => {
        useDictionaries()
        Configs.init() // deep freeze the constant configuration to make it read-only
        // ** Google Analytics **
        ReactGA.pageview(window.location.pathname + '/#/')
        ReactGA.ga(appConfig.country + '.send', 'pageview', { 'page': window.location.pathname + window.location.search })

        // Let's app show blank screen without a spinner.
        if (getParameterByName('blank') === 'true') {
            storeManager.isReadyToDisplayHome = true
        }

        // ** Start Initiate startup API **
        startInitiateAPI()

    }, [])

    /** Start initiate all basic need APIs. */
    const startInitiateAPI = () => {
        logtime('Total Web Load Time').start()
        const uriPath = window.location.pathname.split('/');
        const token = getParameterByName('token')
        const href = getParameterByName('href')

        if (token && href) {
            href = 'https://hydra.unicity.net/v5a/customers/' + href
            localStorage.setItem("customerHref", href)
            localStorage.setItem("customerToken", token)
            localStorage.setItem("user-href", href)
            localStorage.setItem("user-token", token)
        }

        // TODO: later check if this condition is deprecated
        if (sessionStorage.getItem('dtoken')) {
            const dtoken = JSON.parse(sessionStorage.getItem('dtoken'))
            checkOutStore.dToken = dtoken
        }

        const localFooter = localStorage.getItem('footer-data')
        const localStaticFooter = localStorage.getItem(`static-footer-${getCountryExpress(true)}`)
        const localLanguage = localStorage.getItem('language')
        const localDictionary = localStorage.getItem('dictionary')
        // if we have local storage for every basic need things, it's ok to skip the spinner
        if (localFooter && localStaticFooter && localLanguage && localDictionary) {
            //logi('We can skip spinner, thanks to local storage!')
            storeManager.isLocalStorageAPI = true
            //storeManager.isReadyToDisplayHome = true // if set this one true, spinner will not appear if localStorage exists
        } else {
            //logi('Oh no, this spinning diamond again.')
        }

        // ** [API] Get current site version from database **
        const environment = isProduction() ? '' : '-DEV'
        const countrySource = (`EXPRESS-WEB-${getCountryCode3()}${environment}`).toLowerCase()
        getSiteVersion(countrySource, null, (response) => {
            setSiteVersion(countrySource, response)
        })

        // ** [API] Get all languages dictionary **
        loadLanguagesDictionary()

        // ** [API] Get Footer V2 **
        if (localFooter) {
            const dataFooter = JSON.parse(localFooter)
            if (dataFooter.market === getCountryCode()) {
                store.footer = dataFooter
                storeManager.isFooterDone = true
            }
        }
        logtime('Update Footer V2').start()
        getFooter(((res, status) => {
            if (status) {
                const data = JSON.parse(res)
                store.footer = data
                storeManager.isFooterDone = true

                localStorage.removeItem('footer-data')
                localStorage.setItem('footer-data', res)

                logtime('Update Footer V2').end()
            }
        }), {
            strData: JSON.stringify({
                "source": "UNISHOP-WEB-SG",
                "env": "LIVE",
                "market": StoreConfigs.FooterSpecial()
            })
        })

        // ** [API] Get Static Footer **
        if (localStaticFooter) {
            setStaticFooter(JSON.parse(localStaticFooter))
            storeManager.isFooterStaticDone = true
        }
        logtime('Update Footer Static').start()
        getAwsStaticFooter((res, status) => {
            if (status) {
                if (res.success === true) {
                    setStaticFooter(res.data.footer)
                    localStorage.removeItem(`static-footer-${getCountryExpress(true)}`)
                    localStorage.setItem(`static-footer-${getCountryExpress(true)}`, JSON.stringify(res.data.footer))
                    storeManager.isFooterStaticDone = true
                    // update language setting if footer completed after dictionary.
                    if (isLength(store.dictionaryAll) && !localStorage.getItem('dictionary')) {
                        updateLanguageSetting(store.languageCurrent)
                    }
                    console.log('isLength(store.dictionaryAll),', store.languageCurrent, isLength(store.dictionaryAll))
                }
                logtime('Update Footer Static').end()
            }
        })

        // ** [API] Get Banner from admin database **
        const localBannerData = localStorage.getItem(`banner-data-${getCountryExpress(true)}`)
        if (localBannerData) {
            store.bannerAdminData = JSON.parse(localBannerData)
        }
        logtime('Update Banner').start()
        getBannerFromDatabase((response, status) => {
            if (status) {
                localStorage.removeItem(`banner-data-${getCountryExpress(true)}`)
                if (response.data.length > 0) {
                    store.bannerAdminData = response.data
                    localStorage.setItem(`banner-data-${getCountryExpress(true)}`, JSON.stringify(response.data))
                }
                logtime('Update Banner').end()
            }
        })
    }

    /** Set versioning number from database. Clear **localStorage** when it's changed. */
    const setSiteVersion = (countrySource, response) => {
        const result = JSON.parse(response)
        if (result.success === 'yes') {
            const targets = result.target
            const version = result.version
            const localStorageTag = countrySource
            const currentUserID = isCustomerLogin() ? getCustomerID() : null
            // a function for force reload and clear cache
            const forceReload = (newVersion) => {
                localStorage.clear()
                sessionStorage.clear()
                if (isNothing(newVersion) === false) {
                    localStorage.setItem(localStorageTag, newVersion)
                }
                window.location.reload(true)
                throw ': Force reload is required, please wait.'
            }
            // a function for check global versioning
            const checkGlobalReset = () => {
                logi('[Versioning] > Check for global versioning.')
                if (isNothing(localStorage.getItem(localStorageTag))) {
                    if (isNothing(result.source) === false) {
                        localStorage.setItem(localStorageTag, version)
                    }
                } else {
                    if (isNothing(result.source) === false) {
                        if (localStorage.getItem(localStorageTag) !== version) {
                            forceReload(version)
                        }
                    }
                }
            }

            logi('[Versioning] > ID:', currentUserID, '| GV:', version, '| T:', targets)

            // api return targets versioning
            if (isNothing(targets) === false) {
                // user is logged in
                if (isUndefined(currentUserID) === false) {
                    const listTargets = targets.split(',')
                    listTargets.map((userID) => {
                        // matched id
                        if (`${userID}` === `${currentUserID}`) {
                            logi('[Versioning] > Found target versioning:', `${userID}<U-V>${currentUserID}`)
                            // send target back to api
                            getSiteVersion(countrySource, currentUserID, (response) => {
                                const result = JSON.parse(response)
                                if (result.success === 'yes') {
                                    // ready to reload
                                    forceReload()
                                } else {
                                    checkGlobalReset()
                                }
                            })
                        }
                    })
                } else {
                    // no user logged in, check global version
                    checkGlobalReset()
                }
            } else {
                // no targets versioning, check global version
                checkGlobalReset()
            }
        }
    }

    /** Set static footer to MobX observable object. */
    const setStaticFooter = (footerData) => {
        staticFooter.footerApp = footerData.app
        staticFooter.footerContact = footerData.contact
        staticFooter.footerHours = footerData.hours
        staticFooter.footerSocial = footerData.social
        staticFooter.footerQuickLink = footerData.quick_link
        staticFooter.footerHelp = footerData.help
        staticFooter.footerGeneral = footerData.general
        staticFooter.footerFeedBack = footerData.feedback
        staticFooter.footerDisclaimer = footerData.disclaimer
        staticFooter.footerBanner = footerData.banner
        staticFooter.footerPromotion = footerData.promotion
        staticFooter.footerVideo = footerData.video
        staticFooter.footerOrder = footerData.order
        staticFooter.footerTextTitle = footerData.title_product_section
        staticFooter.footerMessenger = footerData.messenger
        staticFooter.footerMainPic = footerData.main_pic
        staticFooter.footerDisplaySections = footerData.display_sections
    }

    const loadLanguagesDictionary = () => {
        let languagesList = appConfig.language[getCountry()]
        if (isUndefined(languagesList)) {
            languagesList = ['EN']
        }
        logn('Languages:', languagesList)

        // 1. Check the primary language first
        let primeLanguage = store.languagePrime
        if (isLength(languagesList)) {
            primeLanguage = languagesList[0]
        } else {
            primeLanguage = 'EN' // handled null error
            logn('Languages list is empty, set English by default.')
        }
        // set primary language to store
        store.languagePrime = primeLanguage
        // if logged in user, restore last language setting
        if (isCustomerLogin()) {
            store.languageCurrent = localStorage.getItem('language')
        } else {
            store.languageCurrent = primeLanguage
            localStorage.setItem('language', store.languageCurrent)
        }

        // 2. Get all dictionary from API
        let resultLanguagesList = ''
        if (Array.isArray(languagesList)) {
            if (languagesList.length === 0) throw 'languagesList should not be empty.'
            if (languagesList.length > 1) {
                languagesList.forEach(lang => {
                    resultLanguagesList = resultLanguagesList.concat(lang, ',')
                })
                resultLanguagesList = resultLanguagesList.substring(0, resultLanguagesList.length - 1)
            } else {
                resultLanguagesList = languagesList[0]
            }
        } else {
            resultLanguagesList = languagesList
        }

        // logtime('Language').start()
        // restore last dictionary from local storage
        const localLanguage = localStorage.getItem('language')
        const localDictionary = localStorage.getItem('dictionary')
        if (localDictionary) {
            store.dictionaryAll = JSON.parse(localDictionary)
            if (isEmptyObject(store.dictionaryAll) === false) {
                store.languageCurrent = localLanguage || store.languagePrime
                store.language = store.dictionaryAll[store.languageCurrent]
                language.footer = justOneOf(store.languageCurrent, '===', 'EN', 'PH') ? K.English : K.Native
                store.isLanguageInitialized = true
                localStorage.setItem('language', store.languageCurrent)
                // logtime('Language').end()
                updateDictionary(resultLanguagesList)
            }
        } else {
            // if local storage for dictionary not exists, let's wait while loading from API
            getTranslationsList((response, status) => {
                if (status) {
                    const createDictionary = rawDictionary => {
                        const _temp = {}
                        Object.keys(rawDictionary).map((label) => {
                            if (label !== '_empty_') _temp[label] = rawDictionary[label]
                        })
                        return _temp
                    }

                    // set all dictionary to store
                    store.dictionaryAll = {}
                    Object.keys(response).map((language) => {
                        Object.assign(store.dictionaryAll, {
                            [language]: createDictionary(response[language])
                        })
                    })

                    localStorage.setItem('dictionary', JSON.stringify(store.dictionaryAll))

                    const start = () => {
                        setTimeout(callback, 1500)
                    }

                    const done = () => {
                        store.isLanguageInitialized = true
                        // update language setting if footer is ready.
                        if (storeManager.isFooterStaticDone) {
                            updateLanguageSetting(store.languageCurrent)
                        }
                        // logtime('Language').end()
                    }

                    const callback = () => {
                        const key = Object.keys(store.dictionaryAll)[count]
                        // logn('Pre-Loading Language:', key)
                        store.language = store.dictionaryAll[key]
                        count--
                        if (count > 0) {
                            start()
                        } else {
                            done()
                        }
                    }

                    let count = 0 // 0 = no need to pre-load language
                    // count = Object.keys(store.dictionaryAll).length - 1 // load every languages
                    if (count === 0) {
                        done()
                    } else {
                        start()
                    }
                }
            }, resultLanguagesList)
        }
    }

    const updateDictionary = (resultLanguagesList) => {
        logtime('Update Restore Language').start()
        getTranslationsList((response, status) => {
            if (status) {
                const createDictionary = rawDictionary => {
                    const _temp = {}
                    Object.keys(rawDictionary).map((label) => {
                        if (label !== '_empty_') _temp[label] = rawDictionary[label]
                    })
                    return _temp
                }

                // update dictionary to store
                store.dictionaryAll = {}
                Object.keys(response).map((language) => {
                    Object.assign(store.dictionaryAll, {
                        [language]: createDictionary(response[language])
                    })
                })
                store.language = store.dictionaryAll[store.languageCurrent]
                language.footer = justOneOf(store.languageCurrent, '===', 'EN', 'PH') ? K.English : K.Native

                localStorage.removeItem('dictionary')
                localStorage.setItem('dictionary', JSON.stringify(store.dictionaryAll))

                updateLanguageSetting(store.languageCurrent)

                logtime('Update Restore Language').end()
            }
        }, resultLanguagesList)
    }

    /** Check and handled maintenance mode from footer. */
    const isMaintenanceMode = () => {
        // prevent any human errors on footer here
        let _isMaintenanceEnglish = staticFooter.footerGeneral.maintenance.english
        let _isMaintenanceNative = staticFooter.footerGeneral.maintenance.native
        _isMaintenanceEnglish = isNothing(_isMaintenanceEnglish) ? 'no' : _isMaintenanceEnglish.trim()
        _isMaintenanceNative = isNothing(_isMaintenanceNative) ? 'no' : _isMaintenanceNative.trim()
        const _isMaintenance = _isMaintenanceEnglish === 'yes' || _isMaintenanceNative === 'yes'
        if (isNullOrUndefined(isMaintenance)) {
            setMaintenance(_isMaintenance)
        }
        return _isMaintenance && isProduction()
    }

    return (
        <>
            {(() => {
                if (store.isCheckCountry === false) {
                    return <Redirect /> /*<Countries/>*/
                } else {
                    // (1) App should be struck here if stores and footers are not ready to use.
                    if (storeManager.isLocalStorageAPI === false) {
                        if (isNothing(store) || isNothing(storeManager)) {
                            return <Redirect />
                        } else {
                            if (storeManager.isFooterDone === false || storeManager.isFooterStaticDone === false) {
                                return (<><Redirect /><div className="d-none">{`${storeManager.isFooterDone}${storeManager.isFooterStaticDone}`}</div></>)
                            } else {
                                if (store.isLanguageInitialized === false) {
                                    return (<><Redirect /><div className="d-none">{`${store.isLanguageInitialized}${store.dictionaryAll}`}</div></>)
                                } else {
                                    // stores and footers are ready to use now
                                }
                            }
                        }
                    }
                }

                /* if (isCountryPhilippines()) {
                    const AclShutdown = React.lazy(() => import('./components/acl/Philippines/AclShutdown'))
                    return (
                        <div>
                            <Suspense fallback={<Redirect/>}>
                                <AclShutdown/>
                            </Suspense>
                        </div>
                    )
                } */

                return (
                    <div className={'disable-selection'}>
                        {/* Maintenance Operation */}
                        <If condition={isMaintenanceMode() === true}>
                            <Then>
                                <Maintenance store={store} staticFooter={staticFooter} />
                            </Then>
                            <Else>
                                {/* (2) Let's components started its work while invisible.*/}
                                <When condition={storeManager.isReadyToDisplayHome === false}>
                                    <Redirect />
                                </When>
                                {/* Normal Operation */}
                                <div className="App">

                                    <LoadDBProducts
                                        store={store}
                                        shopStore={shopStore}
                                        enrollStore={enrollStore}
                                    />

                                    {shopStore.siteSearch === '' &&
                                        <QuickShopNav
                                            store={store}
                                            shopStore={shopStore}
                                        />
                                    }

                                    <AppRouter
                                        store={store}
                                        shopStore={shopStore}
                                        enrollStore={enrollStore}
                                    />

                                    {/* {isCountryKorea() && isCustomerLogin() && <XKRPopup />} */}

                                    {isCountryKorea() === false &&
                                        <div className="modal fade" id="modalLeaveEnroll" tabIndex="-1" role="dialog">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">{dictionary('leave_enroll_msg1')}</h4>
                                                        <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p><b>{dictionary('leave_enroll_msg1')}</b></p>
                                                    </div>
                                                    <div className="modal-footer row">
                                                        <button className="col-12 col-sm-3 btn btn-outline-secondary btn-sm"
                                                            type="button" data-dismiss="modal"
                                                            id="btn-leave-enroll-cancel">{dictionary('leave_enroll_cancel')}
                                                        </button>
                                                        <button className="col-12 col-sm-6 btn btn-primary btn-sm"
                                                            type="button"
                                                            id="btn-leave-enroll-ok">{dictionary('leave_enroll')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Else>
                        </If>
                    </div>
                )
            })()}
        </>
    )
})

export default App