import * as K from './Keywords'

export const FindOurShop = {
    [K.All]: true,
    [K.Australia]: false,
    [K.Korea]: false,
}

export const Disclaimer = {
    [K.All]: true,
    [K.Australia]: false,
    [K.Korea]: false,
}