import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Modal from 'antd/es/modal'
import ImgPopup from './assets/popup/xkr-popup-01.jpg'
import styled from 'styled-components'

const StyledPopup = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-content {
        border-radius: 15px;
        img {
            border-radius: 15px;
        }
    }

    .ant-modal-footer {
        display: none;
    }
`

const XKRPopup = observer(props => {

    const [isOpen, setOpen] = useState(false)

    useEffect(() => {

        setTimeout(() => {
            setOpen(true)
        }, 1000)

    }, [])

    const onClickClose = () => {
        setOpen(false)
    }

    return (
        <>
        <StyledPopup centered visible={isOpen} onCancel={onClickClose}>
            <img src={ImgPopup} alt="" />
        </StyledPopup>
        </>
    )
})

export default XKRPopup