import React, { Component } from 'react';
import { getProduct, newGetProducts } from '../../services/Network';
import { observer } from 'mobx-react';
import { appConfig } from '../config';
import { checkOutStore, shopStore } from '../../stores/MainStore';
import { getCartInfo } from "../ShoppingHelper";
import { isNullOrUndefined } from '../utils/Utils';
import { logsys } from '../utils/PikaLog';

const LoadDBProducts = observer(
    class LoadDBProducts extends Component {
        constructor(props) {
            super(props);
            this.state = {
                dToken: null,
                price_level: ''
            };
        }

        componentDidMount() {
            this.loadDataProduct()
        }

        componentWillReceiveProps(nextProps) {
            //if (nextProps.checkOutStore.dToken !== null) {
            this.loadDataProduct()
            //}
        }

        reloadPrice(price_level) {
            //  let allProducts = sessionStorage.getItem('allProducts')
            let allProductsHydra = sessionStorage.getItem('allProductsHydra')

            if (allProductsHydra !== null) {
                allProductsHydra = JSON.parse(allProductsHydra)
            }
            this.setShoppingProductSource(allProductsHydra, true)
        }

        loadDataProduct() {
            console.log('loadDataProduct')
            let user = localStorage.getItem('customerData')
            let price_level = ''
            if (user !== null) {
                user = JSON.parse(user)
                price_level = user.acl.api.priceLevel
            } else {
                price_level = 'retail_price'
            }

            /* const shoppClass = new ShoppingClass()
            if (shoppClass.isPreferredCustomer()) {
                price_level = 'preferred_price'
            }

            if(shoppClass.isFamilyCustomer()) {
                price_level = 'employee_price'
            }

            let allProducts = sessionStorage.getItem('allProducts')
            let allProductsHydra = sessionStorage.getItem('allProductsHydra')

            if (allProductsHydra !== null) {
                allProductsHydra = JSON.parse(allProductsHydra)
            }

            let warehouse = getWarehouse() */
            
            //if ((allProducts === null || allProductsHydra === null) || (allProductsHydra.status === 0 || allProductsHydra.status !== 'success')) {
            getProduct((res, status) => {
                if(res.hot !== undefined) {
                    shopStore.hotProduct = res.hot
                }
                    
                res = res.data
                if (!res) {
                    return;
                }

                this.props.shopStore.productDataSource = JSON.parse(JSON.stringify(res))
                /* if(this.props.enrollStore !== undefined) {
                        this.props.enrollStore.enrollProductSource = JSON.parse(JSON.stringify(res))
                    }
                    sessionStorage.setItem('allProducts', JSON.stringify(res)) */

                this.setShoppingProductSource(res, status)
                //this.setEnrollProductSource(res, status)

                newGetProducts((re, success) => {
                    if(re.hot !== undefined) {
                        shopStore.hotProduct.map((v,k) => {
                            let item = re.hot.find(item => item.item_code === v.item_code) 
                            if(item) {
                                v.stock = item.qty
                                v = item
                            }
                            return v
                        })
                    }
                    //this.setShoppingProductSource(re, success)
                })
                /* this.setEnrollProductSource(re, success)
                        this.props.shopStore.productFromHydraLoaded = true
                        sessionStorage.setItem('allProductsHydra', JSON.stringify(re))
                    }, warehouse) */

            }, price_level)//, warehouse)
            /* } else {
                allProducts = JSON.parse(allProducts)
                this.props.shopStore.productDataSource = allProducts
                this.props.enrollStore.enrollProductSource = allProducts
                
                //allProductsHydra = JSON.parse(allProductsHydra)
                this.setShoppingProductSource(allProductsHydra, true)
                this.setEnrollProductSource(allProductsHydra, true)
            } */
        }

        setShoppingProductSource(re, success) {
            let user = localStorage.getItem('customerData')
            if (user !== null) {
                user = JSON.parse(user)
            }

            /* let price_level = ''
            let shoppClass = new ShoppingClass()
            if (shoppClass.isPreferredCustomer()) {
                price_level = 'preferred_price'
            }

            if (shoppClass.isFamilyCustomer()) {
                price_level = 'employee_price'
            }

            let p = new ShoppingClass() */
            let infoProducts = {}
            let dataSource = []
            if (success) {
                if (re.status === 'success') {
                    sessionStorage.setItem('allProducts', JSON.stringify(re.data))

                    /* if (re !== null && re.status === 'success') {

                    if (this.props.shopStore.productDataSource.length <= 0) {
                        this.restoreShoppingProductSourceFromLocal()
                    } */
                   
                    re.data.map((item, key) => {
                        this.props.shopStore.productDataSource.map((v, k) => {
                            if (item.item_code === v.product_id) {
                                v.stock = item.qty
                                v.allow_backorder = item.allow_backorder
                                v.pv = item.pv
                                v.status = item.status
                                v.remarks = item.remarks
                                v.image = item.image_url
                                v.sorted = item.sorted
                                v.video = item.video_url
                                v.member_price = item.retail_price
                                console.log('set shop load data')

                                infoProducts[item.item_code] = {
                                    'item_name_1': item.item_name_1,
                                    'category_name_1': item.category_name_1,
                                    'qty': item.qty,
                                    'status': item.status,
                                    'warehouse': item.warehouse,
                                    'allow_backorder': item.allow_backorder,
                                    'remark': item.remarks,
                                    'pv': item.pv,
                                    'retail_price': item.retail_price,
                                    'preferred_price': item.preferred_price,
                                    'wholesale_price': item.wholesale_price
                                }

                                if(v.image === '') {
                                    v.image = 'https://member-calls4.unicity.com/api/unishop/assets/false/JPN/none.jpg'
                                }

                                /* if (appConfig.preferredPrice === true) {
                                    if (this.props.checkOutStore.referralValue !== '') {
                                        v.member_price = item.preferred_price // Preferred price
                                    }
                                } else {
                                    if (this.props.checkOutStore.referralValue !== '') {
                                        v.member_price = item.retail_price // Retail price
                                    }
                                } */
                                if (appConfig.preferredPrice === true) {
                                    if (checkOutStore.referralValue !== '') {
                                        v.member_price = item.preferred_price // Preferred price
                                    }
                                } else {
                                    if (checkOutStore.referralValue !== '') {
                                        v.member_price = item.retail_price // Retail price
                                    }
                                }

                                if (user !== null) {
                                    v.member_price = item[user.acl.api.priceLevel]
                                }
                            

                                /* if (this.props.checkOutStore.dToken !== null) {
                                    v.member_price = item[price_level]
                                } */

                                return v
                            }
                        })

                        return item
                    })
                    logsys(infoProducts) // log new get products
                }
            }

            dataSource = this.props.shopStore.productDataSource
            let storageCart = JSON.parse(localStorage.getItem('storageCart'))

            if (this.props.store.customerData.id) {
                // login
                dataSource.map((b, i) => {
                    b.qty = 0;
                    return false;
                });
                this.loadCart(dataSource, true)
            } else {
                // not login
                if (!storageCart || storageCart.length === 0) {
                    // no localstorage
                    dataSource.map((b, i) => {
                        b.qty = 0;
                        return false;
                    });
                    this.loadCart(dataSource, false)
                } else {
                    // has localstorage
                    dataSource.map((b, i) => {
                        b.qty = 0;
                        storageCart.map((e, f) => {
                            if (e.id === b.product_id) {
                                b.qty = e.qty
                            }
                        })
                        return false;
                    });
                    this.loadCart(dataSource, false)
                }
            }
        }

        loadCart(products, needDatabaseCart) {

            let dataSource = null

            if (products === null) {
                // load cart, no reload products
                dataSource = this.props.shopStore.productDataSource
            } else {
                dataSource = products
            }

            if (needDatabaseCart) {
                console.log('load database cart')
                let me = this
                //const shoppClass = new ShoppingClass()
                getCartInfo(this.props.store.customerData.id.unicity.toString(), function (status, res) {
                    if (status === 'success') {
                        dataSource.map((e, f) => {
                            if (res) {
                                res.map((b, i) => {
                                    if (e.product_id === b.item_code) {
                                        e.qty = b.quantity
                                    }
                                });
                                return false
                            }
                        })
                        me.props.shopStore.productDataSource = JSON.parse(JSON.stringify(dataSource));
                        me.props.shopStore.gotStock = true

                        // saved cart to local storage if it's not existed
                        const localCart = localStorage.getItem('storageCart')
                        if (isNullOrUndefined(localCart)) {
                            const onProduct = JSON.parse(JSON.stringify(dataSource))
                            let storageCart = []
                            onProduct.map((b, i) => {
                                if (b.qty > 0) {
                                    storageCart.push({
                                        id: b.product_id,
                                        qty: b.qty
                                    })
                                }
                            })
                            localStorage.setItem('storageCart', JSON.stringify(storageCart))
                        }
                    } else {
                        me.props.shopStore.productDataSource = JSON.parse(JSON.stringify(dataSource));
                        me.props.shopStore.gotStock = true
                    }
                })
            } else {
                this.props.shopStore.productDataSource = JSON.parse(JSON.stringify(dataSource));
                this.props.shopStore.gotStock = true
            }
            //localStorage.setItem('hydraShoppingProductSource', JSON.stringify(this.props.shopStore.productDataSource))
        }

        /* setEnrollProductSource(re, success) {
            let dataSource = []
            if (success) {
                if (re.status === 'success') {
                    re.data.map((item, key) => {
                        this.props.enrollStore.enrollProductSource.map((v, k) => {
                            if (item.item_code === v.product_id) {
                                v.stock = item.qty
                                v.allow_backorder = item.allow_backorder
                                v.pv = item.pv
                                v.status = item.status
                                v.remarks = item.remarks
                                v.price = item.wholesale_price
                                v.image = item.image_url
                                v.sorted = item.sorted
                                return v
                            }
                        })
                        
                        return item
                    })
                }
            }

            // ???
            if(this.props.enrollStore === undefined) {
                this.props.enrollStore = {
                    enrollProductSource: []
                }
            }



            dataSource = this.props.enrollStore.enrollProductSource
            dataSource.map((b, i) => {
                if (b.remarks !== "|enroll|") {
                    b.qty = 0;
                } else {
                    if (b.post_title === 'Starter Kit') {
                        b.qty = 1
                    }
                }
                return false;
            });

            // ???
            this.props.enrollStore.enrollProductSource = JSON.parse(JSON.stringify(dataSource)) || []
            this.props.shopStore.gotStock = true

            // map enrollProductSource to enrollCart
            dataSource.map((v, k) => {
                this.props.enrollStore.enrollCart[k] = dataSource[k]
            })
            let items = localStorage.getItem("storageEnrollCart")
            if (items !== null) {
                items = JSON.parse(items)
                if (items.length > 0) {
                    items.map((v, k) => {
                        this.props.enrollStore.enrollCart.map((b, i) => {
                            if (v.id === b.product_id) {
                                b.qty = v.qty
                                return b
                            } else {
                                // b.qty = 0
                                return b
                            }
                        })
                    })
                } else {
                    dataSource.map((v, k) => {
                        this.props.enrollStore.enrollCart[k] = dataSource[k]
                    })
                }
            }

            // ???
            let path = window.location.pathname.split('/');
            if (items !== null) {
                // items = JSON.parse(items)
                if (items.length > 0) {
                    // this.props.enrollStore.enrollCart.map((v, k) => {
                    //     supportedMarkets.map((b, i) => {
                    //         if ((b.name === path[1])) {
                    //             if (b.starterKit.product_id === v.product_id) {
                    //                 if (v.qty > 0) {
                    //                     Object.keys(b.starterKit).map((a) => {
                    //                         if (a !== "image") {
                    //                             v[a] = b.starterKit[a]
                    //                         }
                    //                         // v.qty = 1
                    //                     })
                    //                 }
                    //             }
                    //         }
                    //     })
                    //     return v;
                    // })
                }
            }


            this.swapStarterKitToFirst()

            localStorage.setItem('hydraEnrollProductSource', JSON.stringify(this.props.enrollStore.enrollProductSource))
            localStorage.setItem('hydraEnrollCart', JSON.stringify(this.props.enrollStore.enrollCart))
        } */

        swapStarterKitToFirst() {
            let index = 0
            /* let items = localStorage.getItem("storageEnrollCart")
            if (items !== null) {
                items = JSON.parse(items)
                if (items.length > 0) { */
            this.props.enrollStore.enrollCart.filter((v, k) => {
                if (v.remarks === '|enroll|') {
                    index = k
                }
            })
            this.arrayMove(index, 0)
            //}
            //}
        }

        arrayMove(old_index, new_index) {
            if (new_index >= this.props.enrollStore.enrollCart.length) {
                var k = new_index - this.props.enrollStore.enrollCart.length + 1;
                while (k--) {
                    this.props.enrollStore.enrollCart.push(undefined);
                }
            }
            this.props.enrollStore.enrollCart.splice(new_index, 0, this.props.enrollStore.enrollCart.splice(old_index, 1)[0]);
        }

        restoreEnrollProductSourceFromLocal() {
            // after leave enroll, .enrollStore will be clear, when come back to enroll have to load .enrollProductSource and .enrollCart again
            // below is use for restore .enrollProductSource and .enrollCart from localStorage instead of calling hydra
            let hydraEnrollProductSource = localStorage.getItem('hydraEnrollProductSource')
            hydraEnrollProductSource = JSON.parse(hydraEnrollProductSource)
            this.props.enrollStore.enrollProductSource = hydraEnrollProductSource

            let hydraEnrollCart = localStorage.getItem('hydraEnrollCart')
            hydraEnrollCart = JSON.parse(hydraEnrollCart)
            //this.props.enrollStore.enrollCart = hydraEnrollCart || [];
            
            // after waiting for hydra response, .enrollCart will be set value from localStorage.storageEnrollCart
            // and after restore .enrollCart from localStorage.hydraEnrollCart need to check if current localStorage.storageEnrollCart is empty then set .enrollCart to empty
            let storageEnrollCart = localStorage.getItem('storageEnrollCart')
            //storageEnrollCart = JSON.parse(storageEnrollCart) || [];
            if (!storageEnrollCart || storageEnrollCart.length == 0) {
                this.props.enrollStore.enrollCart.map((b, i) => {
                    b.qty = 0
                    return b
                })
            /* } else {
                storageEnrollCart.map((v, k) => {
                    this.props.enrollStore.enrollCart.map((b, i) => {
                        if (v.id === b.product_id) {
                            b.qty = v.qty
                            return b
                        } else {
                            return b
                        }
                    })
                }) */
            }
        
        }

        restoreShoppingProductSourceFromLocal() {
            let hydraShoppingProductSource = localStorage.getItem('hydraShoppingProductSource')
            hydraShoppingProductSource = JSON.parse(hydraShoppingProductSource)
            this.props.shopStore.productDataSource = hydraShoppingProductSource
        }

        render() {
            return (
                <div>
                </div>
            );
        }
    }
)

export default LoadDBProducts