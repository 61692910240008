import { isPointInPolygon, findNearest } from 'geolib';
import countries from './data/counties.geo.mini.js'

const getNearestPolygon = (point) => {
    const features = countries.features
    for (let f = 0; f < features.length; f++) {
        const feature = features[f]
        const polygons = feature.geometry.coordinates
        for (let p = 0; p < polygons.length; p++) {
            //console.log('INDEX >', p)
            //console.log('Find!', polygons[p][0], distanceToPolygon(point, polygons[p][0]))
        }
    }
}

const getNearestCountry = (point, countriesCoordinates) => {
    return findNearest(point, countriesCoordinates)
}

const getCountryByPoint = (point) => {
    //console.log('GetCountryByPoint:', country(point))
    return country(point)
}

const contains = (polygon, point) => {
    var r = isPointInPolygon(point, polygon[0])
    var i = 1
  
    while (r && i < polygon.length)
      r = !isPointInPolygon(point, polygon[i++])
  
    return r
}
  
const country = (point) => {
    const features = countries.features
    for (let f = 0; f < features.length; f++) {
        const feature = features[f]
        const polygons = feature.geometry.coordinates
        for (let p = 0; p < polygons.length; p++)
        if (contains(polygons[p], point)) {
            //console.log('FOUND!!!!!', feature.properties.countryCode3)
            return feature.properties.countryCode3
        }
    }
    return null
}

// Polygons
function vlen(vector) {
    return Math.sqrt(vector[0]*vector[0] + vector[1] * vector[1]);
}

function vsub(v1, v2) {
    return [v1[0] - v2[0], v1[1] - v2[1]];
}

function vscale(vector, factor) {
    return [vector[0] * factor, vector[1] * factor];
}

function vnorm(v) {
    return [-v[1], v[0]];
}
  
function distanceToPolygon(point, polygons) {
    const distant = polygons.map((polygon, index) => {
      const prev = (index == 0 ? polygons.length : index) - 1
      const p2 = polygons[prev]
      const line = vsub(p2, polygon)
  
      if (vlen(line) == 0)
        return vlen(vsub(point, polygon));
  
      var norm = vnorm(line),
          x1 = point[0],
          x2 = norm[0],
          x3 = polygon[0],
          x4 = line[0],
          y1 = point[1],
          y2 = norm[1],
          y3 = polygon[1],
          y4 = line[1],
  
          j = (x3 - x1 - x2 * y3 / y2 + x2 * y1 / y2) / (x2 * y4 / y2 - x4),
          index;
  
        if (j < 0 || j > 1)
          return Math.min(
            vlen(vsub(point, polygon)),
            vlen(vsub(point, p2)));
  
        index = (y3 + j * y4 - y1) / y2;
  
        return vlen(vscale(norm, index));
    });
  
    return Math.min.apply(null, distant);
}

export { getNearestCountry, getCountryByPoint, getNearestPolygon }