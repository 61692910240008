import React, { Component } from 'react'
import Spinner from './Spinner'

class Redirect extends Component {
    render() {
        return (
            <div className="redirect-loading-container">
                <Spinner />
            </div>
        )
    }
}

export default Redirect
