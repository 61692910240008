import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Draggable from 'react-draggable'; 
import { Form, Button, Input, Divider, Typography, Radio } from 'antd';
import { Switch, Case, When } from 'react-if';
import { devTools } from '../../../stores/MainStore';

const isHome = observable.box(true)
const command = observable.box('')

const ToolHome = observer(props => {
    const [value, setValue] = useState('')

    const onInputChange = (e) => {
        setValue(e.target.value)
    }

    const onClickClose = () => {
        devTools.enabled = false
    }

    const onClickSubmit = () => {
        props.onSubmitCommand(value)
        setValue('')
    }

    return (
        <>
            <div className="card-header handle cursor-move">
                <div>
                    <div className="pull-left">
                        <span className="fa fa-wrench fa-fw text-bold mr-2"></span>
                        <span className="text-medium">Please insert your command</span>
                    </div>
                    <span className="fa fa-times fa-fw btn-close pull-right" onClick={onClickClose}></span>
                </div>
            </div>
            <When condition={isHome.get()}>
                <div className="card-body">
                    <Form layout="inline">
                        <Form.Item>
                            <Input allowClear onChange={onInputChange} onPressEnter={() => onClickSubmit()}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" shape="circle" icon="thunderbolt" onClick={() => onClickSubmit()}></Button>
                        </Form.Item>
                    </Form>
                </div>
            </When>
        </>
    )
})

const ToolHydraEndpoint = observer(props => {

    useEffect(() => {
        isHome.set(false)
        devTools.devTopic = 'hydra-endpoint'
    }, [])

    const onClickBack = () => {
        devTools.devTopic = ''
        devTools.devValue = ''
        command.set('')
        isHome.set(true)
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    }

    return (
        <>
        <div className="card-body">
            <Form layout="inline">
                <Form.Item>
                    <Button type="primary" shape="circle" icon="left" onClick={() => onClickBack()}></Button>
                </Form.Item>
                <Form.Item>
                    <Typography.Text strong={true}>Select Hydra End-point</Typography.Text>
                </Form.Item>
                <Divider dashed/>
            </Form>
            <div className="row justify-content-center">
            <Form layout="vertical">
                <Form.Item label="Hydra">
                    <Radio.Group 
                        defaultValue={devTools.devValue} 
                        onChange={(e) => {
                            devTools.devValue = e.target.value
                        }}>
                        <Radio style={radioStyle} value={'hydra-live'}>Hydra - Live</Radio>
                        <Radio style={radioStyle} value={'hydra-test'}>Hydra - Test</Radio>
                        <Radio style={radioStyle} value={'hydraqa-live'}>HydraQA - Live</Radio>
                        <Radio style={radioStyle} value={'hydraqa-test'}>HydraQA - Test</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
            </div>
        </div>
        </>
    )
})

const ToolSleepy = observer(props => {

    useEffect(() => {
        devTools.devTopic = 'sleepy'
    }, [])

    return null
})

const StickyDevTools = observer(props => {
    const [dragPose, setDragPose] = useState(null)

    const onStop = (e, data) => {
        setDragPose({x: data.x, y: data.y})
    }

    const onSubmitCommand = (cmd) => {
        command.set(cmd)

        if (cmd ==='sleepy') {
            devTools.devTopic ='sleepy'
        }
    }

    return (
        <Draggable
            defaultPosition={{x: 20, y: 150}}
            position={dragPose}
            handle=".handle"
            onStop={onStop}
            scale={1}>
            <div className="box-devtools card">
                <ToolHome onSubmitCommand={onSubmitCommand}/>
                <Switch>
                    <Case condition={command.get() === 'hydra'}>
                        <ToolHydraEndpoint/>
                    </Case>
                </Switch>
            </div>
        </Draggable>
    )
})

export default StickyDevTools