import * as K from '../configs/Keywords';
import * as S from '../configs/StorageKeys';
import { CountryCodeToDialingCode, DialingCode, CountryCode, CountryAvailable, CountryCodeExpress } from '../configs/Country';
import { isNothing, isLength, isNullOrUndefined, isSomething } from './Utils';
import { assertValue } from './Errors';
import get from 'lodash/get';
import { includes, set } from 'lodash';
import { isLanguageNative } from '../GlobalHelpers';

/** Use this `Object` template as a mockup data, to prevent any undefined error. */
const dataTemplate = {
    id: {
        unicity: ''
    },
    achievementsHistory: {
        href: '',
        items: '',
    },
    acl: {
        api: {
            priceLevel: '', // e.g. 'preferred_price'
            status: '', // e.g. 'Active'
            type: '' // e.g. 'PreferredCustomer'
        },
        showPv: '', // e.g. false (boolean)
        status: '', // e.g. 'P'

        allowList: '',
        allowed: '',
        country: '',
        description: '',
        inPageMenu: '',
        mainMenu: '',
    },
    birthDate: '',
    cumulativeMetricsProfile: {
        highestRankShort: '',
        href: ''
    },
    email: '',
    humanName: '',
    homePhone: '',
    href: '',
    mainAddress: '',
    metricsProfile: '',
    metricsProfileHistory: {
        aggregate: {
            cumulativeMetricsProfile: {
                highestRankShort: '',
                ref: '',
            }
        },
        href: '',
        items: '',
    },
    mobilePhone: '',
    profilePicture: '',
    type: ''
}

const _getCustomer = () => localStorage.getItem(S.Customer)

/** Get customer data from localStorage. */
export const getCustomerRaw = () => _getCustomer()
/** Assign customer data to localStorage. */
export const assignCustomer = (value) => localStorage.setItem(S.Customer, value)
/** Delete customer data from localStorage. */
export const killCustomer = () => localStorage.removeItem(S.Customer)
/** Log customer data to console.log(). */
export const logCustomerObject = () => console.log(JSON.parse(_getCustomer()))
/** Check the existing of any customer in this site. */
export const isCustomerValid = () => isNothing(_getCustomer()) === false

/** Get customer data as an `Object`. */
export const getCustomerObject = () => {
    if (JSON.parse(_getCustomer()) === null) return dataTemplate

    const c = assertValue(
        JSON.parse(_getCustomer()), 
        'Please use isCustomerValid() before access getCustomerObject().'
    )

    const data = {}
    data.id = {
        unicity: c.id.unicity
    }
    data.achievementsHistory = {
        href: c.achievementsHistory.href,
        items: c.achievementsHistory.items,
    }
    data.acl = {
        api: {
            priceLevel: c.acl.api.priceLevel, // e.g. 'preferred_price'
            status: c.acl.api.status, // e.g. 'Active'
            type: c.acl.api.type // e.g. 'PreferredCustomer'
        },
        showPv: c.acl.showPv, // e.g. false (boolean)
        status: c.acl.status, // e.g. 'P'

        allowList: c.acl.allowList,
        allowed: c.acl.allowed,
        country: c.acl.country,
        description: c.acl.description,
        inPageMenu: c.acl.inPageMenu,
        mainMenu: c.acl.mainMenu,
    }
    data.birthDate = c.birthDate
    data.cumulativeMetricsProfile = {
        highestRankShort: get(c, 'cumulativeMetricsProfile.highestRankShort'),
        href: c.cumulativeMetricsProfile.href
    }
    data.entryPeriod = c.entryPeriod
    data.email = c.email
    data.humanName = c.humanName
    data.homePhone = c.homePhone
    data.href = c.href
    data.mainAddress = c.mainAddress
    data.metricsProfile = c.metricsProfile
    data.metricsProfileHistory = {
        aggregate: {
            cumulativeMetricsProfile: {
                highestRankShort: c.metricsProfileHistory.aggregate.highestRankShort,
                ref: c.metricsProfileHistory.aggregate.href
            }
        },
        href: c.metricsProfileHistory.href,
        items: c.metricsProfileHistory.items
    }
    data.mobilePhone = c.mobilePhone
    data.profilePicture = c.profilePicture
    data.type = c.type
    return data
}
/** Validate current customer then get customer data as an `Object`. */
export const getCustomerValidObject = () => {
    if (isCustomerValid()) {
        return getCustomerObject()
    } else {
        return dataTemplate
    }
}

/** Get customer ACL object. */
export const getCustomerAcl = () => get(getCustomerObject(), 'acl')
/** Get customer `unicity` ID (e.g. 108357166). */
export const getCustomerID = () => get(getCustomerObject(), 'id.unicity')
/** Get customer `href`. */
export const getCustomerHref = () => localStorage.getItem(S.CustomerHref)
/** Get customer `token`. */
export const getCustomerToken = () => localStorage.getItem(S.CustomerToken)

/** Get an `homePhone` from customer. */
export const getCustomerHomePhone = () => get(getCustomerObject(), 'homePhone')
/** Get an `mobilePhone` from customer. */
export const getCustomerMobilePhone = () => get(getCustomerObject(), 'mobilePhone')
/** Get an `email` from customer. */
export const getCustomerEmail = () => get(getCustomerObject(), 'email')

/** Get `birthDate` from customer. */
export const getCustomerBirthDate = () => get(getCustomerObject(), 'birthDate')
/** Get customer `type` in full phrase (e.g. Associate). */
export const getCustomerType = () => getCustomerAcl().api.type
/** Get an alphabet of customer type (e.g. A). */
export const getCustomerStatus = () => getCustomerAcl().status
/** Get an active status of customer (e.g. Active). */
export const getCustomerActiveStatus = () => getCustomerAcl().api.status
/** Get customer price level (e.g. wholesale_price). */
export const getCustomerPriceLevel = () => getCustomerAcl().api.priceLevel
/** Get customer `humanName`. */
export const getCustomerHumanName = () => {
    const human = get(getCustomerObject(), 'humanName')
    const data = {
        fullName: human.fullName,
        firstName: human.firstName,
        lastName: human.lastName
    }

    let native = ''
    Object.keys(human).map((key) => {
        if (includes(key, '@') === true) {
            native = human[key]
        }
    })

    if (native) {
        set(data, 'fullName@native', native)
    }

    return data
}

export const getCustomerFullName = (translate = true) => {
    if (isLanguageNative() && translate === true) {
        if (isSomething(getCustomerHumanName()['fullName@native'])) {
            return getCustomerHumanName()['fullName@native']
        } else {
            return getCustomerHumanName().fullName
        }
    } else {
        return getCustomerHumanName().fullName
    }
}

export const getCustomerFullNameNative = () => {
    return getCustomerHumanName()['fullName@native']
}

/** Get customer `mainAddress`. */
export const getCustomerMainAddress = () => get(getCustomerObject(), 'mainAddress')

/** Get customer country code from `unicity` ID (e.g. 66). */
export const getCustomerDialingCode = (length = 2) => {
    return isNothing(getCustomerID()) === false ? getCustomerID().toString().substr(-length) : null
}

/** Get country code as ISO 3166-1 alpha-2 code from customer (e.g. TH, SG, VN), 
 * which mapped by dialing code from `unicity` ID. Return `unknown` if could not mapped any country.
 * Return `null` if there was no customer logged in. */
export const getCustomerCountryCode = () => {
    if (isCustomerLogin()) {
        let result = null
        Object.keys(DialingCode).map((key) => {
            if (getCustomerDialingCode(2) === DialingCode[key]) result = key
        })
        if (result === null) {
            Object.keys(DialingCode).map((key) => {
                if (getCustomerDialingCode(3) === DialingCode[key]) result = key
            })
        }
        return isNullOrUndefined(CountryCode[result]) ? K.Unknown : CountryCode[result]
    } else {
        return null
    }
}

export const getCustomerEntryPeriod = () => get(getCustomerObject(), 'entryPeriod')

// ** A variant of checking customer methods.
/** Return `true` if any customer already logged in. */
export const isCustomerLogin = () => isNothing(_getCustomer()) === false
/** Check the **Marketing Unit** number to located customer from **Australia**. */
export const isCustomerFromAustralia = () => getCustomerCountryCode() === CountryCode.australia
/** Check the **Marketing Unit** number to located customer from **Cambodia**. */
export const isCustomerFromCambodia = () => getCustomerCountryCode() === CountryCode.cambodia
/** Check the **Marketing Unit** number to located customer from **Hong Kong**. */
export const isCustomerFromHongkong = () => getCustomerCountryCode() === CountryCode.hongkong
/** Check the **Marketing Unit** number to located customer from **Korea**. */
export const isCustomerFromKorea = () => getCustomerCountryCode() === CountryCode.korea
/** Check the **Marketing Unit** number to located customer from **Laos**. */
export const isCustomerFromLaos = () => getCustomerCountryCode() === CountryCode.laos
/** Check the **Marketing Unit** number to located customer from **Indonesia**. */
export const isCustomerFromIndonesia = () => getCustomerCountryCode() === CountryCode.indonesia
/** Check the **Marketing Unit** number to located customer from **New Zealand**. */
export const isCustomerFromNewzealand = () => getCustomerCountryCode() === CountryCode.newzealand
/** Check the **Marketing Unit** number to located customer from **Philippines**. */
export const isCustomerFromPhilippines = () => getCustomerCountryCode() === CountryCode.philippines
/** Check the **Marketing Unit** number to located customer from **Singapore**. */
export const isCustomerFromSingapore = () => getCustomerCountryCode() === CountryCode.singapore
/** Check the **Marketing Unit** number to located customer from **Thailand**. */
export const isCustomerFromThailand = () => getCustomerCountryCode() === CountryCode.thailand
/** Check the **Marketing Unit** number to located customer from **Japan**. */
export const isCustomerFromJapan = () => getCustomerCountryCode() === CountryCode.japan
/** Check the **Marketing Unit** number to located customer from **Vietnam**. */
export const isCustomerFromVietnam = () => getCustomerCountryCode() === CountryCode.vietnam
/** Check the origin of customer by **Marketing Unit** number. */
export const isCustomerFrom = (countryCodeList = []) => {
    if (isLength(countryCodeList)) {
        return countryCodeList.some((countryCode) => {
            const dialingCode = CountryCodeToDialingCode[countryCode]
            return getCustomerDialingCode(2) === dialingCode || getCustomerDialingCode(3) === dialingCode
        })
    } else {
        return false
    }
}

// ** Customer Cart management methods
const keyCartStorage = `${S.CartStorage}`
/** Get current country **Cart** object as `String`. */
export const getCartStorage = () => localStorage.getItem(keyCartStorage)
/** Save an `Object` to  current country **Cart** object, `JSON.stringify()` is not necessary. */
export const saveCartStorage = (data) => localStorage.setItem(keyCartStorage, JSON.stringify(data))
/** Completely removed current country **Cart**. */
export const clearCartStorage = () => localStorage.removeItem(keyCartStorage)
/** Completely removed ***every*** country **Cart**. */
export const clearAllCartStorage = () => {
    CountryAvailable.map((c) => {
        console.log(c)
        localStorage.removeItem(`${S.CartStorage}-${CountryCodeExpress[c].toLowerCase()}`)
    })
}
/** Return `true` if cart is empty, separated by country. */
export const isEmptyCart = () => isNothing(getCartStorage()) ? true : JSON.parse(getCartStorage()).length === 0

export const createCustomerToken = (baId, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1 
    const hash = baId.toString('')
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}

export const validateCustomerToken = (baId, token) =>{
    const now = new Date()
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const tormorrow = new Date()
    tormorrow.setDate(tormorrow.getDate() + 1)

    const validList = [
        createCustomerToken(baId, now),
        createCustomerToken(baId, yesterday),
        createCustomerToken(baId, tormorrow),
    ]

    return validList.includes(token)
}