import React, { Component } from 'react';
import { observer } from 'mobx-react';

const QuickShopNav = observer(
    class QuickShopNav extends Component {
        constructor(props) {
            super(props);
            this.state = {
                bottom: 0
            }
        }

        componentDidMount() {
            document.addEventListener('scroll', this.trackScrolling) // this is the component
            window.addEventListener('resize', this.trackScrolling)
        }

        trackScrolling = () => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const windowBottom = windowHeight + window.pageYOffset;

            if (windowBottom >= document.body.scrollHeight - this.props.store.footerHeight) {
                this.setState({
                    bottom: windowBottom - (document.body.scrollHeight - this.props.store.footerHeight)
                });
            } else {
                this.setState({
                    bottom: 0
                });
            }
        };

        render() {

            let options = this.props.shopStore.quickNavOptions.map((b, i) => {

                return (
                    <div onClick={() => this.props.shopStore.quickNavNext = b} key={i} style={{ height: ((1 / this.props.shopStore.quickNavOptions.length) * 100) + '%' }} className="qsoption">
                        {b}
                    </div>
                )
            });
            return (
                <div>
                    {this.props.shopStore.quickNavOpen &&
                        <div className="qs-quicknav" style={{ bottom: this.state.bottom }}>
                            {options}
                        </div>
                    }
                </div>
            );
        }
    }
)

export default QuickShopNav