import { reaction } from 'mobx'
import { devTools, store, storeManager, shopStore, checkOutStore } from './MainStore'
import { logw, logi, logtime } from '../components/utils/PikaLog'
import { updateLanguageSetting, getCountryCode, randomNumber, randomString } from '../components/GlobalHelpers';
import { processSleepyTest } from '../components/utils/SleepyTest';
import { defaults } from '../components/utils/Utils';

// ** Watch app current language and set something if it changed. **
reaction(() => store.languageCurrent, 
    language => {
        if (storeManager.isFooterStaticDone) {
            //logw('Switched language to:', language)
            updateLanguageSetting(language)
        }
    },  {
        name: 'watcherLanguage',
        onError: () => {
            console.error('Error: Reaction Language')
        }
    }
)

// ** Watch app when it is ready to display home. **
reaction(() => storeManager.isReadyToDisplayHome, 
    isReady => {
        if (isReady === true) {
            logw('App is ready to display.')
        }
    },  {
        name: 'watcherReadyDisplayHome',
        onError: () => {
            console.error('Error: Reaction Ready Home')
        }
    }
)

// ** Watch app when it is ready to display home. **
reaction(() => shopStore.passCheckOut, 
    pass => {
        logw('shopStore.passCheckOut =', pass)
    }
)

reaction(() => devTools.devTopic,
    (topic) => {
        if (defaults(topic, '').toLowerCase() === 'sleepy') {
            processSleepyTest()
        }
    }
)