import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './renewCss.css';
import { validateEmail } from "./components/GlobalHelpers";
import { notification } from './services/Network';
import Spinner from './components/Spinner';

const Maintenance = observer(
    class Maintenance extends Component {
        constructor(props) {
            super(props);
            this.state = {
                dataUpdate: false,
                fullName: '',
                notifyEmail: '',
                notifyResponse: { message: '', status: '' },
                showErrorMessage: false
            }
        }
        componentWillMount() {
            
            // getFooter((res, status) => {
            //     if (status) {
            //         let s = res.replace('top-categories', 'topcategories')

            //         let data = JSON.parse(s)
            //         this.setState({
            //             footer: data
            //         })
            //     }
            // }, { strData: JSON.stringify({ "source": "UNISHOP-WEB-" + getCountryCode(), "country": getCountryCode(), "language": "EN" }) })

        }
        onNotifyEmailChange(e) {
            this.setState({
                notifyEmail: e.target.value
            })
        }
        onNotifyNameChange(e) {
            this.setState({
                fullName: e.target.value
            })
        }
        onSubmit() {
            notification(((res, status) => {
                var messageObj = {
                    timeout: 5000,
                    position: 'top-right',
                    effect: 'slide',
                    html: false
                }
                if (status) {
                    this.setState({ showErrorMessage: true })
                    setTimeout(() => {
                        this.setState({ showErrorMessage: false })
                    }, 5000);
                    this.setState({ notifyResponse: Object.assign({}, this.state.notifyResponse, { message: (JSON.parse(res)).success === 'yes' ? (JSON.parse(res)).message : (JSON.parse(res)).message[0], status: (JSON.parse(res)).success === 'yes' ? 'success' : 'error' }) })
                    // if ((JSON.parse(res)).success === 'yes') {
                    //     Alert.success((JSON.parse(res)).message, messageObj);
                    // } else {
                    //     (JSON.parse(res)).message[0] === 'Email is incorrect.' ? Alert.error((JSON.parse(res)).message, messageObj) : Alert.error((JSON.parse(res)).message, messageObj);
                    // }
                }
                this.setState({
                    fullName: '',
                    notifyEmail: ''
                })
            }), { strData: JSON.stringify({ "source": "SG-SOCIAL", "country": "SG", "fullName": this.state.fullName, "email": this.state.notifyEmail, "language": "EN" }) })
        }

        render() {

            let lang = this.props.store.language.language === 'EN' ? 'english' : 'native'

            if (this.props.staticFooter.footerContact) {
                return (
                    <div className="maintainance">
                        <div className="offcanvas-wrapper">
                            <div className="row no-gutters">
                                <div className="col-md-6 fh-section maintainanceBg"><span className="overlay" style={{
                                    backgroundColor: "#374250",
                                    borderRadius: "0%"
                                }}></span>
                                    <div className="d-flex flex-column fh-section py-5 px-3 justify-content-between">
                                        <div className="w-100 text-center">
                                            <div className="d-inline-block mb-5" style={{ width: "145px" }}><img
                                                className="d-block" src={require("./assets/unicity-logo.png")}
                                                alt="Unishop" />
                                            </div>
                                            <h1 className="text-white text-normal mb-2 maintainLabel">Under Maintenance</h1>
                                            <h5 className="text-white text-normal opacity-80 mb-4">Our site is undergoing
                                                regular maintenance.</h5>
                                        </div>
                                        <div className="w-100 text-center">
                                            <p className="text-white mb-2"><a
                                                href={`tel:` + this.props.staticFooter.footerContact.phone[lang].replace(/\s/g, '')}>{this.props.staticFooter.footerContact.phone[lang]}</a>
                                            </p>
                                            <a className="navi-link-light"
                                                href={`mailto:` + this.props.staticFooter.footerContact.email[lang]}>{this.props.staticFooter.footerContact.email[lang]}</a>
                                            <div className="pt-3">
                                                {this.props.staticFooter.footerSocial.facebook !== undefined && this.props.staticFooter.footerSocial.facebook !== '' &&
                                                    <a className="social-button shape-circle sb-facebook sb-light-skin"
                                                        href={this.props.staticFooter.footerSocial.facebook} target="_blank"><i
                                                            className="socicon-facebook"></i></a>
                                                }
                                                {this.props.staticFooter.footerSocial.twitter !== undefined && this.props.staticFooter.footerSocial.twitter !== '' &&
                                                    <a className="social-button shape-circle sb-twitter sb-light-skin"
                                                        href={this.props.staticFooter.footerSocial.twitter} target="_blank"><i
                                                            className="socicon-twitter"></i></a>
                                                }
                                                {this.props.staticFooter.footerSocial.instagram !== undefined && this.props.staticFooter.footerSocial.instagram !== '' &&
                                                    <a className="social-button shape-circle sb-instagram sb-light-skin"
                                                        href={this.props.staticFooter.footerSocial.instagram} target="_blank"><i
                                                            className="socicon-instagram"></i></a>
                                                }
                                                {/* {this.props.staticFooter.footerSocial.facebook !== undefined && this.props.staticFooter.footerSocial.facebook !== '' &&
                                            <a className="social-button shape-circle sb-google-plus sb-light-skin" href="#"><i className="socicon-googleplus"></i></a>
                                        } */}
                                                {this.props.staticFooter.footerSocial.youtube !== undefined && this.props.staticFooter.footerSocial.youtube !== '' &&
                                                    <a className="social-button shape-circle sb-youtube sb-light-skin"
                                                        href={this.props.staticFooter.footerSocial.youtube} target="_blank"><i
                                                            className="socicon-youtube"></i></a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 fh-section" id="notify" data-offset-top="-1">
                                    <div
                                        className="d-flex flex-column fh-section py-5 px-3 justify-content-center align-items-center">
                                        <div className="text-center" style={{ maxWidth: "500px" }}>
                                            <div className="h1 text-normal mb-2">Notify Me!</div>
                                            <h5 className="text-normal text-muted mb-4">Let me know when your website has
                                                completed maintenance so I can continue using it. Here is my:</h5>
                                            <form>
                                                <div className="form-group">
                                                    <input className="form-control" value={this.state.fullName} type="text" placeholder="Name"
                                                        onChange={this.onNotifyNameChange.bind(this)} />
                                                </div>
                                                <div className="form-group">
                                                    {/* <input className="form-control" type="email" placeholder="Email Address" required="" />*/}
                                                    <input className="form-control"
                                                        value={this.state.notifyEmail}
                                                        type="email" name="EMAIL" placeholder="Email Address"
                                                        onChange={this.onNotifyEmailChange.bind(this)} />
                                                </div>
                                                <button className="btn btn-primary" type="button"
                                                    disabled={!validateEmail(this.state.notifyEmail)}
                                                    onClick={this.onSubmit.bind(this)}><i
                                                        className="icon-mail"></i>&nbsp;
                                            Submit
                                                </button>
                                                <br />
                                                {this.state.showErrorMessage &&
                                                    <label
                                                        className={this.state.notifyResponse.status === 'success' ? 'text-success padding-top-10' : 'padding-top-10 warning-checkout'}>{this.state.notifyResponse.message}</label>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <br />
                        <Spinner />
                    </div>
                );
            }
        }
    }
)

export default Maintenance