import React, { Component } from "react";
import history from "./History";
import { getCustomerData, loginUser, checkToken, makeCustomersInternational } from "../services/Network";
import { ACL, Menu, AllowList, appConfig, COUNTRY_NAME, inArray } from "./config";
import ScrollButton from "./ScrollButton";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import { observer } from "mobx-react";
import { reaction } from "mobx";
import {
  isMobile,
  jsUcfirst,
  isCountryKorea,
  getStoreLanguage,
  getStoreLanguageName,
  isProduction,
  getCountryCode,
  getParameterByName,
} from "./GlobalHelpers";
import Redirect from "./Redirect";
import { logi, logtime, logn } from "./utils/PikaLog";
import { isNothing, isNullOrUndefined, testArray } from "./utils/Utils";
import { storeManager, store, devTools } from "../stores/MainStore";
import {
  assignCustomer,
  getCustomerID,
  getCustomerCountryCode,
  getCustomerType,
  getCustomerStatus,
  getCustomerEmail,
  getCustomerHumanName,
  getCustomerActiveStatus,
} from "./utils/Customer";
import { FS } from "../stores/FSStore";
import { findKey, omit, get } from "lodash";
import StickyDevTools from "./fragment/devtools/StickyDevTools";
import axios from "axios";

const AppRouter = observer(
  class AppRouter extends Component {
    constructor(props) {
      super(props);
      this.state = {
        currentPage: history.location.pathname,
        langHide: true,
        userHide: true,
        menuHidden: true,
        loginHide: this.props.store.loginHide,
        menuHide: true,
        footer: {},
        searchText: "",
        openFilter: false,
        isBrowser: true,
        AclProfile: null,
        AclPublic: null,
      };
      this.changeCurrentPage = this.changeCurrentPage.bind(this);
      this.onSearchChange = this.onSearchChange.bind(this);
      this.aclLogout = this.aclLogout.bind(this);
      this.openMenu = this.openMenu.bind(this);
      this.login = this.login.bind(this);
      this.checkPage = this.checkPage.bind(this);
      this.onFilterClick = this.onFilterClick.bind(this);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
      this.setState({ isBrowser: !isMobile() });
    };

    componentDidMount() {
      this.checkAuth();
      this.checkPage(this.state.currentPage);
      window.addEventListener("resize", this.handleWindowSizeChange);

      let user = localStorage.getItem("customerData");
      if (user) {
        user = JSON.parse(user);
        if (user.acl.country !== window.location.pathname.split("/")[1]) {
          this.appLogout();
        }
      }

      this.disposerLanguage = reaction(
        () => store.isLanguageInitialized,
        (init, reaction) => {
          console.log("()()()() lang init?", init);
          if (init === true) {
            store.language = store.dictionaryAll[store.languageCurrent];
            this.getAclComponent();
            reaction.dispose();
          }
        },
        { fireImmediately: true }
      );
    }

    /* componentWillReceiveProps(nextProps) {
            if (nextProps.store.language !== undefined) {
                this.getAclComponent()
            }
        }
        */
    getAclComponent() {
      let country = localStorage.getItem("country");
      country = jsUcfirst(country);
      if (country === "") {
        country = jsUcfirst(COUNTRY_NAME);
        localStorage.setItem("country", jsUcfirst(COUNTRY_NAME));
      }
      import(`./acl/${country}/AclPublic.js`).then((module) => {
        this.setState({ AclPublic: module.default });
        this.setReadyToDisplayHome(`${country}/AclPublic`);
      });
      import(`./acl/${country}/AclProfile.js`).then((module) => {
        this.setState({ AclProfile: module.default });
        this.setReadyToDisplayHome(`${country}/AclProfile`);
      });
    }

    setReadyToDisplayHome(aclName) {
      if (storeManager.isReadyToDisplayHome === false) {
        logi(aclName + " Done!");
        storeManager.isReadyToDisplayHome = true;
        logtime("Total Web Load Time").end();
      }
    }

    onFilterClick(state) {
      this.setState({ openFilter: state });
    }

    _handleNotification = () => {
      if (typeof Notification !== "undefined") {
        if (Notification.permission === "granted") {
          //const notification = new Notification("Hi there!", {icon: '../../../assets/fav_old.ico'});
          //notification.sound
          console.log("already have grant permission");
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              const notification = new Notification("Hi there!", { icon: "../../../assets/fav_old.ico" });
              //notification.sound;
            } else {
              console.log(permission);
            }
          });
        }
      }
    };

    openMenu() {
      store.navigator.submenu = false;
      if (store.navigator.display === "show-nav") {
        store.navigator.display = "";
        document.body.style.overflow = "";
        this.setState({
          menuHide: true,
        });
        store.isSideBarOpen = false;
        store.isShowingFeedback = false;
      } else {
        store.navigator.display = "show-nav";
        document.body.style.overflow = "hidden";
        this.setState({
          menuHide: false,
        });
        store.isSideBarOpen = true;
      }

      document.body.className = this.props.store.navigator.display;
    }

    onSearchChange(searchText) {
      this.setState({ searchText: searchText });
    }

    aclLogout(action) {
      localStorage.removeItem("customerData");
      if (action !== "refresh") {
        localStorage.removeItem("customerHref");
        localStorage.removeItem("customerToken");
      }
      localStorage.removeItem("checkOutStore");
      localStorage.removeItem("footer-data");
      if (action === "force") {
        window.location.href = "/" + appConfig.country;
      }
      this.props.store.authPass = false;
    }

    appLogout(redirect = true) {
      // FS.shutdown()

      localStorage.removeItem("customerData");
      localStorage.removeItem("customerHref");
      localStorage.removeItem("customerToken");
      localStorage.removeItem("enroll_data");
      localStorage.removeItem("enroll_state");
      localStorage.removeItem("itemData");
      localStorage.removeItem("language");
      localStorage.removeItem("productData");
      localStorage.removeItem("products");
      localStorage.removeItem("storageCart");
      localStorage.removeItem("footer-data");
      localStorage.removeItem("checkOutStore");
      localStorage.removeItem("storageEnrollCart");
      localStorage.removeItem("EnrollStorage");
      localStorage.removeItem("ShoppingStorage");

      this.props.store.authPass = false;

      // must redirect to home page to handle access from qrcode
      if (redirect) window.location.href = "/" + appConfig.country;
    }

    changeCurrentPage(page, menuForce) {
      this.setState({ currentPage: page });
      if (menuForce) {
        return false;
      }
      this.openMenu();
    }

    hideLoginMenu() {
      if (this.state.loginHide) {
        this.setState({
          loginHide: false,
        });
        this.props.store.loginHide = false;
      } else {
        this.setState({
          loginHide: true,
        });
        this.props.store.loginHide = true;
      }
    }
 
    triggerReloadProduct() {
      return false;
    }

    login(username, password, callback) {
      loginUser(
        (res, status) => {
          if (status) {
            let href = res.customer.href;
            let token = res.token;
            getCustomerData(
              (res, status) => {
                if (status) {
                  res.acl = this.getAcl(res.type, res.status);
                  logi("User Country:", res.mainAddress.country);

                  if (res.acl === false || res.acl === null) {
                    callback({
                      pass: false,
                    });
                  } else {
                    if (isCountryKorea()) {
                      //if (res.mainAddress.country === 'KR') {
                      if (inArray(res.acl.status, ["A", "B", "E"]) === false) {
                        this.appLogout(false);
                        callback({ pass: false, error: "status" });
                        return false;
                      }
                    }
                    this.recognizeCustomer(res, href, token);

                    makeCustomersInternational((error, response) => {
                      if (error) console.error(error);
                      callback(true);
                    });
                  }
                } else {
                  callback(false);
                }
              },
              token,
              href
            );
          } else {
            callback({
              pass: false,
              error: res.responseJSON.error,
            });
          }
        },
        username,
        password
      );
    }
    
    autoLogin(autoLoginToken) {
      try {
          axios.get(`https://member-calls2.unicity.com/remoteStorage/broker/${autoLoginToken}`).then((response) => {
              const token = response.data.payload.token;
              axios.get(`https://hydra.unicity.net/v5a/customers?unicity=${response.data.payload.baId}&expand=customer`).then((response2) => {
                getCustomerData(
                  (res, status) => {
                    if (status) {
                      res.acl = this.getAcl(res.type, res.status);
                      if (res.acl === false || res.acl === null) {
                        this.aclLogout("force");
                      } else {
                        this.recognizeCustomer(
                          res,
                          response2.data.items[0].href,
                          token
                        );
                        
                        store.customerData = res;
                        window.location.href = "/" + appConfig.country;
                      }
                    } //Fetched new data
                  },
                  token,
                  response2.data.items[0].href
                );
                  response2.data.items.map((item) => {
                      localStorage.setItem("customerToken", token);
                      localStorage.setItem("user-token", token);
                      localStorage.setItem("customerHref", item.href);
                      localStorage.setItem("user-href", item.href);
                  });
              });
          });
          storeManager.isAutoLoginDone = true
      } catch (error) {
          console.log(error);
      }
  }
    checkAuth() {
      //Check if user Authenticated on refresh
      const autoLoginToken = getParameterByName("autoLogin");
      if (autoLoginToken) {
        this.autoLogin(autoLoginToken);
    }
      if (localStorage.getItem("customerHref")) {
        this.props.store.authPass = true;
        this.props.store.customerData = JSON.parse(localStorage.getItem("customerData"));
        checkToken((res, status) => {
          if (status) {
            if (res.loginTokenSecondsLeft < 86400) {
              this.aclLogout("refresh");
              alert("Session has expired. Please log in again");
            } //Less than 24 hours, kick out
            getCustomerData(
              (res, status) => {
                if (status) {
                  res.acl = this.getAcl(res.type, res.status);
                  if (res.acl === false || res.acl === null) {
                    this.aclLogout("force");
                  } else {
                    this.recognizeCustomer(
                      res,
                      localStorage.getItem("customerHref"),
                      localStorage.getItem("customerToken")
                    );
                   
                    store.customerData = res;
                    
                  }
                } //Fetched new data
              },
              localStorage.getItem("customerToken"),
              localStorage.getItem("customerHref")
            );
          } else {
            this.aclLogout("force");
            //alert('Could not authenticate with server');
          } //Request failed
        }, localStorage.getItem("customerToken"));
      }
    }

    recognizeCustomer(data, href, token) {
      localStorage.removeItem("referral");

      const achItems = get(data, "achievementsHistory.items", []);
      if (achItems.length) {
        achItems.map((_, index) => {
          delete achItems[index].metricsDetails;
        });
        data.achievementsHistory = achItems;
      }

      assignCustomer(JSON.stringify(data));

      localStorage.setItem("customerHref", href);
      localStorage.setItem("customerToken", token);

      try {
        const identifyCustomer = () => {
          const customerVariables = {
            displayName: getCustomerHumanName().fullName,
            email: getCustomerEmail(),
            Rank: `${getCustomerStatus()} (${getCustomerType()})`,
            Status: getCustomerActiveStatus(),
            MarketCode: getCustomerCountryCode(),
          };
          FS.identify(getCustomerID(), { ...customerVariables });
        };

        const checkScript = () => {
          if (isNullOrUndefined(window.FS) === false) {
            FS.init(window.FS);
            identifyCustomer();
            logn("[FullStory] initaite is succeed.");
          } else {
            logn("[FullStory] not found, attemp again in 3 seconds.");
            setTimeout(() => {
              checkScript();
            }, 3000);
          }
        };

        if (isProduction() && isCountryKorea()) {
          const s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.innerHTML = `
                        window['_fs_debug'] = false;
                        window['_fs_host'] = 'fullstory.com';
                        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
                        window['_fs_org'] = 'QC7D6';
                        window['_fs_namespace'] = 'FS';
                        (function(m,n,e,t,l,o,g,y){
                            if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                            g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                            o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                            y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                            g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                            g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                            g.log = function(a,b) { g("log", [a,b]) };
                            g.consent=function(a){g("consent",!arguments.length||a)};
                            g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                            g.clearUserCookie=function(){};
                        })(window,document,window['_fs_namespace'],'script','user');
                    `;
          document.body.appendChild(s);
          checkScript();
        }
      } catch (e) {
        console.error(e);
      }

      // try {
      // if (isProduction()) {
      /* LogRocket.init('zfadbv/ushop-express', {
                        network: {
                            requestSanitizer: request => {
                                // if the url contains 'ignore'
                                if (request.url.toLowerCase().indexOf('payment') !== -1) {
                                    // scrub out the body
                                    request.body = null
                                }
                                // otherwise log the request normally
                                return request
                            },
                        },
                    })
                    setupLogRocketReact(LogRocket)

                    LogRocket.identify(getCustomerID(), {
                        name: `${getCustomerHumanName().firstName} ${getCustomerHumanName().lastName}`,
                        email: `${getCustomerEmail()}`,
                        // Add your own custom user variables here, ie:
                        From: `${getCustomerCountryCode()}`,
                        Status: `${getCustomerStatus()} (${getCustomerType()})`,
                        Language: `${getStoreLanguage()} (${getStoreLanguageName()})`,
                    }) */
      // }
      /* } catch(e) {
                console.error(e)
            } */
    }

    checkTypeAccept(aclMenu, type) {
      const shortType = findKey(ACL, { api: { type: type } });
      if (testArray(shortType, aclMenu.onlyStatus)) {
        return true;
      } else if (testArray(shortType, aclMenu.exceptStatus)) {
        return false;
      } else {
        return true;
      }
    }

    getAcl(type, status) {
      let acl = null;
      Object.keys(ACL).map((v, k) => {
        if (ACL[v].api.type === type && ACL[v].api.status === status) {
          if (ACL[v].allowed === false) {
            return false;
          }

          acl = ACL[v];
          acl.status = v;
          acl.allowList = AllowList[ACL[v].allowed];

          if (acl.allowList === true) {
            acl.mainMenu = Menu(this.props.store.language);
          } else {
            let menu = [];
            let tempMenu = Menu(this.props.store.language);

            tempMenu.map((b, i) => {
              if (acl.allowList.sideBar.indexOf(b.key) >= 0) {
                menu.push(b);
              }
            });
            acl.mainMenu = menu;
          }
          acl.inPageMenu = {};

          Object.keys(AllowList.inPageMenu).map((m, n) => {
            if (acl.inPageMenu[m] === undefined) {
              acl.inPageMenu[m] = [];
            }
            AllowList.inPageMenu[m].map((a, b) => {
              if (a.allowed.indexOf(ACL[v].allowed) >= 0) {
                if (a.countries) {
                  if (testArray(getCountryCode(), a.countries)) {
                    if (this.checkTypeAccept(a, type)) {
                      acl.inPageMenu[m].push(JSON.parse(JSON.stringify(a)));
                    }
                  }
                } else {
                  if (this.checkTypeAccept(a, type)) {
                    acl.inPageMenu[m].push(JSON.parse(JSON.stringify(a)));
                  }
                }
              }
            });
          });
        }
      });
      if (acl !== null && acl !== false) {
        acl.country = appConfig.country;
      }
      
      return acl;
    }

    checkPage(page) {
      if (page === "/sem") {
        this.setState({ siteWrapHide: true });
      }
    }

    render() {
      if (isNothing(store.language) || isNothing(store.language.language)) {
        return <Redirect />;
      }
      if(getParameterByName('autoLogin')){
        return <Redirect />;
      }

      this.triggerReloadProduct(this.props.shopStore.productDataSource);
      this.triggerReloadProduct(this.props.shopStore.quickNavNext);
      let showFilter = isMobile()
        ? this.props.store.subIndexTab === 1 && window.location.pathname.includes("/shop")
        : false;
      let user = localStorage.getItem("customerData");
      const { AclPublic: AclPublic, AclProfile: AclProfile } = this.state;

      return (
        <div className={this.props.store.language.language + "-css"}>
          {/* <StickyDevTools/> */}
          {/* Not Logged in */}
          {user === null && AclPublic !== null && (
            <AclPublic
              {...this.props}
              {...this.state}
              appLogout={() => this.appLogout()}
              login={(username, password, callback) => this.login(username, password, callback)}
              openMenu={() => this.openMenu()}
              onSearchChange={(searchText) => this.onSearchChange(searchText)}
              changeCurrentPage={(page, menuForce) => this.changeCurrentPage(page, menuForce)}
              setInitialLanguage={(lang) => this.props.setInitialLanguage(lang)}
            />
          )}
          {/* Profile */}
          {user !== null &&
            AclProfile !== null &&
            this.props.store.customerData.acl !== undefined &&
            this.props.store.customerData.acl.allowed === "profile" && (
              <AclProfile
                {...this.props}
                {...this.state}
                history={history}
                appLogout={() => this.appLogout()}
                openMenu={() => this.openMenu()}
                onSearchChange={(searchText) => this.onSearchChange(searchText)}
                login={(username, password, callback) => this.login(username, password, callback)}
                changeCurrentPage={(page, menuForce) => this.changeCurrentPage(page, menuForce)}
                setInitialLanguage={(lang) => this.props.setInitialLanguage(lang)}
              />
            )}

          {/* Scroll Button */}
          {this.props.store.subIndexTab !== 2 && this.props.enrollStore.step !== 4 && (
            <ScrollButton scrollStepInPx="120" delayInMs="3" filter={showFilter} onFilterClick={this.onFilterClick} />
          )}
          <div
            onClick={() => this.setState({ langHide: true })}
            className="whitefade"
            hidden={this.state.langHide}
          ></div>
          {/* <div onClick={() => this.props.store.loginHide = true} className="whitefade" hidden={this.props.store.loginHide}></div> */}
          <div
            onClick={() => this.setState({ userHide: true })}
            className="whitefade"
            hidden={this.state.userHide}
          ></div>
          <div onClick={() => this.openMenu()} className="whitefade" hidden={this.state.menuHide}></div>
        </div>
      );
    }
  }
);

export default AppRouter;
