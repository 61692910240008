import * as Apps from "./Apps"
import * as API from "./API"
import * as Address from "./Address"
import * as Payment from "./Payment"
import * as ShippingDetails from "./ShippingDetails"
import * as Store from "./Store"
import * as Country from './Country'
import * as Template from './Template'

import { getFooterLanguageControl } from "../GlobalHelpers"
import { ifNothingThenEmpty, deepFreeze, isAcknowledge } from "../utils/Utils"
import { throatStage, throatBoolean, throatString, throatFallback, throatObject, throatArray, throatAllCustom } from "./ConfigsHeader";
import { getCustomerCountryCode, isCustomerLogin } from "../utils/Customer";
import { logi } from "../utils/PikaLog";


export default class Configs {
    static isInit = false
    static init = () => {
        if (Configs.isInit === false) {
            // init every configs into immutable object
            AddressConfigs.init()
            APIConfigs.init()
            AppsConfigs.init()
            PaymentConfigs.init()
            ShippingDetailsConfigs.init()
            StoreConfigs.init()
            Configs.isInit = true
        }
    }
}

// Apps
export const AppsConfigs = {
    init()                  { deepFreeze(Apps) },
    URL()                   { return throatStage(Apps.URL) },
}

// API
export const APIConfigs = {
    init()                  { deepFreeze(API) },
    Products()              { return throatString(API.Products, CountryConfigs.CountryCode3Express()) }
}

/** Country configs. */
export const CountryConfigs = { 
    init()                  { deepFreeze(Country) },
    /** Get country code as ISO 3166-1 alpha-2 code. (e.g. **TH**, **AU**, **NZ**) */
    CountryCode()           { return throatString(Country.CountryCode) },
    /** Get country code as ISO 3166-1 alpha-3 code. (e.g. **JPN**, **AUS**, **NZL**) */
    CountryCode3()          { return throatString(Country.CountryCode3) },
    /** Get express country code as ISO 3166-1 alpha-2 by added **X** at the beginning of it. (e.g. **XJP**, **XAU**, **XNZ**) */
    CountryCodeExpress(lowerCase = false) {
        const r = throatString(Country.CountryCodeExpress)
        return lowerCase ? r.toLowerCase() : r
    },
    /** Get a beautiful output of country. Can be translated into native version. (e.g. **ประเทศไทย**, **Indonesia**, **New Zealand** */
    CountryFullDisplay()    { return throatString(Country.CountryFullDisplay) },
    /** Get a lowercase with non-space output of country. (e.g. **thailand**, **indonesia**, **newzealand**) */
    CountryFull()           { return throatString(Country.CountryFull) },

    CountryTimeZones()      { return throatString(Country.CountryTimeZones) },

    OfflineProduction()     { return throatBoolean(Country.CountryOfflineProduction) },
}

// Store
export const StoreConfigs = {
    init()                  { deepFreeze(Store) },
    Login()                 { return throatBoolean(Store.Login) },
    EasyShop()              { return throatBoolean(Store.EasyShop) },
    EasyChoicePriceLevel()  { return throatObject(Store.EasyChoicePriceLevel) },
    EasyChoiceCustomerType(){ return throatObject(Store.EasyChoiceCustomerType) },
    Products()              { return throatFallback(Store.Products, CountryConfigs.CountryCodeExpress()) },
    FooterStatic()          { return throatFallback(Store.FooterStatic, CountryConfigs.CountryCodeExpress()) },
    FooterSpecial()         { return throatFallback(Store.FooterSpecial, CountryConfigs.CountryCodeExpress()) },
    PeriodCode()            { return throatFallback(Store.PeriodCode, CountryConfigs.CountryCodeExpress()) }
}

// Address
export const AddressConfigs = {
    init()                  { deepFreeze(Address) },
    AddressBook()           { 
        const l = Address.AddressBook.Local()
        const g = Address.AddressBook.Global()
        if (isCustomerLogin()) {
            const c = getCustomerCountryCode()
            if (isAcknowledge(c)) {
                const allLocal = throatAllCustom(CountryConfigs.CountryFull(), l, false)
                const allGlobal = throatAllCustom(Country.CountryCodeToFull[c], g, false)
                const isLocalUse = throatBoolean(l) && allLocal === true
                const isGlobalUse = throatBoolean(g) && allGlobal === true
                // logi(`AddressBook: Local > ${isLocalUse} | Global > ${isGlobalUse}`)
                // check local first then check global
                return isLocalUse ? isGlobalUse : false
            }
        }
        // logi(`AddressBook: Local > ${throatBoolean(l)} | Global > [NO-CUSTOMER]`)
        return throatBoolean(l) 
    },
    EmailEdit()             { 
        const l = Address.EmailEdit.Local()
        const g = Address.EmailEdit.Global()
        if (isCustomerLogin()) {
            const c = getCustomerCountryCode()
            if (isAcknowledge(c)) {
                const allLocal = throatAllCustom(CountryConfigs.CountryFull(), l, false)
                const allGlobal = throatAllCustom(Country.CountryCodeToFull[c], g, false)
                const isLocalUse = throatBoolean(l) && allLocal === true
                const isGlobalUse = throatBoolean(g) && allGlobal === true
                //logi(`EmailEdit: Local > ${isLocalUse} | Global > ${isGlobalUse}`)
                // check local first then check global
                return isLocalUse ? isGlobalUse : false
            }
        }
        //logi(`EmailEdit: Local > ${throatBoolean(l)} | Global > [NO-CUSTOMER]`)
        return throatBoolean(l) 
    },
}

// ShippingDetails
export const ShippingDetailsConfigs = {
    init()                  { deepFreeze(ShippingDetails) },
    WeightDisplay()         { return throatBoolean(ShippingDetails.WeightDisplay) },
}

// Payment Gateways
export const PaymentConfigs = {
    init()                  { deepFreeze(PaymentConfigs) },
    TermsAndConditions() {
        const data = throatObject(Payment.TermsAndConditions)[getFooterLanguageControl()]
        return ifNothingThenEmpty(data)
    },
    MerchantIDforOCBC()     { return throatStage(throatObject(Payment.MerchantID.ocbc)) },
    MerchantIDforTest()     { return throatStage(throatObject(Payment.MerchantID.test)) },
    GatewaysHydra()         { return throatBoolean(Payment.GatewaysHydra) }
}

// Template
export const TemplateConfigs = {
    init()                  { deepFreeze(TemplateConfigs) },
    FindOurShop()           { return throatBoolean(Template.FindOurShop) },
    Disclaimer()            { return throatBoolean(Template.Disclaimer) },
}