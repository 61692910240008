import * as K from './Keywords'
import { isNullOrUndefined } from '../utils/Utils';

/** Control the visibility of all **AddressBook** functional both local and global. */
export const AddressBook = {
    /** Control the visibility of **AddressBook** in these country. */
    Local() { 
        return {
            [K.All]: true,
            [K.Japan]: false,
            [K.MENA]: false,
            [K.Korea]: false,
        }
    },
    /** Customers from these country ***won't*** able to use an **AddressBook**, wherever they go. */ 
    Global() { 
        return {
            [K.All]: true,
            [K.Japan]: false,
            [K.MENA]: false,
            [K.Korea]: false,
            [K.Vietnam]: false
        }
    }
}

/** Control the visibility of all **EmailEdit** functional both local and global. */
export const EmailEdit = {
    /** Control the visibility of **EmailEdit** on profile page of these country. */
    Local() { 
        return {
            [K.All]: true,
        }
    },
    /** Customers from these country ***won't*** able to use an **EmailEdit**, wherever they go. */ 
    Global() { 
        return {
            [K.All]: true,
            [K.Vietnam]: false
        }
    }
}

export const ShippingAddress = {
    aramexAddress: {
        address1: 'Aramax (AIC Dept.) 3 Changi South Street 1, #01-01',
        address2: 'Santa United International  Bldg.',
        mobilePhone: '6565430300',
        city: 'Singapore',
        country: 'SG',
        zip: '486795'
    },
    pickUpAddress: {
        fullName: 'Singapore Main Office',
        address1: '3 HarbourFront Place',
        address2: '#01-01, HarbourFront Tower 2',
        city: 'Singapore',
        country: 'SG',
        zip: '099254'
    }
}

// ** Address order form config
export function AddressFormCheckout(form, country) {
    const list = {
        name: { [K.All]: true },
        country:  { 
            [K.All]: true,
            [K.MENA]: false
        },
        address1: { [K.All]: true },
        address2: { [K.All]: true, [K.Australia]: false, [K.Newzealand]: false },
        district: {
            [K.Indonesia]: true,
            [K.Hongkong]: true
        },
        city: {
            [K.Malaysia]: true,
            [K.Australia]: true,
            [K.Newzealand]: true,
            [K.Korea]: true
        },
        state: {
            [K.Australia]: true,
            [K.Newzealand]: true,
            [K.Australia]: true,
            [K.Korea]: true
        },
        province: {
            [K.Indonesia]: true,
            [K.Philippines]: true,
        },
        comment: {
            [K.Malaysia]: true,
            [K.Philippines]: true,
            [K.Indonesia]: true,
            [K.Korea]: true,
            [K.Hongkong]: true
        },
        zip: { 
            [K.All]: true ,
            [K.Hongkong]: false
        },
        mobile: { [K.All]: true },
        email:  { [K.All]: true },
        // aramex
        sns1:   { [K.MENA]: true },
        sns2:   { [K.MENA]: true },
        // other html
        hr:     { [K.All]: true }
    }

    const field = list[form]
    if (isNullOrUndefined(field)) {
        return false
    } else {
        if (isNullOrUndefined(field[country])) {
            return isNullOrUndefined(field['all']) ? false : field['all']
        } else {
            return field[country]
        }
    }
}

/** Control Addressbook form on Checkout page here. */
export const AddressFormCheckoutArrangement = (country) => {
    /* How to use Dynamic Addressbook:
    ** [1] use `_` to add sub ID such as `name_jp` <- this `name` form will be checked with AddressFormCheckout() function (use or not use)
    ** you also need to added `name_jp` in html form too.
    ** [2] add `#s` to make this form small (in half-size), you need to add these forms in pair (e.g. 2, 4 or 6) 
    ** or it will not display when without its pair. */
    const list = {
        [K.Australia]: [ 'name', 'address1', 'address2', 'zip', 'city', 'state', 'country', 'mobile#s', 'email#s' ],
        [K.Newzealand]: [ 'name', 'address1', 'address2', 'city', 'state', 'country#s', 'zip#s', 'mobile#s', 'email#s' ],
        [K.Malaysia]: [ 'name', 'address1', 'address2', 'city', 'comment', 'country#s', 'zip#s', 'mobile#s', 'email#s' ],
        [K.Indonesia]: [ 'name', 'address1', 'address2', 'district', 'city', 'province', 'comment', 'country#s', 'zip#s', 'mobile#s', 'email#s' ],
        [K.Philippines]: [ 'name', 'address1', 'address2', 'city', 'province', 'comment_ph', 'country#s', 'zip#s', 'mobile#s', 'email#s' ],
        [K.Japan]: [
            'name_xjp-new-en', 'address1_xjp-new-en', 'address2_xjp-new-en',
            'name_xjp-new-jp', 'address1_xjp-new-jp', 'address2_xjp-new-jp',
            'country#s', 'zip#s', 'email#s', 'mobile#s'],
        [K.MENA]: [ 'name_mde', 'address1_mde', 'address2_mde', 'zip_mde#s', 'mobile_mde#s', 'email_mde', 'sns1_mde#s', 'sns2_mde#s'],
        [K.Korea]: [ 'name', 'address1', 'address2', 'state', 'city', 'country#s', 'zip#s', 'mobile#s', 'email#s' ],
        [K.Hongkong]: [ 'name', 'address1', 'address2', 'district_hk', 'comment#s', 'country#s', 'mobile#s', 'email#s' ],
    }

    const defaultSetting = ['name', 'address1', 'address2', 'district', 'city', 'province', 'state', 'comment', 'country', 'zip', 'mobile', 'email' ]
    const arrangement = list[country]
    if (isNullOrUndefined(arrangement)) {
        return defaultSetting
    } else {
        return arrangement
    }
}

// ** Addressbook form config
export function AddressFormProfile(form, country) {
    const list = {
        name: { [K.All]: true },
        country:  { [K.All]: true },
        address1: { [K.All]: true },
        address2: { [K.All]: true },
        district: {
            [K.Indonesia]: true,
            [K.Hongkong]: true
        },
        city: {
            [K.Malaysia]: true,
            [K.Australia]: true,
            [K.Newzealand]: true,
            [K.Korea]: true
        },
        state: {
            [K.Newzealand]: true,
            [K.Australia]: true,
            [K.Korea]: true
        },
        province: {
            [K.Indonesia]: true,
            [K.Philippines]: true,
        },
        zip: { 
            [K.All]: true ,
            [K.Hongkong]: false
        }
    }
    
    const field = list[form]
    if (isNullOrUndefined(field)) {
        return false
    } else {
        if (isNullOrUndefined(field[country])) {
            return isNullOrUndefined(field['all']) ? false : field['all']
        } else {
            return field[country]
        }
    }
}

/** Control Addressbook form on Profile page here. */
export const AddressFormProfileArrangement = (country) => {
    /* How to use Dynamic Addressbook:
    ** [1] use `_` to add sub ID such as `name_jp` <- this `name` form will be checked with AddressFormCheckout() function (use or not use)
    ** you also need to added `name_jp` in html form too.
    ** [2] add `#s` to make this form small (in half-size), you need to add these forms in pair (e.g. 2, 4 or 6) 
    ** or it will not display when without its pair. */
    const list = {
        [K.Australia]: [ 'name', 'country#s', 'address1#s', 'address2#s', 'city#s', 'state#s', 'zip#s' ],
        [K.Newzealand]: [ 'name', 'address1#s', 'address2#s', 'city#s', 'state#s', 'zip#s', 'country#s' ],
        [K.Malaysia]: [ 'name#s', 'country#s', 'address1#s', 'address2#s', 'city_my#s', 'zip#s' ],
        [K.Indonesia]: [ 'name', 'address1_id#s', 'address2_id#s', 'district#s', 'province#s', 'country#s', 'zip#s' ],
        [K.Philippines]: [ 'name#s', 'country#s', 'address1#s', 'address2#s', 'province#s', 'zip#s' ],
        [K.Hongkong]: [ 'name', 'address1#s', 'address2#s', 'district_hk#s', 'country#s' ],
    }

    const defaultSetting = ['name', 'address1', 'address2', 'district', 'city', 'province', 'state', 'comment', 'landmark', 'country#s', 'zip#s' ]
    const arrangement = list[country]
    if (isNullOrUndefined(arrangement)) {
        return defaultSetting
    } else {
        return arrangement
    }
}