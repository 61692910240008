import * as K from './Keywords'
import { getCurrentDomain } from './ConfigsHeader'

export const TermsAndConditions = {
    [K.Japan]: {
        [K.English]: `${getCurrentDomain()}/japan/#/disclaimer`,
        [K.Native]: `${getCurrentDomain()}/japan/#/disclaimer`
    }
}

export const GatewaysHydra = {
    [K.Australia]: true,
    [K.Newzealand]: true
}

export const MerchantID = {
    [K.OCBC]: {
        [K.All]: {
            [K.Production] : '8408400227349002280',
            [K.Development]: '8408400227349002280',
            [K.Localhost]: '8408400227349002280'
        },
        [K.Australia]: {
            [K.Production]: '0360360227149002440',
            [K.Development]: '0360360227149002440',
            [K.Localhost]: '8408400227349002280'
        },
        [K.Japan]: {
            [K.Production]: '8408400227149001370',
            [K.Development]: '8408400227149001370',
            [K.Localhost]: '8408400227149001370'
        },
        [K.Korea]: {
            [K.Production]: '8408400227449002672',
            [K.Development]: '8408400227449002672',
            [K.Localhost]: '8408400227449002672'
        }
    },
    test: {
        [K.All]: {
            [K.Production]: 'all_pro',
            [K.Development]: 'all_dev',
            [K.Localhost]: 'all_loc'
        },
        [K.Japan]: {
            [K.Development]: 'jp_dev',
            [K.Localhost]: 'jp_loc',
            [K.Production]: 'jp_pro'
        }
    }
}