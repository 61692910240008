import { isProduction } from '../GlobalHelpers';
import { isString } from './Utils';
import { isChrome } from './Bowser';
import { devTools } from '../../stores/MainStore';

// ** --------- Log control functions --------- ** //
const HEADER_DEVLOG     = '[LOG]'
const HEADER_DEVLOGW    = '[WATCHER]'
const HEADER_DEVLOGE    = '[ERROR]'
const HEADER_DEVLOGN    = '[NOTICE]'
const HEADER_DEVLOGTIME = '[TIMER]'

const BASH_ESC          = '\x1B'
const BASH_DEFAULT      = BASH_ESC + '[39m'
const BASH_RED          = BASH_ESC + '[31m'
const BASH_GREEN        = BASH_ESC + '[32m'
const BASH_YELLOW       = BASH_ESC + '[33m'
const BASH_BLUE         = BASH_ESC + '[34m'
const BASH_MAGENTA      = BASH_ESC + '[35m'
const BASH_CYAN         = BASH_ESC + '[36m'
const BASH_WHITE        = BASH_ESC + '[37m'
const BASH_GRAY         = BASH_ESC + '[90m'

const BG_END            = BASH_ESC + '[49m'
const BG_CYAN           = BASH_ESC + '[46m'
const BG_MAGENTA        = BASH_ESC + '[45m'
const BG_BLUE           = BASH_ESC + '[44m'
const BG_YELLOW         = BASH_ESC + '[43m'
const BG_GREEN          = BASH_ESC + '[42m'
const BG_RED            = BASH_ESC + '[41m'

/** Return `true` if ***not*** a Production stage, Simulating live or forced app to show logs. */
export const isValidToLog = () => {
    return isProduction() ? devTools.isShowDevLog : isProduction() === false
}

/** **[LOG]** 
 * Log control function for the ***General Purpose*** messages. This method is mimic `console.log` method,
 * but it always disabled on live production plus the unique prefix string. 
 * @see DevelopmentTools - If you want to enabled on live production.
 * @param {*} messages any string messages, object, array, etc.
 */
export const logi = (...messages) => {
    if (isValidToLog() === false) return
    const tag = isChrome() ? BASH_BLUE + HEADER_DEVLOG + BASH_DEFAULT : HEADER_DEVLOG
    let i = -1, l = messages.length, args = [], fn = 'console.log("' + tag + '", args)'
    while (++i < l) args.push('args[' + i + ']')
    fn = new Function('args', fn.replace(/args/, args.join(',')))
    fn(messages)
}

/** **[NOTICE]** 
 * Log control function for the ***Notice Purpose*** messages. This method is mimic `console.log` method,
 * but it always disabled on live production plus the unique prefix string. 
 * @see DevelopmentTools - If you want to enabled on live production.
 * @param {*} messages any string messages, object, array, etc.
 */
export const logn = (...messages) => {
    if (isValidToLog() === false) return
    const tag = isChrome() ? BASH_GREEN + HEADER_DEVLOGN + BASH_DEFAULT : HEADER_DEVLOGN
    let i = -1, l = messages.length, args = [], fn = 'console.log("' + tag + '", args)'
    while (++i < l) args.push('args[' + i + ']')
    fn = new Function('args', fn.replace(/args/, args.join(',')))
    fn(messages)
}

/** **[WATCHER]** 
 * Log control function for ***Watchers*** which triggered by `autorun` method. This method is mimic `console.log` method,
 * but it always disabled on live production plus the unique prefix string. 
 * @see DevelopmentTools - If you want to enabled on live production.
 * @param {*} messages any string messages, object, array, etc.
 */
export const logw = (...messages) => {
    if (isValidToLog() === false) return
    const tag = isChrome() ? BASH_MAGENTA + HEADER_DEVLOGW + BASH_DEFAULT : HEADER_DEVLOGW
    let i = -1, l = messages.length, args = [], fn = 'console.log("' + tag + '", args)'
    while (++i < l) args.push('args[' + i + ']')
    fn = new Function('args', fn.replace(/args/, args.join(',')))
    fn(messages)
}

/** **[ERROR]** 
 * Log control function for the ***Error*** messages. This method is mimic `console.log` method,
 * but it always disabled on live production plus the unique prefix string. 
 * @see DevelopmentTools - If you want to enabled on live production.
 * @param {*} messages any string messages, object, array, etc.
 */
export const loge = (...messages) => {
    if (isValidToLog() === false) return
    const tag = isChrome() ? BASH_RED + HEADER_DEVLOGE + BASH_DEFAULT : HEADER_DEVLOGE
    let i = -1, l = messages.length, args = [], fn = 'console.log("' + tag + '", args)'
    while (++i < l) args.push('args[' + i + ']')
    fn = new Function('args', fn.replace(/args/, args.join(',')))
    fn(messages)
}

/** **[Information Table]** 
 * Log control function which generated readable ***Table*** from `Object` or `Array`. This method is mimic `console.table` method. 
 * An output will be wrapped with `console.groupCollapsed` which can be specified label at the first index of method's arguments.
 * This method always disabled even on the development stage.
 * @see DevelopmentTools - If you want to enabled this log.
 * @param {*} object object or array. If you want to change group label, specified first index with string.
 */
export const logsys = (...messages) => {
    if (devTools.isShowInfoLog === false) return
    let i = -1, l = messages.length, args = [], fn = 'console.table(args)'
    let label = 'logsys'
    if (isString(messages[0])) {
        label = messages[0]
        messages.shift()
    }
        
    while (++i < l) args.push('args[' + i + ']')
    fn = new Function('args', fn.replace(/args/, args.join(',')))
    console.groupCollapsed(label)
    fn(messages)
    console.groupEnd()
}

export const lognum = (number) => {
    if (isValidToLog() === false) return
    const tag = isChrome() ? BASH_BLUE + HEADER_DEVLOG + BASH_DEFAULT : HEADER_DEVLOG
    console.log(tag, number)
}

export const logtime = (label) => {
    if (isValidToLog() === false) return {
        start: () => {}, end: () => {}
    }
    const tag = isChrome() ? BG_YELLOW + HEADER_DEVLOGTIME + BG_END + ' ' + label : label
    return {
        start: () => console.time(tag),
        end: () => console.timeEnd(tag)
    }
}

/** Log control function, could be disable on live production. Create dash line, the number of dash depend on x multiply with 5. */
export const loghr = (x) => {
    let line = ''
    for (let i = 0; i < x; i++) {
        line += '-----'
    }
    logi(line)
}
// -------------------------------------------------