/* eslint-disable */
import $ from 'jquery'
import { apiURL, apiHEADER, getCountryExpress, getCountryStaticFooter, getCountryCode, isWordExist, isCountryJapan, isCountryKorea, getMarketCode, getCountryCode3, getNativeName, isCountryAustralia } from '../components/GlobalHelpers'
import { apiURL as API, getUserCountryCode, getHydraNotes, getLoginUserId } from '../components/ShoppingHelper'
import { StoreConfigs } from '../components/configs/Configs';
import { getBrowserName, getPlatformType, getOSName } from '../components/utils/Bowser';
import { defaults, isFunction, isString, isJSON, justOneOf } from '../components/utils/Utils';
import { devTools } from '../stores/MainStore';
import { createCustomerToken, getCustomerHumanName, getCustomerID, getCustomerStatus, getCustomerToken } from '../components/utils/Customer';
import moment from 'moment';
import { getCurrentDomain, isLocalhost } from '../components/configs/ConfigsHeader';
import { CountryCode, CountryCode3 } from '../components/configs/Country';

const { REACT_APP_ENV, NODE_ENV } = process.env
const USE_STAGE = REACT_APP_ENV || NODE_ENV
const memberCalls2Endpoints = {
    development: 'https://member-calls2-dev.unicity.com',
    production: 'https://member-calls2.unicity.com',
}
const memberCalls2Url = memberCalls2Endpoints[USE_STAGE]

export const get = (url, callback) => {
    $.ajax({
        'type': 'GET',
        'url': url,
        'success': (response) => {
            callback(response, true)
        },
        'error': (response) => {
            callback(response, false)
        }
    })
}

export const post = (url, data, callback, headers) => {

    const settings = {
        'type': 'POST',
        'url': url,
        'success': (response) => {
            callback(response, true)
        },
        'error': (response) => {
            callback(response, false)
        }
    }

    if(data !== undefined) {
        settings.data = data
    }

    if(headers !== undefined) {
        settings.headers = headers
    }


    $.ajax(settings)
}

const __post = (url, body, configs, fulfilledInterceptor, rejectInterceptor) => {
    let resultEndpoint = url

    const options = {
        method: 'POST',
        url: resultEndpoint,
        data: JSON.stringify(body),
        async: true,
        ...configs
    }

    return new Promise((resolve, reject) => {
        $.ajax(options)
            .done(response => {
                if (isFunction(fulfilledInterceptor)) {
                    fulfilledInterceptor(response)
                }
                if (isString(response)) {
                    if (isJSON(response)) {
                        response = JSON.parse(response)
                    }
                }
                resolve(response)
            })
            .fail((xhr, textStatus, errorThrown) => {
                const { responseJSON } = xhr
                if (isFunction(xhr, textStatus, errorThrown)) rejectInterceptor(xhr, textStatus, errorThrown)
                reject(responseJSON)
            })
    })
}

const requestSplash = (productID, templateID, isForceShortURL = false) => {

    const data = {
        'country_code': getCountryCode3(),
        'member_id': getCustomerID(),
        'member_name': getCustomerHumanName().fullName,
        'member_native_name': '',
        'short_member_type': getCustomerStatus(),
        'items_to_products': [
            {
                'item_code': productID,
                'qty': '1'
            }
        ],
        'template_ref': templateID,
        'ship_address': '{}',
        'call_from': `${getCurrentDomain()}`
    }

    return __post(
        `https://member-calls2${isLocalhost() ? '-dev' : ''}.unicity.com/splash-page-link/main${isForceShortURL ? '?force_short_url=true' : ''}`,
        data,
        {
            headers: { 
                'Authorization': `Bearer ${getCustomerToken()}`,
                'Content-Type': 'application/json'
            }
        }
    )
}

export const Raven = { 
    post: __post,
    getSplash: requestSplash
}

// ---------------------------------------------------------------------------------------------------

export function doSearchBA(callback, text, sponsor, token) {
    //'Authorization': 'Bearer ' + token
    $.ajax({
        'type': 'GET',
        'url': `https://hydra.unicity.net/v5a/customers?fulltext=${text}&sponsor.id.unicity=${sponsor}`,
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (err) {
            callback(err, false)
        }
    });
}
export function getStaticFooter(callback) {
    $.ajax({
        'type': 'GET',
        'async': false,
        'url': 'https://member-calls4.unicity.com/api/unishop/v1/global/footer/' + StoreConfigs.FooterStatic(),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (err) {
            callback(err, false)
        }
    })
}

export function getAwsStaticFooter(callback) {
    $.ajax({
        'type': 'GET',
        async: true,
        'url': `${memberCalls2Url}/footer/data/${StoreConfigs.FooterStatic()}`,
        'success': function (result) {
            const { footer, country_code } = result
            const success = true
            const data = { country_code, footer }
            callback({ success, data }, true)
        },
        'error': function (err) {
            callback(err, false)
        }
    });
}

export function getGeoUrl(callback, code) {
    let data = {
        "source": 'UNISHOP-WEB',
        "country_code": code
    };
    data = JSON.stringify(data);
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/ALL/ALL_Unishop_Geolocation.asp',
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (err) {
            callback(err, false)
        }
    });
}

export function getGeoLocation(callback) {
    $.ajax({
        'type': 'GET',
        'url': 'http://api.ipstack.com/check?access_key=16ea76bbc5cb14d33d5f5e5bb36690b3',
        'success': function (result) {
            callback(result)
        },
        'error': function (result) {
            callback(result)
        }
    });
}

export function generateEnrollUrl(callback, url) {
    $.ajax({
        'type': 'POST',
        'url': 'https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyCyDpORYkLdL2Fyb-TcHd1iH3BDXWOG8vk',
        'headers': { 'Content-Type': 'application/json' },
        'data': JSON.stringify({ longUrl: url }),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getHref(callback, id) {
    let url = "https://hydra.unicity.net/v5a/customers?unicity=" + id
    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getUserDetail(callback, id) {
    let url = "https://hydra.unicity.net/v5a/customers?unicity=" + id + "&expand=customer,achievementsHistory"
    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getHotProduct(callback) {
    let url = "https://member-th.unicity.com/cpanel/wp-admin/admin-ajax.php?action=unimobiapp_get_products&args[meta_key]=product_hot&args[meta_value]=yes"
    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function changeMobilePhone(callback, url, token, mobilePhone) {
    let data = {
        mobilePhone: mobilePhone
    }
    $.ajax({
        'type': 'POST',
        'url': apiURL.hydraStandard + '/customers/me',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}


export function changeEmail(callback, url, token, email) {
    let data = {
        email: email
    }
    $.ajax({
        'type': 'POST',
        'url': apiURL.hydraStandard + '/customers/me',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function changePassword(callback, url, token, password) {
    let data = {
        value: password
    }
    $.ajax({
        'type': 'POST',
        'url': apiURL.hydraStandard + '/customers/me/password',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function deleteNickName(callback, url, token) {
    $.ajax({
        'type': 'DELETE',
        'url': url,
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getGenealogy(callback, href, level, limit, token) {
    let url = href + '/sponsoredCustomersTreePreOrder?maxTreeDepth=' + level + '&limit=' + limit + '&country=' + getCountryCode() + '&expand=self&_httpHeaderAuthorization=Bearer%20' + token;
    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getVip(callback, id) {
    $.ajax({
        'type': 'GET',
        'url': apiURL.getVip + id,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function expandCustomer(callback, href, id) {
    const token = createCustomerToken(id)
    $.ajax({
        'type': 'GET',
        'url': `${apiURL.expandCustomer}${id}?token=${token}`,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function uplineSponsoring(callback, sponsorhref, enrollerID) {

    $.ajax({
        'type': 'GET',
        'url': sponsorhref + apiURL.uplineSponsoringCustomer + enrollerID,
        'success': function (result) {
            var dataSet = result.replace(/-/g, "");
            var obj = JSON.parse(dataSet);
            callback(obj, true)
        },
        'error': function (result) {
            var dataSet = result.replace(/-/g, "");
            var obj = JSON.parse(dataSet);
            callback(obj, true)
        }
    });
}

export function hydraRequestByUrl(callback, token, url) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}


export function hydraRequestByUrlPost(callback, token, url, data) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': url,
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getPeriod(callback, id, market) {
    let data = {
        ba_id: id,
        country: market
    }

    $.ajax({
        'type': 'POST',
        'url': apiURL.getPeriod,
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getCommissionPeriod(callback, id, market) {
    let data = {
        country: "TH",
        system: "AO",
    }
    $.ajax({
        'type': 'POST',
        'url': apiURL.getPeriodCommission,
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getCommissions(callback, token) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': apiURL.getCommissionData,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


export function checkToken(callback, token) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': apiURL.whoAmI,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getProduct(callback, price_level, warehouse) {

    if (warehouse !== undefined) {
        var url = apiURL.getProducts + "&price_level=" + price_level + "&warehouse=" + warehouse
    } else {
        var url = apiURL.getProducts + "&price_level=" + price_level
    }

    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            if (result && result.data && result.data.length > 0) {
                result.data = result.data.filter(removeNoShop);
            }
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
    function removeNoShop(each) {
        return each.remarks!=='no_shop';
    }
}
export function newGetProducts(callback, warehouse) {
    if (warehouse !== undefined) {
        var url = apiURL.newGetProducts + "&warehouse=" + warehouse
    } else {
        var url = apiURL.newGetProducts
    }

    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function shopGetProducts(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/shopprofile/api/shopprofile.php',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function queryOrder(callback, token, href, orderUrl) {
    $.ajax({
        'type': 'GET',
        'url': 'https://member-calls4.unicity.com/queryorder.php?type=2&token=' + token + '&ssurl=' + href + '&link=' + orderUrl,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function queryBuyfrom(callback, token, orderUrl) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': orderUrl,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function reportOrder(callback, token, month) {
    $.ajax({
        'type': 'GET',
        'url': apiURL.orderHistory + token + '&month=' + month,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function genealogyOrders(callback, period, period2, user, token) {
    let logonCountry = getUserCountryCode()
    let market = (logonCountry !== '' && logonCountry !== getCountryCode())
        ? getCountryCode() + '|' + logonCountry
        : getCountryCode();

    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        //'url': 'https://hydra.unicity.net/v5a/customers/' + user + '/ordersAndRmas?dateCreated=[' + period + ';' + period2 + ']&expand=order,rma',
        // todo change to hydra live 
        'url': 'https://hydra.unicity.net/v5a/customers/' + user
            + '/ordersAndRmas?dateCreated=[' + period + ';' + period2
            + ']&expand=order,rma',
            // + '&market=' + market,

        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function makeCustomersInternational(callback) {
    let baCountry = getUserCountryCode()
    let loginId = getLoginUserId()
    let hubCountry = getMarketCode()
   
    if (baCountry !== hubCountry) {
        var data = {
            "customer": {
                "href": "https://hydra.unicity.net/v5a/customers?id.unicity=" + loginId
            },
            "market": hubCountry,
        };

        data = JSON.stringify(data)

        $.ajax({
            'type':'POST',
            'headers':{'Content-Type':'application/json'},
            'url': 'https://hydra.unicity.net/v5a/gears/makecustomersinternational',
            'data': data,
            'async': true,
            'success':function (response) {
                callback(null, response)
            },
            'error':function (error) {
                callback(error, null)
            }
        });
    }else{
        callback()
    }
}

export function makeCustomersInternational_Enroll(ba_id) {
    var data = {
        "customer": {
            "href": "https://hydra.unicity.net/v5a/customers?id.unicity=" + ba_id
        },
        "market": getCountryCode()
    };
    data = JSON.stringify(data)
    $.ajax({
        'type':'POST',
        'headers':{'Content-Type':'application/json'},
        'url': 'https://hydra.unicity.net/v5a/gears/makecustomersinternational',
        'data': data,
        'async': true,
        'success':function (result) {},
        'error':function (result) {}
    });
}

export const APIMakeCustomersInternational = {
    Get(callback, id, timeout = '') {
        const stringTimeout = defaults(timeout, '').toString()
        const data = {
            customer: {
                href: `https://hydra.unicity.net/v5a/customers?id.unicity=${id}`
            },
            market: getMarketCode(),
            timeout: stringTimeout
        }

        post(
            'https://hydra.unicity.net/v5a/gears/makecustomersinternational',
            JSON.stringify(data), callback, {'Content-Type':'application/json'}
        )
    }
}

export function loginUser(callback, username, password) {
    let data = {
        'type': 'base64',
        'value': btoa(unescape(encodeURIComponent(username + ":" + password))),
        'namespace': 'https://hydra.unicity.net/v5a/customers'
    };
    $.ajax({
        'type': 'POST',
        'headers': apiHEADER.preset1,
        'url': apiURL.loginTokens,
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getSeminarData(callback, baid) {
    let id = btoa(baid);
    $.ajax({
        'type': 'GET',
        'url': apiURL.seminarData + id,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function sendFeedback(callback, message, email, baid, countryCode) {
    const device = getPlatformType() + ' ' + getBrowserName() + ' ' + getOSName()
    //console.log(countryCode , message, email, baid, device)
    let data = "market=" + countryCode + "&msg= " + message + "&baid=" + baid + "&email=" + email
    $.ajax({
        'type': 'POST',
        'url': apiURL.sendFeedback,
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getAddress(callback, token, href) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': href + '/shiptooptions',
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function newAddress(callback, token, href, data) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': href + '/shiptooptions',
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function updateAddress(callback, href, token, adrhref, data) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': href + '/shiptooptions/' + adrhref,
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function deleteAddress(callback, href, token, adrhref) {
    $.ajax({
        'type': 'DELETE',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': href + '/shiptooptions/' + adrhref,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getCustomerData(callback, token, href) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': href + "?expand=metricsProfileHistory,profilePicture,achievementsHistory,cumulativeMetricsProfile",
        // 'url': href + "?expand=metricsProfile,metricsProfileHistory,profilePicture,achievementsHistory,cumulativeMetricsProfile",
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}
export function getAchievementsHistory(callback, token, href) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': href + "/achievementsHistory",
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}
export function getProfilePicture(callback, token, href) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': href + "/profilePicture",
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}
export function getMetricsHistory(callback, token, href) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': href,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


export function serviceGetNews(callback) {
    $.ajax({
        'type': 'GET',
        'url': apiURL.getNews,
        'success': function (result) {
            let newsArray = [];
            for (var i in result) {
                newsArray.push({
                    link: result[i].embed_video,
                    image: result[i].image,
                    hot: result[i].new_hot
                })
            }
            return callback(newsArray)
        },
        'error': function (result) { }
    })
}

export function serviceGetMedia(callback) {
    $.ajax({
        'type': 'GET',
        'url': apiURL.getMedia,
        'success': function (result) {
            let mediaArray = [];
            for (var i in result) {
                mediaArray.push({
                    link: result[i].embed_video,
                    image: result[i].image
                })
            }
            return callback(mediaArray)
        },
        'error': function (result) { }
    })
}

export function getAllProduct(callback) {
    $.ajax({
        'type': 'GET',
        'url': apiURL.getAllProduct,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}
export function checkInventory(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': apiURL.getInventory,
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getProvince(callback) {
    $.ajax({
        'type': 'GET',
        'url': apiURL.URL_SERVICES_PROVINCES,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getArea(provinceid, callback) {
    let url = apiURL['URL_SERVICES_AMPHURES'].replace('[provinceId]', provinceid)
    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getSubArea(areaid, callback) {
    let url = apiURL['URL_SERVICES_DISTRICTS'].replace('[amphurId]', areaid)
    $.ajax({
        'type': 'GET',
        'url': url,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function checkValidation(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/THA/THA_Order_Validation.asp',
        'data': data,
        'success': function (result) {
            callback(JSON.parse(result), true)
        },
        'error': function (result) {
            callback(JSON.parse(result), false)
        }
    })
}

export function orderTerms(callback, data) {
    const getUrl = () => {
        const defaultValue = 'https://hydra.unicity.net/v5a/orderTerms?expand=item'
        let newOne = 'hydra.unicity.net/v5'
        if (devTools.enabled) {
            if (devTools.devTopic === 'hydra-endpoint') {
                switch (devTools.devValue) {
                    case 'hydra-live': newOne = 'hydra.unicity.net/v5'; break;
                    case 'hydra-test': newOne = 'hydra.unicity.net/v5-test'; break;
                    case 'hydraqa-live': newOne = 'hydraqa.unicity.net/v5'; break;
                    case 'hydraqa-test': newOne = 'hydraqa.unicity.net/v5-test'; break;
                }
            }

            const newData = JSON.stringify(data).replace(/hydra\.unicity\.net\/v5/g, newOne)
            data = JSON.parse(newData)
            return `https://${newOne}/orderTerms?expand=item`
        }
        return defaultValue
    }

    data.order.notes = getHydraNotes('')
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json' },
        'url': getUrl(),
        'data': JSON.stringify(data),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })

    // let url = 'https://member-calls4.unicity.com/api/unishop/v1/sg/hydratemp/SGFreePromoOrderTerms'
    // $.post(url, JSON.stringify(data))
    //     .done(function( result ) {
    //         callback(result, true)
    //     })
    //     .fail(function(error) {
    //         callback(error, false)
    // });
}

export function enrollAddressValidation(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': apiURL[getCountryExpress()].enrollAddressValidation,
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function creatOrder(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': "https://member-th.unicity.com/cpanel/wp-admin/admin-ajax.php",
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function onSentEmail(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-th.unicity.com/th_order/emailRecice.php',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getFooter(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/ALL/ALL_EXPRESS_FooterV2.asp',
        'data': data,
        'async': false,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getShopProfile(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/shopprofile/api/shopprofile.php',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


export function subscribe(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': apiURL.emailSubscription,
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function resetPassword(callback, data) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json' },
        'url': apiURL.resetPassword,
        'data': data.strData,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function newRegister(callback, data) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json' },
        'url': apiURL.newRegistration,
        'data': data.strData,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function newRegistrationNextStep(callback, token, data) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': apiURL.createPassword,
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function autoShipList(callback, token) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': "https://hydra.unicity.net/v5a/customers/me/autoorders",
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function autoShipOrderDetail(callback, data, token) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'async': false,
        'url': data,

        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function autoShipOrderDetailJpV2(callback, data, token) {
    console.log('data', data)
    let url = {
        url: data
    }
    $.ajax({
        'type': 'GET',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'async': true,
        // 'url': data,

        // v2
        'url': 'https://member-calls2.unicity.com/proxy/autoship/detail',
        'data': url,

        'success': function (result) {
            callback(result.ushop, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function autoShipOrderDelete(callback, data, token) {
    $.ajax({
        'type': 'DELETE',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'async': false,
        'url': data,

        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}
export function validateAddressBook(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': apiURL[getCountryExpress()].validateAddressBook,
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function validateAddress(callback, data) {
    if (!isCountryJapan()) {
        data = JSON.stringify(data)
        data = { strData: data}
    }

    $.ajax({
        'type': 'POST',
        'url': apiURL[getCountryExpress()].validateAddress,
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function validateZipCodeSG(callback, zipcode) {
    $.ajax({
        'type': 'GET',
        'url': 'https://member-calls4.unicity.com/api/unishop/v1/sg/validate/zipcode/' + zipcode,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function enrollValidate(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': apiURL[getCountryExpress()].enrollValidation,
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function autoShipAddressValidate(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/JPN/JPN_Order_Validation.asp',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function createOrderServices(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': apiURL.adminAjax,
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function validateCart(callback, data, url) {
    $.post(url, JSON.stringify(data))
        .done(function (result) {
            callback(result, true)
        })
        .fail(function (error) {
            callback(error, false)
        });
}

export function validateShoppinCheckout(callback, data, url) {
    $.post(url, JSON.stringify(data))
        .done(function (result) {
            callback(result, true)
        })
        .fail(function (error) {
            callback(error, false)
        });
}

export function validateCartEnroll(callback, data, url) {
    $.post(url, JSON.stringify(data))
        .done(function (result) {
            callback(result, true)
        })
        .fail(function (error) {
            callback(error, false)
        });
}

export function convert(callback, data, url) {
    $.post(url, { data: data })
        .done(function (result) {
            callback(result, true)
        })
        .fail(function (error) {
            callback(error, false)
        });
}

export function convertUshop(callback, data, url) {
    $.post(url, JSON.stringify(data))
        .done(function (result) {
            callback(result, true)
        })
        .fail(function (error) {
            callback(error, false)
        });
}

export function calculateOrder(callback, data) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json' },
        'url': 'https://hydra.unicity.net/v5a/orderTerms?expand=item',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function createAutoShip(callback, data, token) {
    $.ajax({
        'type': 'POST',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        'url': 'https://hydra.unicity.net/v5a/customers/me/autoorders',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function uniqueEmail(callback, email) {
    $.ajax({
        'type': 'get',
        'headers': { 'Content-Type': 'application/json', 'Authorization': 'Bearer programs:9857ce776767e9287fde' },
        'url': 'https://hydra.unicity.net/v5a/customers?email=' + email,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function logPayment(callback, data, url) {
    $.post(url, JSON.stringify(data))
        .done(function (result) {
            callback(result, true)
        })
        .fail(function (error) {
            callback(error, false)
        });
}

export function createUser(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://hydra.unicity.net/v5-test/customers',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function sendEmailViaMailgun(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-th.unicity.com/email/mailgun.php',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function contactMeValidate(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/SGP/SGP_ContactMe_Validation.asp',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function () {
            callback(['There was an error with your request.'], false)
        }
    })
}


export function emailSubScribeServices(callback, data) {

    $.ajax({
        'type': 'POST',
        'url': 'https://member-th.unicity.com/th_order/emailRecice.php',
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function uploadFiletoServer(callback, data) {

    $.ajax({
        'type': 'POST',
        'url': 'https://member-sg.unicity.com/uploadNRIC_5MB.php', // point to server-side PHP script 
        'dataType': 'text',  // what to expect back from the PHP script, if anything
        'cache': false,
        'contentType': false,
        'processData': false,
        'data': data,
        'async': false,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function logOrderInsert(callback, data) {

    $.ajax({
        'type': 'POST',
        'url': 'https://member-th.unicity.com/th_order/log_orderInsert.php',
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

/**
 * Aramex Validator
 */
export function aramexValidator(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/SGP/SGP_Order_ARAMEX_ValidationV2.asp',
        'dataType': 'json',
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

/**
 * Aramex Validator
 */

//PH pickup Validator
export function phPickupValidator(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/PHL/PHL_Order_Validation.asp',
        'dataType': 'json',
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function orderSGValidator(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/SGP/SGP_Order_Validation.asp',
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function orderPHValidator(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/PHL/PHL_Order_ValidationV2.asp',
        'dataType': 'json',
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function referralAddressPHValidator(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/PHL/PHL_Referral_Enroll_ValidationV2.asp',
        'dataType': 'json',
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function referralAddressValidator(callback, data) {
    data = JSON.stringify(data)
    $.ajax({
        'type': 'POST',
        'url': apiURL[getCountryExpress()].referralEnrollValidation,
        'dataType': 'json',
        'data': { strData: data },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function checkOutPeriod(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/period.asp',
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function emailSubScribeSGServices(callback, data) {

    $.ajax({
        'type': 'POST',
        'url': 'https://member-sg.unicity.com/sg_order/emailRecice.php',
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


export function uploadUserProfilePicture(callback, token, media) {
    var data = new FormData();
    $.each(media, function (i, file) {
        data.append('media', file);
    });

    $.ajax({
        type: 'POST',
        headers: { 'Authorization': 'Bearer ' + token },
        url: 'https://hydra.unicity.net/v5a/customers/me/profilePicture',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        success: function (result) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    })
}
export function validateGovtSGPId(callback, SGPID) {
    var data = {
        "strID": SGPID,
    };
    $.ajax({
        'type': 'POST',
        'url': apiURL[getCountryExpress()].IdValidation,
        'dataType': 'json',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function validateDuplicateGovId(callback, taxID) {
    $.ajax({
        'type': 'GET',
        //'url': 'https://hydra.unicity.net/v5a/customers.js?_httpMethod=HEAD&mainAddress_country=' + 'X' + getCountryCode() + '&taxTerms_taxId=' + taxID,
        'url': 'https://hydra.unicity.net/v5a/customers.js?_httpMethod=HEAD&mainAddress_country=' + getCountryCode() + '&taxTerms_taxId=' + taxID,
        'dataType': 'json',
        'success': function (result) {
            var dataSet = JSON.stringify(result.meta, undefined, 4);
            var dataSet = dataSet.replace(/-/g, "");
            var obj = JSON.parse(dataSet);
            if (obj.XStatusCode == 200) {
                callback(result, false)
            } else if (obj.XStatusCode == 404) {
                callback(result, true)
            }
        },
        'error': function (result) {
            callback(result, false)
        }
    });
}

export function getCommissionStatement(callback, token) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': apiURL.commissionStatement,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}
export function getCommissionsForPeriod(callback, token) {
    $.ajax({
        'type': 'GET',
        'headers': { 'Authorization': 'Bearer ' + token },
        'url': apiURL.getCommissionData,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}
export function notification(callback, data) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-calls4.unicity.com/ALL/ALL_Under_Maintenance.asp',
        'data': data,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getTranslations(callback, lang) {
    $.ajax({
        'type': 'GET',
        'url': 'https://member-calls4.unicity.com/api/unishop/v1/global/translations/' + lang,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

/**
 * 
 * @param {Function} callback 
 * @param {String|Array} languagesList 
 */
export function getTranslationsList(callback, languagesList) {
    $.ajax({
        'type': 'GET',
        'async': true,
        'url': 'https://member-calls4.unicity.com/api/unishop/v1/global/translations/getmore?lang=' + languagesList,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getSiteVersion(country, target, callback){
    const data = {}
    data["source"] = `${country}`
    if (target !== null) data["dist_id"] = `${target}`

    $.ajax({
        type: 'POST',
        url: 'https://member-calls4.unicity.com/ALL/ALL_Versions.asp',
        data: { strData: JSON.stringify(data) },
        success: function (result) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    }) 
}

export function getBannerFromDatabase(callback) {

    let legitCountryCode = getCountryExpress()
    // these country use country code 3 instead of express code
    if (justOneOf(getCountryCode(), '===', CountryCode.australia, CountryCode.newzealand)) {
        legitCountryCode = getCountryCode3()
    }

    $.ajax({
        type: 'GET',
        url: `https://member-calls4.unicity.com/api/unishop/v1/global/announces/${legitCountryCode}?type=banner`,
        success: function (response) {
            callback(response, true)
        },
        error: function (response) {
            callback(response, false)
        }
    })
}

export function getBannerFromAws(callback) {

    let legitCountryCode = getCountryExpress()
    // these country use country code 3 instead of express code
    if (justOneOf(getCountryCode(), '===', CountryCode.australia, CountryCode.newzealand)) {
        legitCountryCode = getCountryCode3()
    }
    
    $.ajax({
        type: 'GET',
        url : `${memberCalls2Url}/banners/activeBanner/${legitCountryCode}`,
        success: function (response) {
            callback(response, true)
        },
        error: function (response) {
            callback(response, false)
        }
    })
}


//https://member-calls4.unicity.com
export function getTripPoint(callback, baid) {
    $.ajax({
        'type': 'POST',
        'data': { 'baids': baid },
        'url': 'https://member-calls4.unicity.com/api/unishop/v1/global/trip/point',
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


//bankwire payment
export function createOrderForPH(callback, olddata, totalPrice, totalPV, profileBAID, period, sessionToken) {
    debugger
    $.ajax({
        'type': 'POST',
        'data': {
            'action': 'create_order',
            'data': olddata,
            'amount': totalPrice,
            'pv': totalPV,
            'orderBy': profileBAID,
            'period': period,
            'session': sessionToken,
            'web': 'true'
        },
        'url': 'https://member-ph.unicity.com/cpanel/wp-admin/admin-ajax.php',
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


//order email confirmation
export function receiveOrderConfirmEmailForPH(callback, order_id, receiveEmail) {
    $.ajax({
        'type': 'POST',
        'data': {
            "action": "udReciveMail",
            "order_id": order_id,
            "emailRC": receiveEmail,
        },
        'url': 'https://memberph.unicity-easynet.com/globalpay/app.php',
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


//bankwire payment
export function getBankWirePayment(callback, order_id, baid) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-ph.unicity.com/cpanel/payment-ph/?temp_order_id=' + order_id + '&baid=' + baid,
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

//bankwire payment
export function makeBankWirePayment(callback, order_id, baid) {
    $.ajax({
        'type': 'POST',
        'url': 'https://member-ph.unicity.com/cpanel/payment-bkk-success/?NjAwMDU3MTM1&ref3=BankWire',
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getProvinceList(callback) {
    $.ajax({
        'type': 'GET',
        'dataType': 'json',
        'url': 'https://dsc-th.unicity.com/getdata.php?type=getAllProvince',
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}


export function orderValidate(callback, data) {


    $.ajax({
        'type': 'POST',
        'url': apiURL[getCountryExpress()].validateOrder,
        'data': {
            strData: JSON.stringify(data)
        },
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })

}

export function getSignature(callback, data) {
    {
        $.ajax({
            'type': 'POST',
            'url': 'http://member-calls4.unicity.com/api/unishop/v1/' + getCountryExpress() + '/payment/request/convert/ocbc',
            'data': {
                strData: btoa(JSON.stringify(data))
            },
            'success': function (result) {
                callback(result, true)
            },
            'error': function (result) {
                callback(result, false)
            }
        })
    }
}

export function getKoreaAddress(callback, state){

    let param = ''
    if(state !== null) {
        param += '&state=' + state
    }

    $.ajax({
        type: 'GET',
        url: 'https://hydra.unicity.net/v5a/addresses.json?country=KR' + param,
        success: (result) => {
            callback(result, true)
        },
        error: (result) => {
            callback(result, false)
        }
    })
}

/** Get order currency from API. 
 * @param {*} callback return result as (result, status).
 * @param {string} market market country code (e.g. SG, AU, NZ). 
 * @param {string} shipToCountry ship to country code (e.g. SG, AU, NZ). 
 * @param {string} orderId order invoice id.
 */
export function getOrderHistoryCurrency(callback, market, shipToCountry, orderId) {
    const data = {
        market: market,
        shipToCountry: shipToCountry,
        orderId: orderId
    }

    $.ajax({
        type: 'POST',
        url: 'https://tkvjrct9we.execute-api.ap-southeast-1.amazonaws.com/production',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        data: JSON.stringify(data),
        success: function (result) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    })
}

export const APITopCategories = {
    List(callback, countryCode3, warehouse) {
        get(
            `https://member-calls4.unicity.com/api/unishop/v1/global/getRandomThreeOfBestselling?country_code=${countryCode3}&warehouse=${warehouse}`,
            callback
        )
    }
}

export const APIReferralCode = {
    Get(callback, id) {
        get(
            `${API.referralCode.get}/${id}`,
            callback
        )
    },
    Validate(callback, code) {
        get(
            `${API.referralCode.validate}/${code}`,
            callback
        )
    }
}

export function getBestSellingProducts(callback) {
    $.ajax({
        type: 'GET',
        url: 'https://member-calls4.unicity.com/api/unishop/v1/global/getbestsellingproducts?country_code=' + getCountryExpress(),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })
}

export function getAutoShipMessage(callback) {

    $.ajax({
        type: 'GET',
        url: 'https://member-calls4.unicity.com/api/unishop/v1/global/miscellaneous/autoship?country=' + getCountryCode3(),
        'success': function (result) {
            callback(result, true)
        },
        'error': function (result) {
            callback(result, false)
        }
    })

}

export const autoShipLog = (callback, data) => {
    $.ajax({
        type: 'POST',
        url: 'https://member-calls2.unicity.com/unishop-fn-misc/log',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(data),
        async: true,
        success: function (result, textStatus, request) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    })
}

export const autoShipSendConfirm = (callback, data) => {
    $.ajax({
        type: 'POST',
        url: 'https://member-calls2.unicity.com/fw/auto_ship',
        data: JSON.stringify(data),
        async: true,
        success: function (result) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    })
}
export const autoShipSendDeleteConfirm = (callback, data) => {
    $.ajax({
        type: 'POST',
        url: 'https://member-calls2.unicity.com/fw/auto_ship_delete',
        data: JSON.stringify(data),
        async: true,
        success: function (result) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    })
}

export function sendRenewal(callback, language, token, baId, customerHref) {

    let today = moment();
    let expire = moment(today).add(1, 'days').toISOString();

    const data = {
        payload: {
            token: token,
            baId: baId,
            customerHref: customerHref,
            language: language
        },
        expire: expire
    }

    $.ajax({
        type: 'POST',
        url: 'https://member-calls2.unicity.com/remoteStorage/broker',
        headers: { 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InVzaG9wLXdlYnNpdGUiLCJpYXQiOjE2MDA3NjU2NTF9.OX0nVLGPex8HdkVDOn4ikwdSHNf_OIO9I0az7JSPa2o', 'Content-Type': 'application/json', 'Accept': 'application/json' },
        data: JSON.stringify(data),
        success: function (result) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    })
}
export const getJPYCurrentRate = (callback, data) => {
    $.ajax({
        type: 'GET',
        url: 'https://member-calls2.unicity.com/remoteStorage/data/jpx_exchange_rate',
        data: JSON.stringify(data),
        async: true,
        success: function (result) {
            callback(result, true)
        },
        error: function (result) {
            callback(result, false)
        }
    })
}