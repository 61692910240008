import React from 'react';
import ReactDOM from 'react-dom';
import { store, shopStore, enrollStore } from './stores/MainStore';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { getCountryCode } from './components/GlobalHelpers';
import { detectGeolocation } from './components/Geolocation';
import { testArray, isNothing } from './components/utils/Utils';
import { CountryAvailable } from './components/configs/Country';
import md5 from 'crypto-js/md5';

if (/japan/.test(window.location.pathname)) {
  window.location.replace('https://ushop.unicity.com/express/japan');
} else if (/philippines/.test(window.location.pathname)) {
  window.location.replace('https://ushop.unicity.com/express/philippines');
} else if (/hongkong/.test(window.location.pathname)) {
  window.location.replace('https://ushop.unicity.com/express/hongkong');
} else if (/australia/.test(window.location.pathname)) {
  window.location.replace('https://ushop.unicity.com/australia');
} else if (/newzealand/.test(window.location.pathname)) {
    window.location.replace("https://ushop.unicity.com/newzealand");
}


// Set Country into Local Storage
let uriPath = window.location.pathname.split('/')
let location = localStorage.getItem('country')
if (location === null) {
    location = uriPath[1]
    localStorage.setItem('country', location)
} else {
    if (location !== uriPath[1]) {
        location = uriPath[1]
        localStorage.setItem('country', location)
    }
}

// Remove Items in Cart for Non-Login User
if (window.performance) {
    if (performance.navigation.type === 1) {
        if (localStorage.getItem('customerData') === null) {
            localStorage.removeItem('storageCart')
        }
    }
}

localStorage.removeItem('top10products')
localStorage.removeItem('hydraEnrollProductSource')
localStorage.removeItem('hydraEnrollCart')
localStorage.removeItem('storageEnrollCart')
// ----------------------------------
const existsCountry = testArray(location, CountryAvailable) ? location : null
document.title += existsCountry ? ` (${existsCountry === 'mena' ? 'MENA' : getCountryCode()})` : ''

detectGeolocation(existsCountry)

try {
    // -- Versioning for Developers --
    const VERSION_DEV = md5('1.1.0').toString()
    console.log(`%cVersion: ${VERSION_DEV}`, 'font-weight: bold; color: blue;')
    const clearStorageThenSetVersion = () => {
        console.log('%cForced clean storage.', 'font-weight: bold; color: red;')
        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem('version-dev', VERSION_DEV)
        window.location.reload()
        throw 'reload'
    }
    const preVersionDev = localStorage.getItem('version-dev')
    if (isNothing(preVersionDev) === false) {
        if (preVersionDev !== VERSION_DEV) {
            clearStorageThenSetVersion()
        }
    } else {
        clearStorageThenSetVersion()
    }
    const versionStr = ''.concat('-----------------------------------------------')
    console.log('%c' + versionStr, 'font-weight: bold; color: blue;')
    // ----------------------------------
} catch (error) {
    if (error === 'string') {
        throw '- Found new development version, reload is required.'
    }
}

ReactDOM.render(<App store={store} shopStore={shopStore} enrollStore={enrollStore}/>, document.getElementById('root'))
// registerServiceWorker()