import { shopStore } from '../../stores/MainStore'
import { updateCartInfo } from '../ShoppingHelper'
import { appConfig } from '../config';

export function AddValueItem(configs) {

    let productName
    let onProduct = JSON.parse(JSON.stringify(configs['dataSource']))
    // Storage Cart
    let fireCart = {}
    let storageCart = []
    let type = configs['type'] !== undefined ? configs['type'] : 'add'
    let value = configs['qty'] !== undefined ? parseInt(configs['qty']) : undefined
    let isPowerCart = configs['cart'] === undefined ? false : true
    let multiple = configs['multiple'] === undefined ? false : true
    let id = configs['productId']
    let hasItem = false
    let btnDisabled = null
    
    onProduct.map((b, i) => {
        if (b.product_id === id) {
            productName = b.post_title
            b.qty = parseInt(b.qty)

            let ableQty = parseInt(b.stock)
            if (b.buffer_qty) {
                ableQty = parseInt(b.stock) - parseInt(b.buffer_qty) 
            }
            
            if (type === 'add' && b.qty >= ableQty) {
                if (b.allow_backorder !== '1') {
                    b.qty = ableQty
                    btnDisabled = 'disabled'
                } else {
                    b.qty = ++b.qty
                }
            } else {
                if (type === 'removeAll') {
                    b.qty = 0
                } else if (type === 'remove') {
                    if (b.qty > 0) {
                        b.qty = --b.qty
                    }  
                    if(b.qty <= 0) {
                        b.qty = 0
                    }
                } else {
                    if (type === 'single') {
                        if (value !== undefined) {
                            b.qty += value
                        } else {
                            b.qty = ++b.qty
                        }
                    } else {
                        if (b.qty >= parseInt(b.stock)) {
                            b.qty = b.stock
                        } else {
                            if (value !== undefined) {
                                b.qty = value
                            } else {
                                b.qty = ++b.qty
                            }
                        }
                    }
                }
            }

            b.btnDisabled = btnDisabled
        }

        if (type === 'clearCart') {
            b.qty = 0
            hasItem = false
        }

        if (b.qty > 0) {
            fireCart[b.product_id] = {}
            fireCart[b.product_id].id = b.product_id
            fireCart[b.product_id].qty = b.qty
            storageCart.push({
                id: b.product_id,
                qty: b.qty
            });
            hasItem = true

        }
        return b
    })

    if (configs['storage']) {
        // firebase.database()
        //     .ref('carts_web/' + configs['userId'])
        //     .set(fireCart);

        updateCartInfo(configs['userId'], fireCart);
    }
    localStorage.setItem('storageCart', JSON.stringify(storageCart))

    return {
        dataSource: onProduct,
        productName: productName
    }
}

export function CalculateCart(products) {
    let { total, totalPv, passCheckOut } = shopStore

    // console.log("CalculateCart1", passCheckOut, total, totalPv)

    if (passCheckOut === false && products != null) {
        total = 0
        totalPv = 0
        products.map((v, k) => {
            if (v.qty > 0) {
                // console.log(v.item_code, v.qty, v.member_price, v.pv)
                total += (parseFloat(v.member_price) * v.qty)
                totalPv += (parseInt(v.pv) * v.qty)
            }
        })

        // console.log("CalculateCart2", passCheckOut, total, totalPv)
    }

    return {
        total: total,
        totalPv: totalPv
    }
}

export function CalculateCartWithPriceLevel(products, priceLevel) {
    let { total, totalPv, passCheckOut } = shopStore

    // console.log("CalculateCart1", products, priceLevel)

    if (passCheckOut === false && products != null) {
        total = 0
        totalPv = 0
        products.map((v, k) => {
            if (v.qty > 0) {
                // console.log(v.item_code, v.qty, v.member_price, v.pv)
                total += ((v[priceLevel] === '' ? 0: parseFloat(v[priceLevel])) * v.qty)
                totalPv += (parseInt(v.pv) * v.qty)
            }
        })
    }

    return {
        total: total,
        totalPv: totalPv
    }
}


export const showOnDev = () => { 
    return (window.location.hostname === appConfig.hostname.localhost || window.location.hostname === 'ushop-dev.unicity.com')
}

export const showOnlyTestAccount = (user) => {
    return (user !== null &&  user.id !== undefined && user.id.unicity === '108357166') ? true : false
}