import Alert from 'react-s-alert'
import { shopStore, store, checkOutStore, defaultCheckOutStore, defaultShopStore, staticFooter } from '../stores/MainStore';
import { storeEasyShop } from '../stores/StoreEasyShop';
import { shippingAddress, appConfig, currencyBehindPrice } from './config'
import { isNullOrUndefined, isNothing, ifNothingThenEmpty, trim } from './utils/Utils';
import { numeralFormat, isMobile, getDevice, dictionary, isLanguageEnglish, getFooterLanguageControl, integerFormat, replaceHashTagsWith, isCountryKorea, isCountryHongkong } from './GlobalHelpers'
import { AddValueItem } from './shopping/ShoppingGlobalFunctions';
import { logi, logn, loge } from './utils/PikaLog'
import { CountryConfigs, AA, StoreConfigs } from './configs/Configs';
import { ShippingAddress } from './configs/Address';
import { CountryCode, CountryFullDisplay } from './configs/Country';
import { ShippingMethods } from './configs/ShippingMethods';
import { _ } from 'core-js';
import { getCustomerStatus, isCustomerLogin } from './utils/Customer';

import * as SafeHelpers from './configs/ConfigsHeader'
import * as K from './configs/Keywords'
import { toJS } from 'mobx';

// Avoid GlobalHelpers methods to prevent undefined errors.
const getCountryCode = () => CountryConfigs.CountryCode()
const getCountryCode3 = () => CountryConfigs.CountryCode3()
const getCountryExpress = () => CountryConfigs.CountryCodeExpress()
const getProductCountryCode3 = () => StoreConfigs.Products()

export function getCurrentDomain() {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

export const getAPIDomain = () => {
    const localAPI = 'http://localhost:8888/api/unishop'
    const liveAPI = 'https://member-calls4.unicity.com/api/unishop'
    const devAPI = 'https://member-calls4.unicity.com/api/unishop_dev'

    switch (SafeHelpers.checkHost()) {
        case K.Localhost: return devAPI
        case K.Development: return liveAPI
        case K.Production: return liveAPI
        default: 
            if (getCurrentDomain().indexOf('ngrok') !== 1) {
                return devAPI
            } else {
                return liveAPI
            }
    }
}

export const getOCBCUrl = () => {
    const staging = 'https://testepayment.ocbc.com/BPG/admin/payment/PaymentInterfaceSimulator.jsp'
    const production = 'https://epayment.ocbc.com/BPG/admin/payment/PaymentInterface.jsp'

    switch (SafeHelpers.checkHost()) {
        case K.Localhost: return staging
        case K.Development: return production
        case K.Production: return production
        default: return staging
    }
}

export var apiURL = {
    shopping: {
        validate_cart_api: getAPIDomain() + '/v1/' + getCountryExpress() + '/validate_cart/shopping/payment',
        success_url: getCurrentDomain() + '/' + appConfig.country + '/#/checkout/payment-response/success',
        error_url: getCurrentDomain() + '/' + appConfig.country + '/#/checkout/payment-response/failed',
        RETURN_URL: getAPIDomain() + '/v1/' + getCountryExpress() + '/payment/response/shopping',
        convert_api: getAPIDomain() + '/v1/' + getCountryExpress() + '/payment/request/convert/ocbc',
        log_payment_api: getAPIDomain() + '/v1/' + getCountryExpress() + '/payment/request/log',
        getautologin: getAPIDomain() + '/v1/' + getCountryExpress() + '/common/getautologin',
        checkstock_api: getAPIDomain() + '/v1/' + getCountryExpress() + '/validate_cart/shopping/checkstock',
        checkOrderId: getAPIDomain() + '/v1/' + getCountryExpress() + '/payment/checkOrderId'
    },
    enroll: {
        validate_cart_api: getAPIDomain() + '/v1/' + getCountryExpress() + '/validate_cart/enroll/payment',
        validate_free_api: getAPIDomain() + '/v1/' + getCountryExpress() + '/validate_cart/enroll/free',
        success_url: getCurrentDomain() + '/' + appConfig.country + '/enroll/payment-response/success',
        error_url: getCurrentDomain() + '/' + appConfig.country + '/enroll/payment-response/failed',
        RETURN_URL: getAPIDomain() + '/v1/' + getCountryExpress() + '/payment/response/enroll',
        RESPONSE_TYPE: "HTTPS"
    },
    cart: {
        add: getAPIDomain() + '/v1/' + getCountryExpress() + '/cart_info/add/',
        get: getAPIDomain() + '/v1/' + getCountryExpress() + '/cart_info/get',
        limit: getAPIDomain() + '/v1/common/global/CartLimit'
    },
    referralCode: {
        get: `${getAPIDomain()}/v1/global/referralcode/code`,
        validate: `${getAPIDomain()}/v1/global/referralcode/ba`,
    },
    product: {
        getProducts: "https://member-calls4.unicity.com/api/unishop/v1/global/getproducts?country_code=" + getProductCountryCode3() + "&secret=yes",
        newGetProducts: "https://member-calls4.unicity.com/api/unishop/v1/global/getproducts?country_code=" + getProductCountryCode3(),
        //getProducts: getAPIDomain() + '/v1/global/getproducts?country_code=' + getCountryExpress() + '&secret=yes',
        //newGetProducts: getAPIDomain() + '/v1/global/getproducts?country_code=' + getCountryExpress(),
    },
    order: {
        secureinfo: getAPIDomain() + '/v1/common/global/SecureOrderDetail'
    },
    ocbc: {
        url: getOCBCUrl()
    },
    directPayment: {
        url: getAPIDomain() + '/v1/' + getCountryExpress() + '/payment/request/direct',
        freeenroll: getAPIDomain() + '/v1/' + getCountryExpress() + '/payment/freeenroll',
    },
}

export function phoneCheckVerified(phone, country, ba_country, identity, callback) {
    let ba_id = getLoginUserId()
    if (identity) ba_id = identity

    if (ba_id) {
        let data = {
            ba_id : ba_id,
            phone : phone,
            country: country,
            ba_country: ba_country
        }
        window.$.post(apiURL.PhoneVerify.CheckVerified, JSON.stringify(data))
            .done(function (result) {
                callback('success', result)
            })
            .fail(function (error) {
                callback('error', error)
            });
    } else {
        callback('error', 'ba_id not found.')
    }
}


export function clearData() {
    checkOutStore.shoppingNotLogin = false
    checkOutStore.shipping = '2'
    checkOutStore.baID = ''
    checkOutStore.referralValue = ''
    checkOutStore.referralBy = ''
    checkOutStore.referralText = ''
    checkOutStore.referenceDetail = ''
    checkOutStore.referralAddress.delivery = {}
    checkOutStore.referralAddress.pickUp = {}
    if(!localStorage.customerData){
        shopStore.productDataSource.map((v, k) => {
            v.member_price = v.retail_price
            return v
        })
    }
}

export function clearEasyShop() {
    checkOutStore.shipping = ShippingMethods.ID.delivery
    storeEasyShop.isEnabled = false
    storeEasyShop.baID = ''
    storeEasyShop.referralValue = ''
    storeEasyShop.referralBy = ''
    storeEasyShop.referralText = ''
    storeEasyShop.referenceDetail = ''
    storeEasyShop.referralAddress.delivery = {}
    storeEasyShop.referralAddress.pickUp = {}
    storeEasyShop.referralAddress.aramex = {}
}

export function phoneRequestOTP(phone, source, country, identity, callback) {
    let ba_id = getLoginUserId()
    if (identity) ba_id = identity

    if (ba_id) {
        let data = {
            ba_id : ba_id,
            phone : phone,
            source: source,
            country: country
        }
        window.$.post(apiURL.PhoneVerify.RequestOTP, JSON.stringify(data))
            .done(function (result) {
                callback('success', result)
            })
            .fail(function (error) {
                callback('error', error)
            });
    } else {
        callback('error', 'ba_id not found.')
    }
}

export function phoneConfirmOTP(phone, country, identity, confirm_otp, callback) {
    let ba_id = getLoginUserId()
    if (identity) ba_id = identity

    if (ba_id) {
        let data = {
            ba_id : ba_id,
            phone : phone,
            confirm_otp: confirm_otp,
            country: country
        }
        window.$.post(apiURL.PhoneVerify.ConfirmOTP, JSON.stringify(data))
            .done(function (result) {
                callback('success', result)
            })
            .fail(function (error) {
                callback('error', error)
            });
    } else {
        callback('error', 'ba_id not found.')
    }
}

export function getPickupMessage (reference_id, callback) {
    let data = {
        reference_id: reference_id
    }
    window.$.post(apiURL.order.secureinfo, JSON.stringify(data))
        .done(function (result) {
            callback('success', result)
        })
        .fail(function (error) {
            callback('error', error)
        });
}

export function validateCartLimit(countryExpress, orderTermsJson, callback) {
    let data = {
        countryCode3: countryExpress,
        lang: store.languageCurrent,
        orderTermsJson: orderTermsJson
    }

    window.$.post(apiURL.cart.limit, JSON.stringify(data))
        .done(function (result) {
            if (callback) {
                callback('success', result)
            }
        })
        .fail(function (error) {
            callback('error', error)
        });
}

export function getLoginUserId() {
    let user = getUser()
    let userId = ''
    try {
        userId = user.id.unicity
    } catch (e) {
        console.log(e)
    }
    return userId
}

export function clearCartInfo(user_id) {
    if (user_id) {
        let fireCart = {};
        fireCart[0] = {};
        fireCart[0].qty = 0;
        updateCartInfo(user_id, fireCart);
    }
    localStorage.removeItem('storageCart');
    localStorage.removeItem('storageEnrollCart');
}

export function updateCartInfo(user_id, cart, callback) {
    let data = {
        ba_id: user_id,
        cart: cart
    }

    window.$.post(apiURL.cart.add, JSON.stringify(data))
        .done(function (result) {
            if (callback) {
                callback(result)
            }
        })
        .fail(function (error) {
            console.log('updateCartInfo fail', error)
        });
}

export function getCartQuantity() {
    let qty = 0
    let localCart = localStorage.getItem('storageCart')
    if (localCart !== null) {
        localCart = JSON.parse(localCart)
        localCart.map((b, i) => {
            qty += parseInt(b.qty)
        })
    }
    return qty
}

export function getCartQuantityPerItem(item_code) {
    let qty = 0
    let localCart = localStorage.getItem('storageCart')
    if (localCart !== null) {
        localCart = JSON.parse(localCart)
        localCart.map((b, i) => {
            if (b.id === item_code) {
                qty = parseInt(b.qty)
            }
        })
    }
    return qty
}

export function getCartTotal(item_code) {
    let total = 0
    let localCart = localStorage.getItem('storageCart')
    let onProduct = JSON.parse(JSON.stringify(shopStore.productDataSource))

    onProduct.map((b2, i2) => {
        if (b2.product_id === item_code) {
            total += parseInt(b2.member_price)
        }
    })

    if (localCart !== null) {
        localCart = JSON.parse(localCart)
        localCart.map((b, i) => {
            onProduct.map((b2, i2) => {
                if (b2.product_id === b.id) {
                    total += parseInt(b2.member_price) * b.qty
                }
            })
        })
    }
    return total
}

export function getCartInfo(user_id, callback) {
    // Storage Cart
    let fireCart = {}
    let localCart = localStorage.getItem('storageCart')
    if (localCart !== null) {
        localCart = JSON.parse(localCart)
        localCart.map((b, i) => {
            fireCart[b.id] = {}
            fireCart[b.id].id = b.id
            fireCart[b.id].qty = b.qty
        })
        updateCartInfo(user_id, fireCart, function (result) {
            callback('success', result)
        });
    } else {
        window.$.ajax({
            'type': 'GET',
            'url': `${apiURL.cart.get}/${user_id}`,
            'success': function (result) {
                callback('success', result)
            },
            'error': function (err) {
                console.log(err)
                callback('error', err)
            }
        });
    }
}

export function getUser() {
    let user = localStorage.getItem('customerData')
    user = JSON.parse(user)
    return user
}

export function getShowPV() {
    let user = getUser()
    if (user !== null && user.acl.showPv === true) {
        if (getCustomerStatus() === 'M') return false
        else return true
    } else {
        return false
    }
}

export function getUserCountryCode() {
    let user = getUser()
    if (user !== null) {
        return user.mainAddress.country
    } else {
        return ''
    }
}

export function getHydraNotes(referralValue) {
    // Express PH|mobileW|iphone|referral_108357166|UNISHOP-WEB-SG_322”
    let project = 'Express-' + getCountryCode()
    // let project = 'ushop'
    let site = isMobile() ? 'mobileW' : 'web'
    let device = getDevice()
    let referral = referralValue ? 'referral_' + referralValue : ''
    let notes = `${project}|${site}|${device}|${referral}|`
    return notes
}

export function getShoppingCartItemValue(item_code, name) {
    let result = 0
    if (name === 'item_total_pv') {
        shopStore.productDataSource.map((v, k) => {
            if (v.product_id === item_code) {
                result = parseInt(v.pv) * parseInt(v.qty)
            }
        })
    }
    if (name === 'price_per_unit') {
        shopStore.productDataSource.map((v, k) => {
            if (v.product_id === item_code) {
                result = numeralFormat(v.member_price)
            }
        })
    }
    if (name === 'item_subtotal') {
        shopStore.productDataSource.map((v, k) => {
            if (v.product_id === item_code) {
                result = numeralFormat(v.member_price * v.qty)
            }
        })
    }
    return result
}

export function isShoppingLogin() {
    let checkOutStoreLocal = localStorage.getItem('checkOutStore')
    let user = localStorage.getItem('customerData')
    if (user !== null) {
        return true
    } else {
        return false
    }
}

export function getShippingKey() {
    let shipping = ''
    if (checkOutStore.shipping === '2') {
        shipping = 'delivery'
    } else if (checkOutStore.shipping === '3') {
        shipping = 'pickUp'
    } else if (checkOutStore.shipping === '4') {
        shipping = 'aramex'
    }
    return shipping
}

export function getShoppingDeliveryAddress(needNative, nativeFieldKey) {

    try {
        if (isNullOrUndefined(needNative) === true) {
            // no need native
            return {
                fullName: checkOutStore.billingAddress.delivery.firstNameEn + ' ' + checkOutStore.billingAddress.delivery.lastNameEn,
                firstNameEn: checkOutStore.billingAddress.delivery.firstNameEn,
                lastNameEn: checkOutStore.billingAddress.delivery.lastNameEn,
                address1: checkOutStore.billingAddress.delivery.address1,
                address2: checkOutStore.billingAddress.delivery.address2,
                zip: isCountryHongkong() ? '' : trim(checkOutStore.billingAddress.delivery.zip),
                email: checkOutStore.billingAddress.delivery.email,
                mobilePhone: checkOutStore.billingAddress.delivery.mobilePhone,
                city: checkOutStore.billingAddress.delivery.city,
                state: checkOutStore.billingAddress.delivery.state,
                comment: checkOutStore.billingAddress.delivery.comment,
                country: getCountryCode(),
                // address1Native: checkOutStore.billingAddress.delivery.address1Native || checkOutStore.billingAddress.delivery.address1,
                // address2Native: checkOutStore.billingAddress.delivery.address2Native || checkOutStore.billingAddress.delivery.address2,
            }
        } else {
            // need native
            return {
                fullName: `${checkOutStore.billingAddress.delivery.firstNameEn}${checkOutStore.billingAddress.delivery.lastNameEn ? ` ${checkOutStore.billingAddress.delivery.lastNameEn}` : ''}` || '',
                fullNameNative: checkOutStore.billingAddress.delivery.fullNameNative || '',
                firstNameEn: checkOutStore.billingAddress.delivery.firstNameEn || '',
                lastNameEn: checkOutStore.billingAddress.delivery.lastNameEn || '',
                address1: checkOutStore.billingAddress.delivery.address1 || '',
                address2: checkOutStore.billingAddress.delivery.address2 || '',
                address1Native: checkOutStore.billingAddress.delivery.address1Native || checkOutStore.billingAddress.delivery.address1,
                address2Native: checkOutStore.billingAddress.delivery.address2Native || checkOutStore.billingAddress.delivery.address2+checkOutStore.billingAddress.delivery.city+checkOutStore.billingAddress.delivery.state,
                zip: isCountryHongkong() ? '' : trim(checkOutStore.billingAddress.delivery.zip),
                email: checkOutStore.billingAddress.delivery.email || '',
                mobilePhone: checkOutStore.billingAddress.delivery.mobilePhone || '',
                city: checkOutStore.billingAddress.delivery.city || '',
                cityNative: checkOutStore.billingAddress.delivery.cityNative || '',
                state: checkOutStore.billingAddress.delivery.state || '',
                stateNative: checkOutStore.billingAddress.delivery.stateNative || '',
                comment: checkOutStore.billingAddress.delivery.comment || '',
                commentNative: checkOutStore.billingAddress.delivery.commentNative || '',
                country: getCountryCode()
            }
        }
    } catch (e) {
        return {
            fullName: '',
            firstNameEn: '',
            lastNameEn: '',
            address1: '',
            address2: '',
            zip: '',
            email: '',
            mobilePhone: '',
            city: '',
            state: '',
            country: getCountryCode(),
            comment: ''
        }
    }
}

export function getPickUpAddress() {
    return shippingAddress.pickUpAddress
}

export function getShoppingBillingAddress() {
    switch (checkOutStore.shipping) {
        case ShippingMethods.ID.delivery: 
            return getShoppingDeliveryAddress() 
        case ShippingMethods.ID.pickUp:
            console.log('Need work with PickUp')
            break 
    }
}

export function getShoppingAramexFormData() {
    const fname = (checkOutStore.billingAddress.aramex.firstNameEn || '').trim()
    const lname = ''//(checkOutStore.billingAddress.aramex.lastNameEn || '').trim()
    return {
        language: 'English',
        firstName: fname,
        lastName: lname,
        address1: ShippingAddress.aramexAddress.address1,
        address2: ShippingAddress.aramexAddress.address2,
        zip: ShippingAddress.aramexAddress.zip,
        email: (checkOutStore.billingAddress.aramex.email || '').trim(),
        mobilePhone: (ShippingAddress.aramexAddress.mobilePhone || '').trim(),
        city: CountryFullDisplay.singapore,
        state: (checkOutStore.billingAddress.aramex.state || '').trim(),
        comment: (checkOutStore.billingAddress.aramex.comment || '').trim(),
        country: CountryCode.singapore,
        sns: (`${checkOutStore.sns1}${checkOutStore.sns2}`).trim(),
        period: checkOutStore.commissionMonth
    }
}

export function getShoppingDeliverFormData() {
    let data = {
        language: 'English',
        fullName: getShoppingDeliveryAddress().fullName || '',
        firstName: getShoppingDeliveryAddress().firstNameEn || '',
        lastName: getShoppingDeliveryAddress().lastNameEn || '',
        address1: getShoppingDeliveryAddress().address1 || '',
        address2: getShoppingDeliveryAddress().address2 || '',
        city: getShoppingDeliveryAddress().city,
        state: getShoppingDeliveryAddress().state,
        zip: getShoppingDeliveryAddress().zip || '',
        country: getShoppingDeliveryAddress().country,
        email: getShoppingDeliveryAddress().email || '',
        mobilePhone: getShoppingDeliveryAddress().mobilePhone || '',
        comment: getShoppingDeliveryAddress().comment || '',
    }

    if (getCountryExpress() === 'XJP') {
        data.language = appConfig.languageName[store.language.language][0]
        data.fullNameNative = getShoppingDeliveryAddress(true).fullNameNative
        data.address1Native = getShoppingDeliveryAddress(true).address1Native
        data.address2Native = getShoppingDeliveryAddress(true).address2Native
        data.country = 'SG'
    }
    if (isCountryKorea()) {
        data.language = appConfig.languageName[store.language.language][0]
        data.fullNameNative = getShoppingDeliveryAddress(true).fullNameNative
        data.address1Native = getShoppingDeliveryAddress(true).address1Native
        data.address2Native = getShoppingDeliveryAddress(true).address2Native
        data.ship_native_city = getShoppingDeliveryAddress(true).cityNative
        data.ship_native_state = getShoppingDeliveryAddress(true).stateNative
        data.ship_native_comment = getShoppingDeliveryAddress(true).commentNative
    }

    return data
}

export function getShoppingFormData() {
    switch (checkOutStore.shipping) {
        case ShippingMethods.ID.delivery: return getShoppingDeliverFormData()
        case ShippingMethods.ID.aramex: return getShoppingAramexFormData()
    }
}

export function renderProvinceList() {
    let topArr = [{ value: "none", name: "-- " + store.language.choose_province + " --" }]
    let list = []
    let provinces = JSON.parse(localStorage.getItem('provinces'))
    let topList = ['Bangkok']
    let lang = (store.language.language === 'TH') ? 'PROVINCE_NAME' : 'PROVINCE_NAME_ENG'

    provinces.map((v, k) => {
        if (topList.indexOf(v['PROVINCE_NAME_ENG'].trim()) < 0) {
            list.push({ value: v[lang].trim(), name: v[lang].trim() })
        } else {
            topArr.unshift({ value: v[lang].trim(), name: v[lang].trim() })
        }
    })
    list.sort(SortByName)

    topArr.map((v) => {
        list.unshift(v)
    })

    return list
}
export function getSelectedProvince(currProvince) {
    // let currProvince = province;
    let province = JSON.parse(localStorage.getItem('provinces'))
    let lang = (store.language.language === 'TH') ? 'PROVINCE_NAME' : 'PROVINCE_NAME_ENG'
    let selectedProvince = ''

    province.map((v, k) => {
        if (v.PROVINCE_NAME.trim() === currProvince) {
            selectedProvince = v[lang].trim()

        } else if (v.PROVINCE_NAME_ENG === currProvince) {
            selectedProvince = v[lang].trim()
        }
    })
    return selectedProvince
}

export function SortByName(a, b) {
    var aValue = a.value.toLowerCase();
    var bValue = b.value.toLowerCase();
    return aValue.localeCompare(bValue);
}

export function clearLocalShoppingCart() {
    // shopStore.productDataSource.map((v, k) => {
    //     v.qty = 0
    // }) 
    // localStorage.removeItem('storageCart')

    let onProduct = JSON.parse(JSON.stringify(shopStore.productDataSource))
    let storageCart = [];
    onProduct.map((b, i) => {
        b.qty = 0
        return b
    })

    localStorage.setItem('storageCart', JSON.stringify(storageCart))
    shopStore.productDataSource = onProduct
}

export function getDeliveryFee(orderTermsItems) {
    let deliveryFee = ''
    if (orderTermsItems) {
        orderTermsItems.map((v, k) => {
            deliveryFee = (appConfig.tax[appConfig.country])
                ? (v.terms.freight.amount * appConfig.tax[appConfig.country])
                : v.terms.freight.amount
        })
    }

    deliveryFee = numeralFormat(deliveryFee)

    let regex = /[.00]/
    if (regex.test(deliveryFee)) {
        return parseInt(deliveryFee)
    } else {
        return deliveryFee
    }
}

export function getOrderTermsByKey(orderTerms, key) {

    let result = ''

    try {
        orderTerms.items.map((v, k) => {
            if (key === 'total') {
                result = v.terms.total
            }
            if (key === 'pv') {
                result = v.terms.pv
            }
            if (key === 'weight') {
                result = v.terms.weight
            }
            if (key === 'currency') {
                result = v.currency
            }
            if (key === 'freight_amount') {
                result = v.terms.freight.amount
            }
        })
    } catch (e) {

    }

    return result
}

export function saveCheckOutToLocal() {
    let ShoppingStorage = {
        checkOutStore: checkOutStore,
        shopStore: shopStore
    }
    localStorage.setItem('ShoppingStorage', JSON.stringify(ShoppingStorage));
}
export function resetCheckOutStore() {
    Object.keys(checkOutStore).map((a) => {
        checkOutStore[a] = defaultCheckOutStore[a]
    })
}

export function resetShopStore() {
    Object.keys(shopStore).map((a) => {
        shopStore[a] = defaultShopStore[a]
    })
}

export function checkCartLimitAlert(id, page, object={}) {
    function pushCartAlert(type, html) {
        
        if (object.noAlert === true) return

        const newOffset = (isNothing(store.marginTopMain) ? 0 : store.marginTopMain) === 0 ? 120 : 80
        if (type === 'error') {
            Alert.error(html, {
                position: 'top-right',
                effect: 'slide',
                offset: newOffset
            })
        } else {
            Alert.success(html, {
                position: 'top-right',
                effect: 'slide',
                offset: newOffset
            })
        }
    }

    let isCheck = false, isPushAdd = false
    const className = page.toLowerCase()
    const footerLanguage = getFooterLanguageControl()

    let ltotal = parseInt(staticFooter.footerOrder.cart_limit_price[footerLanguage])
    let lqty = parseInt(staticFooter.footerOrder.cart_limit_qty[footerLanguage])
    let ltotalQty = parseInt(staticFooter.footerOrder.cart_limit_qty_total[footerLanguage])

    if (isNothing(lqty)) lqty = 0
    if (isNothing(ltotal)) ltotal = 0
    if (isNothing(ltotalQty)) ltotalQty = 0

    let limitConfig = {
        qty: lqty,
        qtyTotal: ltotalQty,
        total: ltotal
    }

    let alertHtmlAdd = '<table style="margin: 0px 5px 0px 5px"><tr>'
    + '<td valign="center" style="margin: 0px 0px 0px 0px;"><i class="icon-circle-check"></i></td>'
    + '<td valign="center"> <span style="font-weight:900">' 
    + '#PRODUCT</span><br>' + dictionary('successfully_added_to_cart') + '</td></tr></table>'
    
    let alertHtml = '<table style="margin: 0px 5px 0px 5px"><tr>'
    + '<td valign="center" style="margin: 0px 0px 0px 0px;"><i class="icon-circle-cross"></i></td>'
    + '<td valign="center"> <span style="font-weight:900">' 
    + '#TEXT</span></td></tr></table>'

    /* logi('---[This logs are only display on dev mode]---')
    logi('Footer/currency =', store.currency)
    logi('Footer/cart_limit_price =', limitConfig.total, limitConfig.total === 0 ? '(Unlimited)' : '')
    logi('Footer/cart_limit_qty =', limitConfig.qty, limitConfig.qty === 0 ? '(Unlimited)' : '')
    logi('----------------------------------------------') */

    let productName = ''
    let productPrice = NaN
    
    const onProduct = JSON.parse(JSON.stringify(shopStore.productDataSource))
    onProduct.map((b) => {
        if (b.product_id === id) {
            productName = (isLanguageEnglish() === false) ? (b.post_title_2 !== '') ? b.post_title_2 : b.post_title : b.post_title
            productPrice = b.price
        }
    })

    if (isNothing(limitConfig) === false) {
        const currencyPosition = currencyBehindPrice(getCountryCode())
        const currencyLeft = currencyPosition === 'left' ? store.currency + ' ' : ''
        const currencyRight = currencyPosition === 'right' ? ' ' + store.currency : ''
        const totalAfterAdd = getCartTotal(id)
        const qtyBeforeAdd = getCartQuantityPerItem(id)
        let msgExceedQty = '', msgExceedQtyTotal = '', msgExceedTotal = ''

        function rewriteMsgExceedQty() {
            msgExceedQty = replaceHashTagsWith(dictionary('error_cart_limit_qty'), 'LIMQTY', integerFormat(limitConfig.qty))
        }
        function rewriteMsgExceedQtyTotal() {
            msgExceedQtyTotal = replaceHashTagsWith(dictionary('error_cart_limit_qty_total'), 'LIMQTY', integerFormat(limitConfig.qtyTotal))
        }
        function rewriteMsgExceedTotal(total) {
            msgExceedTotal = dictionary('error_cart_limit_price')
            msgExceedTotal = replaceHashTagsWith(msgExceedTotal, 'LCUR', currencyLeft)
            msgExceedTotal = replaceHashTagsWith(msgExceedTotal, 'RCUR', currencyRight)
            msgExceedTotal = replaceHashTagsWith(msgExceedTotal, 'TOTAL', numeralFormat(total))
            msgExceedTotal = replaceHashTagsWith(msgExceedTotal, 'LIMTOTAL', numeralFormat(limitConfig.total))
        }

        rewriteMsgExceedQty()
        rewriteMsgExceedQtyTotal()
        rewriteMsgExceedTotal(totalAfterAdd)
        
        if (className === 'productcart') {
            isCheck = object.type === 'add' || object.type === 'focusout'
        } else {
            isCheck = true
        }

        if (isCheck === true) {
            // qty per item limit
            if (limitConfig.qty > 0) {
                if (isNothing(object) === false) {
                    if (isNothing(object.value) === false) {
                        if (object.value > limitConfig.qty) {
                            alertHtml = replaceHashTagsWith(alertHtml, 'TEXT', msgExceedQty)
                            pushCartAlert('error', alertHtml)
                            return false
                        }
                    }
                }
                if (qtyBeforeAdd >= limitConfig.qty) {
                    alertHtml = replaceHashTagsWith(alertHtml, 'TEXT', msgExceedQty)
                    pushCartAlert('error', alertHtml)
                    return false
                }
            }

            // qty cart limit
            if (limitConfig.qtyTotal > 0) {
                const totalQty = getCartQuantity()
                let typeOfChangeQty = ''
                if (isNothing(object) === false) {
                    if (isNothing(object.value) === false) {
                        let newTotalQty = 0
                        if (object.prevValue <= object.value) {
                            // plus
                            typeOfChangeQty = 'plus'
                            newTotalQty = (totalQty - object.prevValue) + object.value
                        } else {
                            // minus
                            typeOfChangeQty = 'minus'
                            newTotalQty = object.value
                        }

                        if (newTotalQty > limitConfig.qtyTotal) {
                            alertHtml = replaceHashTagsWith(alertHtml, 'TEXT', msgExceedQtyTotal)
                            pushCartAlert('error', alertHtml)
                            return false
                        }
                    }
                } 
                if (totalQty >= limitConfig.qtyTotal && typeOfChangeQty === '') {
                    alertHtml = replaceHashTagsWith(alertHtml, 'TEXT', msgExceedQtyTotal)
                    pushCartAlert('error', alertHtml)
                    return false
                }
            }
    
            // total limit
            if (limitConfig.total > 0) {
                if (isNothing(object) === false) {
                    if (isNothing(object.value) === false) {
                        if (productPrice * object.value > limitConfig.total) {
                            rewriteMsgExceedTotal(productPrice * object.value)
                            alertHtml = replaceHashTagsWith(alertHtml, 'TEXT', msgExceedTotal)
                            pushCartAlert('error', alertHtml)
                            return false
                        }
                    }
                }

                if (totalAfterAdd > limitConfig.total) {
                    alertHtml = replaceHashTagsWith(alertHtml, 'TEXT', msgExceedTotal)
                    pushCartAlert('error', alertHtml)
                    return false
                }
            }
        }
    }

    if (object.instantReturn === true) return true

    let storageCart = []
    let data = {}
    let fireCart = {}
    let valueAdded = null
    
    switch (className) {
        case 'quickshop':
            onProduct.map((b) => {
                if (b.product_id === id) {
                    b.qty = ++b.qty
                }
                if (b.qty > 0) {
                    fireCart[b.product_id] = {}
                    fireCart[b.product_id].id = b.product_id
                    fireCart[b.product_id].qty = b.qty
                    storageCart.push({
                        id: b.product_id,
                        qty: b.qty
                    })
                }
                return false
            })
        
            if (store.customerData.id) {
                updateCartInfo(store.customerData.id.unicity, fireCart);
            }
        
            localStorage.setItem('storageCart', JSON.stringify(storageCart))
            shopStore.productDataSource = onProduct;
            isPushAdd = true
            break
        case 'catproduct':
            data = {
                productId: id,
                dataSource: shopStore.productDataSource
            }
            if (store.customerData.id) {
                data.userId = store.customerData.id.unicity
                data.storage = 'firebase'
            }
            valueAdded = AddValueItem(data)
            shopStore.productDataSource = valueAdded.dataSource
            isPushAdd = true
            break
        case 'productcart':
            data = {
                productId: id,
                dataSource: shopStore.productDataSource.slice(),
                type: object.type,
                qty: object.value
            }
            if (store.customerData.id) {
                data.userId = store.customerData.id.unicity
                data.storage = 'firebase'
            }
            valueAdded = AddValueItem(data)
            shopStore.passCheckOut = false
            shopStore.productDataSource = valueAdded.dataSource
            isPushAdd = false
            break
        case 'allproduct':
            onProduct.map((b) => {
                if (b.product_id === id) {
                    b.qty = ++b.qty
                }
                if (b.qty > 0) {
                    fireCart[b.product_id] = {}
                    fireCart[b.product_id].id = b.product_id
                    fireCart[b.product_id].qty = b.qty
                    storageCart.push({
                        id: b.product_id,
                        qty: b.qty
                    })
                }
                return false
            })
            if (store.customerData.id) {
                updateCartInfo(store.customerData.id.unicity, fireCart)
            }
            localStorage.setItem('storageCart', JSON.stringify(storageCart))
            shopStore.productDataSource = onProduct
            isPushAdd = true
            break
        case 'singleproduct':
            data = {
                productId: id,
                dataSource: shopStore.productDataSource.slice(),
                qty: object.value,
                type: object.type
            }
            if (store.customerData.id) {
                data.userId = store.customerData.id.unicity
                data.storage = 'firebase'
            }
            valueAdded = AddValueItem(data)
            shopStore.productDataSource = valueAdded.dataSource
            document.getElementById('qty-input').value = 1
            isPushAdd = true
            break
        default:
            loge('checkCartLimitAlert() class name not found (' + className + ')')
            break
    }

    if (isPushAdd === true) {
        alertHtmlAdd = replaceHashTagsWith(alertHtmlAdd, 'PRODUCT', productName)
        pushCartAlert('success', alertHtmlAdd)
    }
}
export function getShippingCode(infoShipment) {
    let shipping = ''
    if (infoShipment === 'delivery') {
        shipping = '2'
    } else if (infoShipment === 'pickUp') {
        shipping = '3'
    } else if (infoShipment === 'aramex') {
        shipping = '4'
    } 

    return shipping
}
export function isDuplicatedError(errors) {
    for (var key in errors) {
        if (errors[key].indexOf('Duplicated') !== -1) return true
    }
    return false
}

export function createDuplicatedError(responseText) {

    let msg = dictionary('error_duplicated_order')
    if (responseText.type === 'enroll' || responseText.type === 'shopping retail' || responseText.type === 'shopping profile') {
        msg = dictionary('error_duplicated_order_without_order')
    }
    let regex = /(\d{2})-(\w+)-(\d{4}) (.*)/  //08-May-2019 5:49PM
    let matches = responseText.stamp_created.match(regex);
    let day = matches[1]
    let month = matches[2]
    let monthstr = dictionary('month_'+month.toLowerCase())
    let year = matches[3]
    let time = matches[4]
    let datestr = day+'-'+monthstr+'-'+year

    // msg = msg.replace('{{items}}', items)
    msg = msg.replace('{{order_id}}', responseText.order_id)
    msg = msg.replace('{{pv}}', responseText.pv)
    msg = msg.replace('{{total}}', numeralFormat(responseText.total)+' '+ staticFooter.footerGeneral.currency_code[getFooterLanguageControl()])
    msg = msg.replace('{{date}}', datestr)
    msg = msg.replace('{{time}}', time)

    return msg;
}