import React from "react";
import validator from "validator";
import moment from "moment";
import { appConfig, supportedMarkets, inArray, momentLocale } from "./config";
import numeral from "numeral";
import {
  store,
  staticFooter,
  devTools,
  storeManager,
  language,
} from "../stores/MainStore";
import { apiURL as apiURLShop, getCurrentDomain } from "./ShoppingHelper";
import {
  isNothing,
  isNullOrUndefined,
  isString,
  justOneOf,
  defaults,
} from "./utils/Utils";
import {
  CountryCode,
  CountryFullDisplay,
  CountryCodeToFull,
} from "../components/configs/Country";
import { PaymentConfigs, CountryConfigs } from "./configs/Configs";
import { isTablet as _isTablet, isMobile as _isMobile } from "./utils/Bowser";
import { loge, logn } from "./utils/PikaLog";
import { isCustomerLogin } from "./utils/Customer";
import { AddressFormCheckout, AddressFormProfile } from "./configs/Address";
import ReactMarkdown from "react-markdown/with-html";

import * as SafeHelpers from "./configs/ConfigsHeader";
import * as K from "./configs/Keywords";
import get from "lodash/get";

// Stage check functions
export const isLocalhost = () => {
  return (
    isProduction() === false && checkHost() === appConfig.hostname.localhost
  );
};
export const isDevelopment = () => {
  return (
    isProduction() === false && checkHost() === appConfig.hostname.development
  );
};
export const isProduction = () => {
  return (
    checkHost() === appConfig.hostname.production ||
    devTools.isSimulateLive === true
  );
};
export const isProductionTruth = () => {
  return checkHost() === appConfig.hostname.production;
};
export const isDevelopmentOrLocalhost = () => {
  return isProduction() === false && (isLocalhost() || isDevelopment());
};
// Language check functions
export const isLanguageEnglish = () => {
  return getStoreLanguage() === appConfig.languageCode.english;
};
export const isLanguageNative = () => {
  return isLanguageEnglish() === false;
};
export const isLanguageJapanese = () => {
  return getStoreLanguage() === appConfig.languageCode.japanese;
};
export const isLanguageBahasa = () => {
  return getStoreLanguage() === appConfig.languageCode.bahasa;
};
export const isLanguageKorean = () => {
  return getStoreLanguage() === appConfig.languageCode.korean;
};
// Country check functions
export const isCountryJapan = () => {
  return getCountryCode() === CountryCode.japan;
};
export const isCountryIndonesia = () => {
  return getCountryCode() === CountryCode.indonesia;
};
export const isCountryPhilippines = () => {
  return getCountryCode() === CountryCode.philippines;
};
export function isCountryAustralia() {
  return getCountryCode() === appConfig.countryCode.australia;
}
export const isCountryNewzealand = () => {
  return getCountryCode() === CountryCode.newzealand;
};
export const isCountryMalaysia = () => {
  return getCountryCode() === CountryCode.malaysia;
};
export const isCountryMENA = () => {
  return getCountryCode() === CountryCode.mena;
};
export const isCountryHongkong = () => {
  return getCountryCode() === CountryCode.hongkong;
};
export const isCountryKorea = () => {
  return getCountryCode() === CountryCode.korea;
};

export const getEnvironment = () => {
  switch (checkHost()) {
    case K.Localhost:
      return "Localhost";
    case K.Development:
      return "Development";
    case K.Production:
      return "Production";
    default:
      return "Unknown";
  }
};

export const getDevelopmentID = () => {
  if (isDevelopment()) {
    try {
      let d = getCurrentDomain();
      d = d.split("-dev")[1];
      d = d.split(".")[0];
      return d;
    } catch (e) {
      console.error("getDevelopmentID()", e);
      return "";
    }
  } else {
    return "";
  }
};

/** Covert string value to markdown component.
 * @param {string} value any string value with markdown syntax.
 * @return {React.ReactNode} return component
 * @example
 * withMarkdown('Hello **Bold** Text')
 * withMarkdown('<strong>Bold</strong><br><font color="red">Red</font>')
 */
export const withMarkdown = (value) => {
  if (isString(value) === false) return <span></span>;
  const result = value.replace(/\n/g, "<br/>");
  return <ReactMarkdown source={result} escapeHtml={false} />;
};

export const listErrorDictionaryWords = [];
/** Get word from dictionary. Return devlog error when a word does not existed. */
export const dictionary = (label) => {
  const word = get(store, `language.${label}`);
  if (isNothing(word)) {
    if (inArray(label, listErrorDictionaryWords))
      return isProduction() ? "" : label;
    loge(
      'Label "' +
        label +
        '" was not exists in dictionary (' +
        getStoreLanguageName() +
        ")."
    );
    listErrorDictionaryWords.push(label);
    return isProduction() ? "" : `[${label}]`;
  } else {
    return word;
  }
};

export const updateLanguageSetting = (languageCode) => {
  try {
    logn(
      "Update > language:",
      languageCode,
      "| footer:",
      storeManager.isFooterStaticDone
    );
    // update flag, dictionary, currency
    store.flagCurrent = appConfig.flags[languageCode];
    store.language = store.dictionaryAll[languageCode];
    language.footer = justOneOf(languageCode, "===", "EN", "PH")
      ? K.English
      : K.Native;
    store.currency = getFooterCurrency();
    // update moment
    const momentLocaleCode = momentLocale(getCountry(), languageCode);
    if (inArray(momentLocaleCode, moment.locales()) === false) {
      if (momentLocaleCode !== "en") {
        moment.locale(
          momentLocaleCode,
          require(`moment/locale/${momentLocaleCode}`)
        );
      }
    } else {
      moment.locale(momentLocaleCode);
    }
    //logi('Moment Update (', momentLocaleCode, ') >>', moment("2019-06-10").format('MMM YYYY'), moment.locales())
    // update local storage
    localStorage.setItem("language", languageCode);
  } catch (e) {
    // it's error because static footer not ready, let's wait and try again
    loge(e);
  }
};

/** Print text on localhost stage. <span class='text-muted'>TEXT</span> */
export const printl = (text) => {
  return isLocalhost() === true ? (
    <span
      className={"mx-2 px-2 badge badge-pill badge-warning"}
      dangerouslySetInnerHTML={{ __html: text }}
    ></span>
  ) : (
    ""
  );
};

/** Print text on localhost and development stage. <span class='text-muted'>TEXT</span> */
export const printd = (text) => {
  return isDevelopment() === true ? (
    <span
      className={"mx-2 px-2 badge badge-pill badge-primary text-small"}
      dangerouslySetInnerHTML={{ __html: text }}
    ></span>
  ) : (
    ""
  );
};

export const showOnDev = () => {
  return (
    window.location.hostname === appConfig.hostname.localhost ||
    window.location.hostname === "express-dev.unicity.com"
  );
};

export const checkHost = () => {
  return SafeHelpers.checkHost();
};

let member_call_url = "https://member-calls4.unicity.com/";
export var apiURL = {
  loginAddProfile:
    member_call_url + "api/unishop_dev/v1/common/global/addProfile",
  hydraStandard: "https://hydra.unicity.net/v5",
  loginTokens: "https://hydra.unicity.net/v5a/loginTokens",
  // getNews: "https://member-" + getCountryCode(true) + ".unicity.com/cpanel/wp-admin/admin-ajax.php?action=unimobiapp_get_news&args[posts_per_page]=-1",
  // getMedia: "https://member-" + getCountryCode(true) + ".unicity.com/cpanel/wp-admin/admin-ajax.php?action=unimobiapp_get_prospect",
  // getAllProduct: "https://member-" + getCountryCode(true) + ".unicity.com/cpanel/wp-admin/admin-ajax.php?action=unimobiapp_get_products&args[order]=asc&args[posts_per_page]=100&args[paged]=",
  getProducts: apiURLShop.product.getProducts,
  newGetProducts: apiURLShop.product.newGetProducts,
  // getProductsHot: "https://member-" + getCountryCode(true) + ".unicity.com/cpanel/wp-admin/admin-ajax.php?action=unimobiapp_get_products&args[meta_key]=product_hot&args[meta_value]=yes&args[order]=asc",
  sendFeedback:
    "https://member-calls4.unicity.com/ALL/ALL_Send_Mail_Feedback_Express.php",
  seminarData:
    "https://member-th.unicity.com/cpanel/wp-admin/admin-ajax.php?action=unimobiapp_get_seminar&b=",
  orderHistory:
    "https://member-" +
    getCountryCode(true) +
    ".unicity.com/mobile/queryorder.php?type=1&token=",
  queryOrder:
    "https://member-" +
    getCountryCode(true) +
    ".unicity.com/mobile/queryorder.php?type=2&token=",
  whoAmI: "https://hydra.unicity.net/v5a/whoami",
  //getPeriod: "https://thdl1.unicity-easynet.com/commission_html/getdate.php",
  getPeriod: "https://member-calls4.unicity.com/period.asp",
  getCommissionData:
    "https://hydra.unicity.net/v5a/customers/me/commissionstatements",
  expandCustomer: "https://member-calls2.unicity.com/h/info/",
  uplineSponsoringCustomer: "/uplineSponsoringCustomers.js?unicity=",
  getVip: "https://member-calls4.unicity.com/v5/customers/me/fsb?id=",
  getInventory:
    "https://member-" +
    getCountryCode(true) +
    ".unicity.com/easyship/getInventory.php",
  linkPayMent:
    "https://member-" +
    getCountryCode(true) +
    ".unicity.com/cpanel/payment-bkk-2/?temp_order_id=",
  adminAjax:
    "https://member-" +
    getCountryCode(true) +
    ".unicity.com/cpanel/wp-admin/admin-ajax.php",
  commissionStatement:
    "https://hydra.unicity.net/v5a/customers/me/commissionstatements",
  resetPassword: "https://hydra.unicity.net/v5a/passwordresettokens",
  newRegistration: "https://hydra.unicity.net/v5a/passwordcreatetokens",
  emailSubscription:
    "https://member-calls4.unicity.com/ALL/ALL_Email_Subscription.asp",
  hydraCustomer: "https://hydra.unicity.net/v5a/customers?id.unicity=",
  createPassword: "https://hydra.unicity.net/v5a/customers/me/password",
  hydraItems: "https://hydra.unicity.net/v5a/items?id.unicity=",
  hydraShippingMethods: "https://hydra.unicity.net/v5a/shippingmethods?type=",
  SG: {
    validateAddress: member_call_url + "SGP/SGP_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "SGP/SGP_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "SGP/SGP_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "SGP/SGP_Referral_Enroll_ValidationV2.asp",
    IdValidation: member_call_url + "SGP/SGP_ID_Validation.asp",
  },
  JP: {
    validateAddress: member_call_url + "JPN/JPN_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "JPN/JPN_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "JPN/JPN_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "JPN/JPN_Referral_Enroll_ValidationV2.asp",
    IdValidation: member_call_url + "SGP/SGP_ID_Validation.asp",
  },
  TH: {
    validateAddress: member_call_url + "THA/THA_Order_ValidationV21.asp",
    validateAddressBook: member_call_url + "THA/THA_AddrBook_ValidationV21.asp",
    enrollValidation: member_call_url + "THA/THA_Enroll_ValidationV21.asp",
    referralEnrollValidation:
      member_call_url + "THA/THA_Enroll_ValidationV21.asp",
    IdValidation: member_call_url + "api/unishop/v1/TH/validate_address/idcard",
  },
  PH: {
    validateAddress: member_call_url + "PHL/PHL_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "PHL/PHL_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "PHL/PHL_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "PHL/PHL_Referral_Enroll_ValidationV2.asp",
    IdValidation:
      member_call_url + "api/unishop_dev/v1/ph/validate_address/idcard",
  },
  XPH: {
    validateAddress: member_call_url + "XPH/XPH_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "XPH/XPH_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "XPH/XPH_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "XPH/XPH_Referral_Enroll_ValidationV2.asp",
    IdValidation:
      member_call_url + "api/unishop_dev/v1/xph/validate_address/idcard",
  },
  XMY: {
    validateAddress: member_call_url + "XMY/XMY_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "XMY/XMY_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "XMY/XMY_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "XMY/XMY_Referral_Enroll_ValidationV2.asp",
    IdValidation:
      member_call_url + "api/unishop_dev/v1/xmy/validate_address/idcard",
  },
  XID: {
    validateAddress: member_call_url + "XID/XID_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "XID/XID_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "XID/XID_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "XID/XID_Referral_Enroll_ValidationV2.asp",
    IdValidation:
      member_call_url + "api/unishop_dev/v1/xid/validate_address/idcard",
  },
  XAU: {
    validateAddress: member_call_url + "XAU/XAU_Order_ValidationV21.asp",
    validateAddressBook: member_call_url + "XAU/XAU_AddrBook_ValidationV21.asp",
    enrollValidation: member_call_url + "XAU/AUS_Enroll_ValidationV21.asp",
    referralEnrollValidation:
      member_call_url + "XAU/AUS_Enroll_ValidationV2.asp",
    IdValidation:
      member_call_url + "api/unishop/v1/XAU/validate_address/idcard",
  },
  XNZ: {
    validateAddress: member_call_url + "XNZ/XNZ_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "XNZ/XNZ_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "XNZ/NZL_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "XNZ/NZL_Enroll_ValidationV2.asp",
    IdValidation:
      member_call_url + "api/unishop/v1/XNZ/validate_address/idcard",
  },
  XJP: {
    validateAddress: member_call_url + "JPN/JPN_Order_Validation_NFR_V2.asp",
    validateAddressBook: member_call_url + "JPN/JPN_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "JPN/JPN_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "JPN/JPN_Referral_Enroll_ValidationV2.asp",
    IdValidation: member_call_url + "SGP/SGP_ID_Validation.asp",
  },
  XME: {
    validateAddress: member_call_url + "SGP/SGP_Order_ARAMEX_ValidationV2.asp",
    validateAddressBook: member_call_url + "SGP/SGP_AddrBook_ValidationV2.asp",
    enrollValidation: member_call_url + "JPN/JPN_Enroll_ValidationV2.asp",
    referralEnrollValidation:
      member_call_url + "JPN/JPN_Referral_Enroll_ValidationV2.asp",
    IdValidation: member_call_url + "SGP/SGP_ID_Validation.asp",
  },
  XHK: {
    validateAddress: member_call_url + "XHK/XHK_Order_ValidationV2.asp",
    validateAddressBook: member_call_url + "XHK/XHK_AddrBook_ValidationV2.asp",
  },
  XKR: {
    validateAddress: member_call_url + "XKR/XKR_Order_ValidationV2.asp",
  },
};

export var links = {
  seminarQualifications:
    "https://member-calls4.unicity.com/ALL/TRIP/" +
    getCountryCode() +
    "/Trip-Unicity-Leadership Seminar-Proposal-jun-sep-2018.pdf",
  // seminarQualifications: "https://member-" + getCountryCode(true) + ".unicity.com/London%20Trip%20-%20Unicity%20Leadership%20Seminar%20Proposal%202017%20(Eng).pdf",
  //seminarQualifications: "https://member-" + getCountryCode(true) + ".unicity.com/download/Tokyo%20Trip%20-%20Unicity%20Leadership%20Seminar%20Proposal%202018_MSB_final.pdf",
  seminarBrochure:
    "https://member-" +
    getCountryCode(true) +
    ".unicity.com/London%20Trip%2020x24_2.jpg",
  seminarImage:
    "https://member-" +
    getCountryCode(true) +
    ".unicity.com/cpanel/wp-content/uploads/2017/02/london2017.jpg",
  signUp:
    "https://www.unicity-easynet.com/tools/?action=register&url=member" +
    getCountryCode(true) +
    ".unicity.com&lang=" +
    getCountryCode(true),
  forgotPass:
    "https://www.unicity-easynet.com/tools/?action=forgot&url=member" +
    getCountryCode(true) +
    ".unicity.com&lang=" +
    getCountryCode(true),
  facebook: "https://www.facebook.com/UnicityInternational",
  line: "https://line.me/R/ti/p/%40unicity",
  youtube: "https://www.youtube.com/user/MKTunicity",
  instagram: "https://www.instagram.com/unicity_thailand/",
};

export const firebaseConfig = {
  apiKey: "AIzaSyBvbm-snXcio9RiO5txWdzUXXe-UUEskpY",
  authDomain: "unishop-8e7a7.firebaseapp.com",
  databaseURL: "https://unishop-8e7a7.firebaseio.com",
  projectId: "unishop-8e7a7",
  storageBucket: "unishop-8e7a7.appspot.com",
  messagingSenderId: "395680533208",
};

export function objectToArray(object, callback) {
  let tempArray = [];
  Object.keys(object).map((key) => {
    tempArray.push(Object.assign(object[key], { key }));
    return false;
  });
  callback(tempArray);
}

export const reflect = (promise) => {
  return promise.then(
    (type) => {
      return { type: type, status: true };
    },
    (type) => {
      return { type: type, status: false };
    }
  );
};

export const validateAlphaEnglish = (str) => {
  var pattern = /^[a-zA-Z]+$/;
  return pattern.test(str);
};

export const validateAlphaThai = (str) => {
  var pattern = /^[ก-๙]+$/;
  return pattern.test(str);
};

export const validateEmail = (str) => {
  return validator.isEmail(str);
};

export const isNilOrEmpty = (value) => {
  return validator.isEmpty(value);
};

export const isMobile = () => {
  return _isMobile();
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

export const isTablet = () => {
  return _isTablet();
  if (/Tablet|iPad/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

export const getDevice = () => {
  var module = {
    options: [],
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera,
    ],
    dataos: [
      { name: "Windows Phone", value: "Windows Phone", version: "OS" },
      { name: "Windows", value: "Win", version: "NT" },
      { name: "iPhone", value: "iPhone", version: "OS" },
      { name: "iPad", value: "iPad", version: "OS" },
      { name: "Kindle", value: "Silk", version: "Silk" },
      { name: "Android", value: "Android", version: "Android" },
      { name: "PlayBook", value: "PlayBook", version: "OS" },
      { name: "BlackBerry", value: "BlackBerry", version: "/" },
      { name: "Macintosh", value: "Mac", version: "OS X" },
      { name: "Linux", value: "Linux", version: "rv" },
      { name: "Palm", value: "Palm", version: "PalmOS" },
    ],
    databrowser: [
      { name: "Chrome", value: "Chrome", version: "Chrome" },
      { name: "Firefox", value: "Firefox", version: "Firefox" },
      { name: "Safari", value: "Safari", version: "Version" },
      { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
      { name: "Opera", value: "Opera", version: "Opera" },
      { name: "BlackBerry", value: "CLDC", version: "CLDC" },
      { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
    ],
    init: function () {
      var agent = this.header.join(" "),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        html = "",
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, "i");
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
          matches = string.match(regexv);
          version = "";
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + ".";
              } else {
                version += matches[j];
              }
            }
          } else {
            version = "0";
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: "unknown", version: 0 };
    },
  };

  let e = module.init();
  let osName = e.os.name + "|" + e.browser.name + " " + e.browser.version;
  return osName;
};

export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validateGovId = (str) => {
  if (str.length !== 9) return false;

  str = str.toUpperCase();

  var i,
    icArray = [];
  for (i = 0; i < 9; i++) {
    icArray[i] = str.charAt(i);
  }

  icArray[1] = parseInt(icArray[1], 10) * 2;
  icArray[2] = parseInt(icArray[2], 10) * 7;
  icArray[3] = parseInt(icArray[3], 10) * 6;
  icArray[4] = parseInt(icArray[4], 10) * 5;
  icArray[5] = parseInt(icArray[5], 10) * 4;
  icArray[6] = parseInt(icArray[6], 10) * 3;
  icArray[7] = parseInt(icArray[7], 10) * 2;

  var weight = 0;
  for (i = 1; i < 8; i++) {
    weight += icArray[i];
  }

  var offset = icArray[0] === "T" || icArray[0] === "G" ? 4 : 0;
  var temp = (offset + weight) % 11;

  var st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];
  var fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];

  var theAlpha;
  if (icArray[0] === "S" || icArray[0] === "T") {
    theAlpha = st[temp];
  } else if (icArray[0] === "F" || icArray[0] === "G") {
    theAlpha = fg[temp];
  }

  return icArray[8] === theAlpha;
};

export const validateDate = (min, date) => {
  return moment().diff(date, "years") >= min ? true : false;
};

export var apiHEADER = {
  preset1: { "Content-Type": "application/json" },
};

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export var rankListOrdered = {
  Dst: 0,
  Ph1: 1,
  Mgr: 2,
  SrM: 3,
  ExM: 4,
  Dir: 5,
  SrD: 6,
  ExD: 7,
  PrD: 8,
  PrS: 9,
  PrR: 10,
  DIA: 11,
  CCM: 12,
  AFP: 13,
};

export var objProductValidate = {
  order: {
    customer: {
      href: "https://hydra.unicity.net/v5a/customers?type=Customer",
    },
    shipToAddress: {
      country: "",
    },
  },
  vary: {
    lines: {
      items: [
        {
          item: {
            href: "https://hydra.unicity.net/v5a/items?id.unicity=",
          },
          quantity: "?",
        },
      ],
    },
  },
};

export var deliveryAddress = {
  language: "",
  fullName: "",
  address1: "",
  address2: "",
  city: "",
  comment: "",
  country: "",
  zip: "",
  email: "",
  mobilePhone: "",
};

export var pickUpAddress = {
  language: "Thai",
  fullName: "Main Office",
  address1: "44/1 Rungrojthanakul Building 1st Floor",
  address2: "and 15th Floor,Ratchadapisek Road",
  comment: "",
  country: "TH",
  city: "Huaykwang",
  zip: "10310",
  email: "cs_thailand@unicity.com",
  mobilePhone: "",
};

export var orderItem = {
  order: {
    customer: {
      href: "",
    },
    lines: {
      items: null,
    },
    shipToName: {
      firstName: "",
      lastName: "",
    },
    shippingMethod: {
      href: "",
    },
    notes: "MWeb",
    shipToPhone: "",
    shipToEmail: "",
    shipToAddress: {},
  },
};
export var create_order = {
  action: "create_order",
  data: null,
  amount: null,
  pv: null,
  orderBy: null,
  period: null,
  session: null,
  web: "true",
};

export var order_data = {
  requestType: "createOrder",
  requestVersion: "1",
  transactionId: "2",
  appDeviceOSVersion: "1.0",
  appDeviceOSName: "Web",
  requestObj: {
    applicationVersion: "1.0",
    applicationId: "UNISHOP",
    deviceId: "1000000",
    distMarket: "TH",
    order: {
      productItems: [],
      shippingMethod: null,
      shipTo: [],
      billTo: [],
      orderTotal: null,
    },
    distributorType: "A",
    distributorId: null,
    market: "TH",
  },
  sessionToken: "",
};

export var productItems = {
  productItemId: null,
  quantity: null,
  autoShip: false,
};

export const isEnrollPath = () => {
  const path = window.location.hash.split("/");
  return path[1] === "enroll";
};

export const isCheckoutPath = () => {
  const path = window.location.hash.split("/");
  return path[1] === "checkout";
};

export const isCartPath = () => {
  let path = window.location.hash.split("/");
  return path[1] === "cart";
};

export const getNativeName = (obj, nameType) => {
  var fullName = "";
  var nativeName = "";
  Object.keys(obj).forEach(function (key) {
    var value = obj[key];
    if (key === "fullName") fullName = value;
    if (fullName !== "") nativeName = value;
  });

  if (nameType === "fullName") {
    return fullName;
  } else if (nameType === "nativeName") {
    return nativeName;
  } else {
    if (nativeName === "") return fullName;
    else return nativeName;
  }
};

export const hasExtension = (inputID, exts) => {
  var fileName = document.getElementById(inputID).value;
  return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
    fileName
  );
};

export function formToJSON(elements) {
  let inputs = {};
  for (var i = 0, element; (element = elements[i++]); ) {
    inputs[element.name] = element.value;
  }
  return inputs;
}

//-------------------------------------------------//
//***************[ Country Methods ]***************//
//-------------------------------------------------//
/** Get country from url by uriPath (express.unicity.com/**country**).
 *
 * e.g. **thailand**, **indonesia**, **newzealand**
 * @return {String} country from uriPath.
 */
export function getCountry() {
  return appConfig.country;
}

/** Get country output by country code, this could be a native version.
 *
 * e.g.  **New Zealand**, **日本**, **ประเทศไทย**
 * @param {String} countryCode code as ISO 3166-1 alpha-2.
 * @return {String} country output.
 */
export function getDefaultCountry(countryCode) {
  switch (countryCode) {
    case CountryCode.japan:
      if (isLanguageJapanese()) {
        return dictionary("japan");
      }
      break;
    case CountryCode.korea:
      if (isLanguageKorean()) {
        return dictionary("korea");
      }
      break;
  }
  return CountryFullDisplay[CountryCodeToFull[countryCode]];
}

/** Get a lowercase with non-space output of country.
 *
 * e.g. **thailand**, **indonesia**, **newzealand**
 * @return {String} a lowercase with no-space output of country.
 */
export function getCountryFull() {
  return appConfig.countryFull[appConfig.country];
}

/** Get a beautiful output of country. Can be translated into native version.
 *
 * e.g. **ประเทศไทย**, **Indonesia**, **New Zealand**
 * @param {Boolean} translation translate this country if native version is existed.
 * @return a beautiful output of country.
 */
export function getCountryFullDisplay(translation = false) {
  let output = "";
  if (translation === true) {
    output = CountryConfigs.CountryFullDisplay();
  } else {
    output = getDefaultCountry(getCountryCode());
  }
  return output;
}

/** Get country code as ISO 3166-1 alpha-2 code.
 *
 * e.g. **TH**, **AU**, **NZ**
 * @param {Boolean} lowercase transform output into lowercase.
 * @return {String} code as ISO 3166-1 alpha-2.
 */
export function getCountryCode(lowercase = false) {
  const c = CountryConfigs.CountryCode();
  return lowercase === true ? c.toLowerCase() : c;
}

/** Get country code as ISO 3166-1 alpha-3 code.
 *
 * e.g. **JPN**, **AUS**, **NZL**
 * @param {Boolean} lowercase transform output into lowercase.
 * @return {String} code as ISO 3166-1 alpha-3.
 */
export function getCountryCode3(lowercase = false) {
  const c = CountryConfigs.CountryCode3();
  return lowercase === true ? c.toLowerCase() : c;
}

/** Get express country code as ISO 3166-1 alpha-2 by added **X** at the beginning of it.
 *
 * e.g. **XJP**, **XAU**, **XNZ**
 * @param {Boolean} lowercase transform output into lowercase.
 * @return {String} code as ISO 3166-1 alpha-2 by added **X** at the beginning of it.
 */
export function getCountryExpress(lowercase = false) {
  const c = CountryConfigs.CountryCodeExpress();
  return lowercase === true ? c.toLowerCase() : c;
}

export function getCountryByCondition() {
  return PaymentConfigs.GatewaysHydra()
    ? getCountryCode3()
    : getCountryExpress();
}

////////////////////////////////////////////////////////

export function getLanguage() {
  return appConfig.language[appConfig.country][0];
}

/** Covert any language code into language name in English. (e.g. TH = Thai, ID = Bahasa) */
export function getLanguageName(languageCode) {
  let language = {
    TH: "Thai",
    JP: "Japanese",
    EN: "English",
    ID: "Bahasa",
    VN: "Vietnamese",
    AR: "Arabic",
  };
  return language[languageCode];
}

/** Get currently language name in English. */
export function getStoreLanguageName() {
  return getLanguageName(getStoreLanguage());
}

/** Get current store language as language code format. (e.g. TH, ID, JP) */
export function getStoreLanguage() {
  let lang = "";
  if (isNothing(store.language)) {
    loge("Store Language not found.");
  } else {
    lang = store.language.language;
  }
  return lang;
}

/** Get current store currency. (e.g. THB, SGD, NZD) */
export function getStoreCurrency() {
  if (isNothing(store.currency)) {
    loge("Store Currency could not be found.");
    return "";
  }
  return store.currency;
}

export function getAddressOrderFormConfig(form) {
  return AddressFormCheckout(form, appConfig.country);
}

export function getAddressbookFormConfig(form) {
  return AddressFormProfile(form, appConfig.country);
}
export function getPvRang() {
  return appConfig.pvRange[CountryCode[appConfig.country]];
}
export function getPriceRang() {
  return appConfig.priceRange[CountryCode[appConfig.country]];
}
export function getInputRang() {
  return appConfig.inputRange[CountryCode[appConfig.country]];
}
export function getPriceLevel() {
  var customerData = JSON.parse(localStorage.getItem("customerData"));
  if (customerData.acl.api.priceLevel !== "") {
    return customerData.acl.api.priceLevel;
  } else {
    return "retail_price";
  }
}

export function getPlanFromCountryCode(countryCode) {
  let plans = appConfig.plan;
  let c = "";

  Object.keys(plans).map((v, k) => {
    plans[v].map((vv, kk) => {
      if (countryCode === vv) {
        c = jsUcfirst(v);
      }
    });
  });

  return c;
}

export function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    console.log(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10));
    return true;
  }

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf("rv:");
    console.log(parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10));
    return true;
  }

  var edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    console.log(parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10));
    return true;
  }

  // other browser
  return false;
}

export const GetCardType = (number) => {
  // visa
  var re = new RegExp("^4");
  if (number.match(re) != null) return "V";

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return "M";

  return "";
};

export function convertObjectToJSON(object) {
  return JSON.parse(JSON.stringify(object));
}

export function booleanReader(value) {
  return typeof value !== "boolean" ? value === "true" : value;
}

export function isWordExist(text, find) {
  if (!text) return false;
  return text.search(find) !== -1;
}

export function replaceHashTagsWith(text, tag, replacePhrase) {
  const hashtag = "#" + tag;
  const regex = new RegExp(hashtag, "g");
  return isWordExist("#" + tag) ? text.replace(regex, replacePhrase) : text;
}

export const isSafari = () => {
  var isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1;
  return isSafari;
};

/**
 * Check every possibility to be null.
 * @param {*} object [any] any object.
 */
export function isNullPossibility(object) {
  return (
    object === null || object === undefined || object === "" || object === NaN
  );
}

/**
 * Check decimal format from footer.
 * @param {*} number any number that need to be format.
 * @param {boolean} isDecimal enable decimal display.
 */
export function decimalController(number, language) {
  if (isNothing(number)) return null;
  if (isNothing(language)) return null;

  const CASE_INTEGER = "integer";

  let front = null;
  let result = null;
  let resultMod = null;
  let control = staticFooter.footerGeneral;

  if (language === CASE_INTEGER) {
    // forced the function to return in integer case
    control = { decimal_controller: { english: "int", native: "int" } };
  } else {
    if (isNothing(control)) {
      // if footer not found, use a default setting instead
      console.warn(
        "Warning: decimal controller are not found, please check the footer."
      );
      control = { decimal_controller: { english: 2, native: 2 } };
    }
  }

  if (isNothing(control.decimal_controller)) return null;

  const controlEnglish =
    control.decimal_controller[appConfig.footerLanguageControl.english];
  const controlNative =
    control.decimal_controller[appConfig.footerLanguageControl.native];
  if (isNothing(controlEnglish)) return null;
  if (isNothing(controlNative)) return null;

  const limit =
    language === appConfig.footerLanguageControl.english
      ? controlEnglish
      : controlNative;
  if (isNothing(limit)) return null;

  result = number.toString();

  switch (limit.toString()) {
    case "int":
      // forced integer case
      result = numeral(result).format(appConfig.numeralFormat);
      break;
    default:
      resultMod = result.split(".")[1];
      if (isNothing(resultMod)) {
        // no decimal case
        result = numeral(result).format(appConfig.numeralFormat);
      } else {
        if (resultMod === 0) {
          // no decimal case
          result = numeral(result).format(appConfig.numeralFormat);
        } else {
          front = numeral(result.split(".")[0]).format(appConfig.numeralFormat);
          result = front.toString() + "." + resultMod.toString();
          // decimal case
          const points = resultMod.toString();
          let newLength;
          const marginLength = points.length - limit;
          if (marginLength === 0) {
            newLength = points.length;
          } else if (marginLength > 0) {
            newLength = marginLength;
          } else if (marginLength < 0) {
            newLength = Math.abs(marginLength);
          }
          //console.log("LIM:", limit, "| M:",marginLength, "| N:",newLength, "| R:",result.length - newLength)
          if (marginLength <= 0) {
            newLength = result.length;
          } else {
            newLength = result.length - newLength;
          }
          result = result.substring(0, newLength);

          // find zero in decimal
          resultMod = result.split(".")[1];
          //console.log("Mod:", resultMod)
          if (isNothing(resultMod) === false) {
            let pattern = ".";
            for (let s = 0; s < resultMod.length; s++) {
              pattern += "0";
            }
            result = result.replace(pattern, "");
            //console.log("Removed Zero>", result, "|pat:", pattern)
          } else {
            result = result.replace(".", "");
          }
          //console.log("Decimal Limited ===", limit)
        }
      }
      break;
  }
  return result;
}

/**
 * Convert any value to numeral format or decimal format.
 *  @param {*} value any number that should be convert.
 */
export function numeralFormat(value) {
  const language =
    getStoreLanguage() === appConfig.languageCode.english
      ? appConfig.footerLanguageControl.english
      : appConfig.footerLanguageControl.native;
  return decimalController(value, language);
}

/**
 * Convert any value to integer format.
 * @param {*} value any number that should be integer.
 */
export function integerFormat(value) {
  return decimalController(value, "integer");
}

export function getFooterLanguageControl() {
  const fallback = isProduction() ? "english" : "";
  return defaults(language.footer, fallback);
}

export function getFooterCurrency() {
  return isNullOrUndefined(staticFooter.footerGeneral)
    ? ""
    : staticFooter.footerGeneral.currency_code[getFooterLanguageControl()];
}

/**
 * Replaced special string to html format.
 * @[br] - new line @[color=#000000] - color text @[li] - list @[b] - bold text@[i] - italic text@[u] - underline text
 * @param {string} text any text that need to be format.
 */
export function textFormat(text) {
  if (text == undefined) return "";
  let newStyle = {};
  let color = "";
  // find new line
  let regex = /\[br\]/g;
  let result = text.replace(regex, "<br/>");
  // find color
  let regexColor = /\[color=#/;
  const colorPos = result.search(regexColor);
  // find list
  let regexList = /\[li\]/;
  const listPos = result.search(regexList);
  // find bold
  let regexBold = /\[b\]/;
  const boldPos = result.search(regexBold);
  // find italic
  let regexItalic = /\[i\]/;
  const italicPos = result.search(regexItalic);
  // find underline
  let regexUnderline = /\[u\]/;
  const underlinePos = result.search(regexUnderline);

  // replace color
  if (colorPos !== -1) {
    color = result.substr(colorPos + String("[color=#").length, 6);
    regexColor = /\[color=#......\]/;
    result = result.replace(regexColor, "");
    newStyle["color"] = "#" + color;
  }
  // replace list
  if (listPos !== -1) {
    result = result.replace(regexList, "<li>");
    result += "</li>";
  }
  // replace bold
  if (boldPos !== -1) {
    result = result.replace(regexBold, "");
    newStyle["fontWeight"] = "bold";
  }
  // replace italic
  if (italicPos !== -1) {
    result = result.replace(regexItalic, "");
    newStyle["fontStyle"] = "italic";
  }
  // replace underline
  if (underlinePos !== -1) {
    result = result.replace(regexUnderline, "");
    newStyle["textDecoration"] = "underline";
  }
  return (
    <span style={newStyle} dangerouslySetInnerHTML={{ __html: result }}></span>
  );
}

export const compareLocaleText = (text1, text2) => {
  let test = new Intl.Collator("fi-u-co-trad");
  return test.compare(text1, text2);
};

export function isContainStarterKit(items) {
  let found = false;
  items.forEach(function (b) {
    supportedMarkets.forEach(function (s) {
      if (s.name === getCountryFull()) {
        if (b.item.id.unicity === s.starterKit.product_id) {
          found = true;
        }
      }
    });
  });
  return found;
}

export function extractPhoneFromRawText(rawText) {
  if (rawText) {
    let phone = rawText;
    let phoneSpl = phone.split("(");
    phone = phoneSpl[0];
    if (phoneSpl.length > 1) {
      if (/\d/.test(phoneSpl[1])) {
        phone = phoneSpl[1];
        phone = phone.replace(/\)/g, "");
      }
    }
    phone = phone.replace(/\s/g, "").replace(/\-/g, "");

    return phone;
  } else {
    return "";
  }
}

export function randomString(
  length,
  upperCase = true,
  lowerCase = true,
  numeric = true
) {
  let s = "",
    r = "";
  if (upperCase) r += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (lowerCase) r += "abcdefghijklmnopqrstuvwxyz";
  if (numeric) r += "0123456789";
  for (let i = 0; i < length; i++) {
    s += r.charAt(Math.floor(Math.random() * r.length));
  }
  return s;
}

export function randomNumber(length, noZeroFirst = false) {
  let s = "",
    r = "0123456789",
    r0 = "123456789";
  for (let i = 0; i < length; i++) {
    if (noZeroFirst === true && i === 0) {
      s += r0.charAt(Math.floor(Math.random() * r0.length));
    } else {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
  }
  return s;
}

export function isShowProductPrice() {
  if (isCustomerLogin() === false) {
    if (isCountryJapan()) {
      return false;
    }
  }
  return true;
}

export const getMarketCode = () => {
  switch (getCountryCode()) {
    case "AU":
      return "AU";
    case "NZ":
      return "NZ";
    default:
      return "SG";
  }
};
