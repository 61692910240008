// Constants & Methods for Shipping Methods - * Please do not setup any configs here. *
export const ShippingMethods = {
    ID: {
        /** ID = 2 */
        delivery: '2',
        /** ID = 3 */
        pickUp: '3',
        /** ID = 4 */
        aramex: '4',
        /** ID = 5 */
        pickUpHochiminh: '5',
        /** ID = 6 */
        motorDelivery: '6'
    },
    name: {
        delivery: 'delivery',
        pickUp: 'pickUp',
        aramex: 'aramex',
        pickUpHochiminh: 'pickUpHochiminh',
        motorDelivery: 'motorDelivery'
    },
    IDToName: {
        '2': 'delivery',
        '3': 'pickUp',
        '4': 'aramex',
        '5': 'pickUp', // Pick Up Hochiminh
        '6': 'motorDelivery'
    }
}