import { observable, decorate } from 'mobx';

export const language = observable({
    footer: null,
})

class MainStore {
    currency = 'USD'
    minStockQty = 0
    match = {}
    country = ''
    country_code = 'SG'
    indexTab = 0
    subIndexTab = 1
    subReportIndexTab = 1
    productData = []
    addressValidation = []
    newsDataSource = []
    mediaDataSource = []
    productDataSource = []
    loginHide = true
    seminarData = []

    language = {}
    flagCurrent = 'united-states.png'
    languageCurrent = null
    languagePrime = null
    languageFull = null
    dictionaryAll = {}
    isLanguageInitialized = false

    authPass = false
    customerData = {}
    currentPage = 0
    shopProfileIndexTab = 1
    shopProfileData = {}
    shopProfileProDataSource = {}
    shopProDataSort = {}
    shopProfileCart = []
    shopProfilelistCart = []
    shopProfileTotal = 0
    shopProfileTotalPv = 0
    shopProfileID = 0
    autoShipIndexTab = 1
    autoShipCart = []
    autoShipTotal = 0
    autoShipTotalPv = 0
    navigator = {
        menuPush: 0,
        routePush: 0,
        hideSubmenuInSidebar: false,
        display: '',
        submenu: false
    }
    imageState = true;
    catOpens = []
    marginTopMain = 0;
    toast = {
        show: false,
        type: 'default',
        position: 'topRight',
        title: 'Test',
        message: 'Test test test test'
    }
    isSideBarOpen = false
    legPeople = []
    homePage = 0
    mainMenu = []
    //Enroll
    provinces = []
    footer = {}
    messengerOpen = false
    isWebview = false
    deviceDetails = {}
    isIphone = false
    productRender = null
    filterProduct = false
    categories = null
    isHeaderNavigate = false

    isCheckCountry = false
    isShowingFeedback = false

    isLoadingOD = false
}

decorate(MainStore, {
    currency: observable,
    minStockQty: observable,
    match: observable,
    country: observable,
    country_code: observable,
    indexTab: observable,
    subIndexTab: observable,
    subReportIndexTab: observable,
    productData: observable,
    addressValidation: observable,
    newsDataSource: observable,
    mediaDataSource: observable,
    productDataSource: observable,
    loginHide: observable,
    seminarData: observable,

    language: observable.struct,
    flagCurrent: observable.struct,
    languageCurrent: observable.struct,
    languagePrime: observable.struct,
    dictionaryAll: observable.struct,
    isLanguageInitialized: observable.struct,

    authPass: observable,
    customerData: observable,
    currentPage: observable,
    shopProfileIndexTab: observable,
    shopProfileData: observable,
    shopProfileProDataSource: observable,
    shopProDataSort: observable,
    shopProfileCart: observable,
    shopProfilelistCart: observable,
    shopProfileTotal: observable,
    shopProfileTotalPv: observable,
    shopProfileID: observable,
    autoShipIndexTab: observable,
    autoShipCart: observable,
    autoShipTotal: observable,
    autoShipTotalPv: observable,
    navigator: observable,
    imageState: observable,
    catOpens: observable,
    marginTopMain: observable,
    toast: observable,
    isSideBarOpen: observable.struct,
    legPeople: observable,
    homePage: observable,
    mainMenu: observable,
    provinces: observable,
    footer: observable,
    messengerOpen: observable,
    isWebview: observable,
    deviceDetails: observable,
    isIphone: observable,
    productRender: observable,
    filterProduct: observable,
    categories: observable,
    isHeaderNavigate: observable,
    isCheckCountry: observable.struct,

    isLoadingOD: observable.struct
})

class ShopStore {
    gotStock = false;
    productDataSource = [];
    hotProduct = [];
    quickNavOpen = false
    quickNavOptions = []
    quickNavNext = null
    quickNavNextAfter = null
    siteSearch = ''
    passCheckOut = false
    validatedCartError = false
    total = 0
    totalPv = 0
    currencyRateJPY = 0
    enrollTotal = 0
    enrollTotalPv = 0
    enrollTotalLoading = false
    guestCheckout = false
}

decorate(ShopStore, {
    gotStock: observable,
    productDataSource: observable,
    hotProduct: observable,
    quickNavOpen: observable,
    quickNavOptions: observable,
    quickNavNext: observable,
    quickNavNextAfter: observable,
    siteSearch: observable,
    passCheckOut: observable,
    validatedCartError: observable,
    total: observable,
    totalPv: observable,
    currencyRateJPY: observable,
    enrollTotal: observable,
    enrollTotalPv: observable,
    enrollTotalLoading: observable,
    guestCheckout: observable
})

class EnrollStore {
    isCartFromShopping = false
    enrollProductSource = []
    enrollCart = []
    enrollQuickNavOpen = false
    enrollQuickNavOptions = []
    enrollQuickNavNext = null
    enrollQuickNavNextAfter = null
    errorPoints = []
    enrollState = false
    siteSearch = ''
    step = 1 // must start at 1
    stepBPass = false
    stepCPass = false
    isBAStatusActive = false
    agreeTCPass = false
    areas = []
    subAreas = []
    validationErrors = []
    sponsor;
    checkCalStore = []
    mainAddredShip = {
        city: "Singapore",
        country: "SG",
        state: "",
        zip: "099254",
        address1: "3 HarbourFront Place,",
        address2: "#01-01, HarbourFront Tower 2"
    }
    enrollData = {
        enroller: '',
        sponsor: '',
        enrollerName: '',
        sponsorName: '',
        enrollerNativeName: '',
        sponsorNativeName: '',
        govId: '',
        firstNameTh: '',
        lastNameTh: '',
        firstNameEn: '',
        lastNameEn: '',
        gender: 'none',
        maritalStatus: 'none',
        birthday: '',
        province: 'none',
        area: 'none',
        subArea: 'none',
        mainAddress: '',
        mainAddress2: '',
        country: 'none',
        city: '',
        zip: '',
        email: '',
        phone: '',
        mobile: '',
        password: '',
        password2: '',
        icFile: '',
        icFileBC: '',
        shippingmethod: '',
    }
}

decorate(EnrollStore, {
    isCartFromShopping: observable,
    enrollProductSource: observable,
    enrollCart: observable,
    enrollQuickNavOpen: observable,
    enrollQuickNavOptions: observable,
    enrollQuickNavNext: observable,
    enrollQuickNavNextAfter: observable,
    errorPoints: observable,
    enrollState: observable,
    siteSearch: observable,
    step: observable,
    stepBPass: observable,
    stepCPass: observable,
    isBAStatusActive: observable,
    agreeTCPass: observable,
    areas: observable,
    subAreas: observable,
    validationErrors: observable,
    sponsor: observable,
    checkCalStore: observable,
    mainAddredShip: observable,
    enrollData: observable,
})

class CheckOutStore {
    referralParam = ''
    currentStep = 0
    fullName = ''
    firstNameEn = ''
    lastNameEn = ''
    address1 = ''
    address2 = ''
    address1Native = ''
    address2Native = ''
    country = ''
    province = ''
    city = ''
    zip = ''
    email = ''
    mobilePhone = ''
    homePhone = ''
    confirmation = 1
    checkedConfirmation = true
    sns1 = ''
    sns2 = ''
    comment = ''
    checkout = false
    commissionMonth = ''
    listCommissionMonths = []
    items = []
    total = 0
    pv = 0
    cartMessage = ''
    orderTerms = {}
    shipping = '2'
    shipToTime = ''
    period = ''
    govId = ''
    birthDate = ''
    gender = 'none'
    referralPassword = ''
    referralCheckedPassword = false
    referralBy = 'by_referral_id'
    referralValue = ''
    referralAddress = {
        delivery: {},
        pickUp: {}
    }
    referralAddressPH = {
        delivery: {},
        sameDay: {},
        halfday: {},
        pickUpOrtigas: {},
        pickUpTaguig: {}
    }
    billingAddress = {
        delivery: {},
        deliveryMainAddress: {},
        aramex: {},
        pickUp: {},
        deliveryBackup: {}

    }
    billingAddressPH = {
        delivery: {},
        sameDay: {},
        halfday: {},
        pickUpOrtigas: {},
        pickUpTaguig: {}
    }
    addressBook = {
        firstLoaded: false,
        isModified: false,
        addressList: {},
        selectedAddressKey: {},
        href: '',
        showList: false,
        showFormEdit: true,
        showFormNew: false,
        tempNewAddress: {},
        showconfirmation: false
    }
    loadingValidate = false
    referenceDetail = {}
    paymentType = ''
    addressBookLoaded = false
    dToken = null
    // Subscribe Confirmation
    isReceivedEmail = true
    isReceivedInvoice = true
}

decorate(CheckOutStore, {
    referralParam: observable,
    currentStep: observable,
    fullName: observable,
    firstNameEn: observable,
    lastNameEn: observable,
    address1: observable,
    address2: observable,
    country: observable,
    province: observable,
    city: observable,
    zip: observable,
    email: observable,
    mobilePhone: observable,
    homePhone: observable,
    confirmation: observable,
    checkedConfirmation: observable,
    sns1: observable,
    sns2: observable,
    comment: observable,
    checkout: observable,
    commissionMonth: observable,
    listCommissionMonths: observable,
    items: observable,
    total: observable,
    pv: observable,
    cartMessage: observable,
    orderTerms: observable,
    shipping: observable,
    shipToTime: observable,
    period: observable,
    govId: observable,
    birthDate: observable,
    gender: observable,
    referralPassword: observable,
    referralCheckedPassword: observable,
    referralBy: observable,
    referralValue: observable,
    referralAddress: observable,
    referralAddressPH: observable,
    billingAddress: observable,
    billingAddressPH: observable,
    addressBook: observable,
    loadingValidate: observable,
    referenceDetail: observable,
    paymentType: observable,
    addressBookLoaded: observable,
    dToken: observable,
    isReceivedEmail: observable.struct,
    isReceivedInvoice: observable.struct,
})

class CreditCardStore {
    cardName = ''
    cardNumber = ''
    cardMonth = ''
    cardYear = ''
    cardCVV = ''
    validatePass = false
}

decorate(CreditCardStore, {
    cardName: observable,
    cardNumber: observable,
    cardMonth: observable,
    cardYear: observable,
    cardCVV: observable,
    validatePass: observable
})

class DictionaryStore {
    loading = false
    dictionary = {}
}

decorate(DictionaryStore, {
    dictionary: observable,
    loading: observable
})

class StaticFooter {
    footerApp = null
    footerContact = null
    footerHours = null
    footerSocial = null
    footerQuickLink = null
    footerHelp = null
    footerGeneral = null
    footerFeedBack = null
    footerDisclaimer = null
    footerBanner = null
    footerPromotion = null
    footerVideo = null
    footerOrder = null
    footerTextTitle = null
    footerMessenger = null
    footerMainPic = null
}

decorate(StaticFooter, {
    footerApp: observable,
    footerContact: observable,
    footerHours: observable,
    footerSocial: observable,
    footerQuickLink: observable,
    footerHelp: observable,
    footerGeneral: observable,
    footerFeedBack: observable,
    footerDisclaimer: observable,
    footerBanner: observable,
    footerPromotion: observable,
    footerVideo: observable,
    footerOrder: observable,
    footerTextTitle: observable,
    footerMessenger: observable
})

/** Observable parameters for rendering, loading API or other basic need. */
class StoreManager {
    // spinner handler
    isReadyToDisplayHome = false
    // local storage shortcut pass
    isLocalStorageAPI = false
    // footers handler
    isFooterDone = false
    isFooterStaticDone = false
}

decorate(
    StoreManager, {
    isReadyToDisplayHome: observable.struct,
    isLocalStorageAPI: observable.struct,
    isFooterDone: observable.struct,
    isFooterStaticDone: observable.struct,
}
)

/** Observable parameters for development tools. */
class DevelopmentTools {
    enabled = false
    devTopic = null
    devValue = null
    isSimulateLive = false
    isShowDevLog = false
    isShowInfoLog = false
    isSleepyTest = false
}

decorate(
    DevelopmentTools, {
    enabled: observable,
    devTopic: observable,
    devValue: observable,
    isSimulateLive: observable.struct,
    isShowDevLog: observable.struct,
    isShowInfoLog: observable.struct,
    isSleepyTest: observable.struct,
}
)

class SaveToAutoship {
    autoShipIndexTab = 0
    autoshipCart = null
}

decorate(
    SaveToAutoship, {
    autoShipIndexTab: observable.struct,
    autoshipCart: observable.struct
}
)

export const saveToAutoship = new SaveToAutoship()

export let store = new MainStore()
export let shopStore = new ShopStore()
export let defaultShopStore = new ShopStore()
export let enrollStore = new EnrollStore()
export let defaultEnrollStore = new EnrollStore()
export let checkOutStore = new CheckOutStore()
export let defaultCheckOutStore = new CheckOutStore()
export let checkOutEnrollStore = new CheckOutStore()
export let defaultCheckOutEnrollStore = new CheckOutStore()
export let shoppingCreditCardStore = new CreditCardStore()
export let enrollCreditCardStore = new CreditCardStore()
export let staticFooter = new StaticFooter()
export var dictionaryStore = new DictionaryStore()
export var storeManager = new StoreManager()
export var devTools = new DevelopmentTools()