import * as K from './Keywords'

/** Country code for get products API. */
export const Products = {
    [K.Australia]: 'AUS',
    [K.Newzealand]: 'NZL'
}

/** Login portal configs. */
export const Login = {
    [K.All]: true,
    [K.Australia]: false
}

// **************************
/** Easy shop configs. */
export const EasyShop = {
    [K.All]: false,
    [K.Australia]: true
}

export const EasyChoicePriceLevel = {
    [K.Australia]: {
        1: K.WholesalePrice, // distributor id
        2: K.PreferredPrice, // checkout as guest
        3: K.PreferredPrice  // referral code
    }
}

export const EasyChoiceCustomerType = {
    [K.Australia]: {
        1: K.Associate, // distributor id
        2: K.PreferredCustomer, // checkout as guest
        3: K.PreferredCustomer  // referral code
    }
}
// **************************

/** Country code for static footer. */
export const FooterStatic = {
    [K.Australia]: 'AUS',
    [K.Newzealand]: 'NZL'
}

/** Country code for special footer. */
export const FooterSpecial = {
    [K.Australia]: 'AUS',
    [K.Newzealand]: 'NZL'
}

// TODO: Added Messenger in Express
export const FacebookMessenger = {
    [K.Singapore]: 'https://www.messenger.com/t/UnicitySIN',
}

export const PeriodCode = {
    [K.Australia]: 'AU',
    [K.Newzealand]: 'NZ',
    [K.Hongkong]: 'XHK',
    [K.Indonesia]: 'XID',
    [K.Japan]: 'XJP',
    [K.Korea]: 'XKR',
    [K.Malaysia]: 'XMY',
    [K.Philippines]: 'XPH'
}