import { useEffect } from "react";
import { dictionaryStore, store } from "../../stores/MainStore";
import { getDictionary } from "./Services";

export const useDictionaries = async () => {
    dictionaryStore.loading = true;
    try {
        const response = await getDictionary();
        dictionaryStore.dictionary = response;
        dictionaryStore.loading = false
    } catch (error) {
        dictionaryStore.loading = false
    }
};

export const useTranslate = () => {
    const dictionaries = dictionaryStore.dictionary;
    const translate = (key) => dictionaries[store.languageCurrent.toLowerCase()][key] || "";

    return [translate];
};
