import $ from 'jquery'

// First priority JS files
import './utils/Bowser'
import '../stores/GlobalWatchers'

import { countryNumeralFormat } from './configs/Country'
import { devTools } from '../stores/MainStore'
import { getStoreLanguage, getCountry, isWordExist, getParameterByName, dictionary, getCountryCode3, isCountryJapan, isCountryAustralia } from './GlobalHelpers'
import { isUndefined, isNullOrUndefined, justOneOf, defaults } from './utils/Utils'
import { logn } from './utils/PikaLog'
import { isProduction } from './configs/ConfigsHeader'

// -- Debugging Parameters Control --
devTools.isSimulateLive = false
devTools.isShowDevLog = false
devTools.isShowInfoLog = false
// Live Production Simulation - used on Development stage
if (getParameterByName('live') === 'true') {
    // console.log('Enabled [Simulate Live]')
    devTools.isSimulateLive = true
}
// Enable Development Log on Production stage
if (getParameterByName('log') === 'true') {
    devTools.isShowDevLog = true
    // console.log('Enabled [DEV Logs]')
}
// Enable Information Logs
if (getParameterByName('info') === 'true') {
    devTools.isShowInfoLog = true
    // console.log('Enabled [Information Logs]')
}
// Enable Devtools
if (getParameterByName('dev') === 'true') {
    devTools.enabled = true
    console.log('Enabled [DevTools]')
}
// Enable Sleepy form auto-filled
if (justOneOf(getParameterByName('test'), '===', 'Sleepy', 'sleepy')) {
    devTools.isSleepyTest = true
    console.log('Enabled [Sleepy Test]')
}
// ----------------------------------

let pathname = window.location.pathname.split('/')
let location = window.location.hash

localStorage.setItem('country', pathname[1])
export const COUNTRY_NAME = pathname[1]
export const CURRENT_PATH = '/' + pathname[2]
export const VERSION_DEV = '1.2005.9999'

if (location === null) {
    location = pathname[1]
    localStorage.setItem('country', location)
} else {
    if (location !== pathname[1]) {
        location = pathname[1]
        localStorage.setItem('country', location)
        // localStorage.removeItem('language')
        localStorage.removeItem('provinces')
        localStorage.removeItem('top10products')
    }

}

export const appConfig = {
    appPath: location,
    assetPath: '/assets/',
    numeralFormat: numeral('int', COUNTRY_NAME.toLowerCase()),
    numeralDecimalFormat: numeral('decimal', COUNTRY_NAME.toLowerCase()),
    preferredPrice: false,
    country: COUNTRY_NAME,
    GACode: {
        malaysia: 'UA-128376146-1',
        indonesia: 'UA-128376146-2',
        philippines: 'UA-128376146-3',
        australia: 'UA-128376146-4',
        newzealand: 'UA-119346265-9',
        japan: '',
        mena: ''
    },
    GA4MeasurementID: {
        allMarkets: 'G-MPWKQSLRTV',
        malaysia: 'G-8P31JQV24P',
        indonesia: 'G-WH63DBHNFT',
        philippines: 'G-14JQCC24EX',
        australia: 'G-GM6HVT1LRY',
        newzealand: 'G-45W48MXKKD',
        japan: 'G-5QZWF82H83',
        mena: ''
    },
    language: {
        malaysia: ['EN'],
        indonesia: ['EN','ID'],
        philippines: ['EN'],
        australia: ['EN'],
        newzealand: ['EN'],
        japan: ['EN','JP'],
        mena: ['EN', 'AR'],
        hongkong: ['EN'],
        korea: ['KR', 'EN']
    },
    languageName: {
        SG: ['English'],
        TH: ['ภาษาไทย'],
        JP: ['Japanese'],
        EN: ['English'],
        MY: ['English'],
        VN: ['Vietnamese'],
        CN: ['中文'],
        ID: ['Bahasa'],
        KR: ['Korean'],
        AU: ['Australia'],
        NZ: ['New Zealand'],
        AR: ['Arabic'],
        HK: ['中文']
    },
    flags: {
        EN: 'united-states.png',
        MY: 'malaysia.png',
        ID: 'indonesia.png',
        PH: 'philippines.png',
        KR: 'korea.png',
        AU: 'australia.png',
        NZ: 'newzealand.png',
        JP: 'japan.png',
        AR: 'arabic.png',
        HK: 'hongkong.png'
    },
    tax: {
        philippines: false,
        indonesia: false,
        malaysia: false,
        korea: false,
        australia: false,
        newzealand: false,
        japan: false,
        mena: false,
        hongkong: false
    },
    pvRange: {
        JP: [0, 1000],
        TH: [0, 1000],
        SG: [0, 1000],
        PH: [0, 1000],
        VN: [0, 1000],
        ID: [0, 1000],
        MY: [0, 1000],
        KR: [0, 10000],
        AU: [0, 10000],
        NZ: [0, 10000],
        JP: [0, 10000],
        ME: [0, 10000],
        HK: [0, 10000]
    },
    priceRange: {
        JP: [0, 20000],
        TH: [0, 20000],
        SG: [0, 20000],
        PH: [0, 20000],
        VN: [0, 20000],
        ID: [0, 20000],
        MY: [0, 1000],
        KR: [0, 1000],
        AU: [0, 10000],
        NZ: [0, 10000],
        JP: [0, 10000],
        ME: [0, 10000],
        HK: [0, 10000]
    },
    inputRange: {
        JP: [0, 1000],
        TH: [0, 1000],
        SG: [0, 1000],
        PH: [0, 1000],
        VN: [0, 1000],
        ID: [0, 1000],
        MY: [0, 1000],
        KR: [0, 1000],
        AU: [0, 1000],
        NZ: [0, 1000],
        JP: [0, 1000],
        ME: [0, 1000],
        HK: [0, 1000]
    },
    fbCustomerChatPageId: {
        thailand: '172740789986067',
        singapore: '399302273752593',
        japan: '',
        philippines: '',
        vietnam: '',
        indonesia: '',
        korea: '',
        australia: '',
        newzealand: '399302273752593',
        japan: '',
        mena: '',
        hongkong: ''
    },
    // -----------------------
    // -- Country Constant --
    countryCode: {
        singapore: 'SG',
        malaysia: 'MY',
        indonesia: 'ID',
        philippines: 'PH',
        australia: 'AU',
        newzealand: 'NZ',
        japan: 'JP',
        mena: 'ME',
        hongkong: 'HK',
        korea: 'KR'
    },
    countryCode3: {
        singapore: 'SGP',
        malaysia: 'MYS',
        indonesia: 'IDN',
        philippines: 'PHL',
        australia: 'AUS',
        newzealand: 'NZL',
        japan: 'JPN',
        mena: 'XME',
        hongkong: 'HKG',
        korea: 'KOR'
    },
    countryExpress: {
        singapore: 'XSG',
        malaysia: 'XMY',
        indonesia: 'XID',
        philippines: 'XPH',
        australia: 'XAU',
        newzealand: 'XNZ',
        japan: 'XJP',
        mena: 'XME',
        hongkong: 'XHK',
        korea: 'XKR'
    },
    countryFullDisplay: {
        singapore: 'Singapore',
        malaysia: 'Malaysia',
        indonesia: 'Indonesia',
        philippines: 'Philippines',
        australia: 'Australia',
        newzealand: 'New Zealand',
        japan: 'Japan',
        mena: 'MENA',
        hongkong: 'Hong Kong',
        korea: 'Korea'
    },
    countryCodeToFullDisplay: {
        SG: 'Singapore',
        MY: 'Malaysia',
        ID: 'Indonesia',
        PH: 'Philippines',
        AU: 'Australia',
        NZ: 'New Zealand',
        JP: 'Japan',
        ME: 'MENA',
        HK: 'Hong Kong',
        KR: 'Korea'
    },
    countryFull: {
        singapore: 'singapore',
        malaysia: 'malaysia',
        indonesia: 'indonesia',
        philippines: 'philippines',
        australia: 'australia',
        newzealand: 'newzealand',
        japan: 'japan',
        mena: 'mena',
        hongkong: 'hongkong',
        korea: 'korea'
    },
    countryCodeToFull: {
        SG: 'singapore',
        MY: 'malaysia',
        ID: 'indonesia',
        PH: 'philippines',
        AU: 'australia',
        NZ: 'newzealand',
        JP: 'japan',
        ME: 'mena',
        HK: 'hongkong',
        KR: 'korea'
    },
    // -----------------------
    // -- Language Constant --
    languageCode: {
        english: 'EN',
        philippines: 'PH',
        vietnamese: 'VN',
        bahasa: 'ID',
        arabic: 'AR',
        japanese: 'JP',
        korean: 'KR',
    },
    // ----------------------------------
    // -- Shipping & Customer Constant --
    customerType: {
        associate: 'Associate'
    },
    shippingMethod: {
        AU: ['delivery'],
        ID: ['delivery'],
        NZ: ['delivery'],
        PH: ['delivery'],
        MY: ['delivery'],
        JP: ['delivery'],
        ME: ['delivery'],
        HK: ['delivery'],
        KR: ['delivery']
    },
    shippingMethodType: {
        expedited: 'Expedited',
        economy: 'Economy'
    },
    shippingMethodID: {
        delivery: '2',
        pickup: '3',
        aramex: '4',
        motorDelivery: '6'
    },
    shippingMethodIDToName: {
        '2': 'delivery',
        '3': 'pickUp',
        '4': 'aramex',
        '6': 'motorDelivery'
    },
    // -----------------------
    hostname: {
        localhost: 'localhost',
        development: 'dev',
        production: 'production'
    },
    footerLanguageControl: {
        english: 'english',
        native: 'native'
    }
}

export function calculateDistance(pointA, pointB) {

    // const lat1 = pointA.coordinates[2].latitude;
    // const lon1 = pointA.coordinates[2].longitude;
  
    // const lat2 = pointB.coordinates[1].latitude;
    // const lon2 = pointB.coordinates[1].longitude;
    const lat1 = pointA.latitude
    const lon1 = pointA.longitude

    const lat2 = pointB.latitude
    const lon2 = pointB.longitude
    
    const R = 6371e3; // earth radius in meters
    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);
  
    const a = (Math.sin(Δφ / 2) * Math.sin(Δφ / 2)) +
              ((Math.cos(φ1) * Math.cos(φ2)) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = R * c;
    return distance; // in meters
}

export function numeral(type, country) {
    return '0,0'
    let format = countryNumeralFormat[country]
    if (format === undefined) {
        format = {
            numeralFormat: '0,0',
            numeralDecimalFormat: '0,0.00'
        }
    }
    if (type === 'decimal') {
        return format.numeralDecimalFormat
    } else {
        return format.numeralFormat
    }
}

export const supportedMarkets = [
    {
        name: 'thailand',
        title: 'UNICITY (TH)',
        code: 'TH',
        currency: 'THB',
        starterKit: {
            product_id: '27051',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: '43',
            image: 'https://member-calls4.unicity.com/media/unishop/sgp/sku/27051.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|'
        }
    },
    {
        name: 'singapore',
        title: 'UNICITY (SG)',
        code: 'SG',
        currency: '',
        starterKit: {
            product_id: '27051',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 43,
            image: 'https://member-calls4.unicity.com/media/unishop/sgp/sku/27051.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|'
        }
    },
    {
        name: 'japan',
        title: 'UNICITY (JP)',
        code: 'JP',
        currency: '',
        starterKit: {
            product_id: '27051',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 43,
            image: 'https://member-calls4.unicity.com/media/unishop/sgp/sku/27051.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|'
        }
    },
    {
        name: 'newzealand',
        title: 'UNICITY (NZ)',
        code: 'NZ',
        currency: 'NZD',
        starterKit: {
            product_id: '31440',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 43,
            image: 'https://member-calls4.unicity.com/media/unishop/sgp/sku/27051.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|'
        }
    },
    {
        name: 'australia',
        title: 'UNICITY (AU)',
        code: 'AU',
        currency: 'AUD',
        starterKit: {
            product_id: '31440',
            post_title: 'Starter Kit',
            pv: 0,
            member_price: 0,
            image: 'https://member-calls4.unicity.com/media/unishop/sgp/sku/27051.jpg',
            qty: 1,
            stock: '99',
            status: '1',
            allow_backorder: '1',
            remarks: '|enroll|'
        }
    }
]


export const shippingAddress = {
    aramexAddress: {
        address1: 'Aramax (AIC Dept.) 3 Changi South Street 1, #01-01',
        address2: 'Santa United International  Bldg.',
        mobilePhone: '6565430300',
        city: 'Singapore',
        country: 'SG',
        zip: '486795'
    },
    pickUpAddress: {
        fullName: 'Singapore Main Office',
        address1: '3 HarbourFront Place',
        address2: '#01-01, HarbourFront Tower 2',
        city: 'Singapore',
        country: 'SG',
        zip: '099254'
    }
}

export const ACL = {
    A: {
        description: 'Active',
        api: {
            type: 'Associate',
            status: 'Active',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'A'),
        showPv: true
    },
    B: {
        description: 'Pending',
        api: {
            type: 'Associate',
            status: 'Pending',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'B'),
        showPv: true
    },
    C: {
        description: 'Customer',
        api: {
            type: 'Customer',
            status: 'Active',
            priceLevel: 'retail_price'
        },
        allowed: allowLogin(location, 'C'),
        showPv: false
    },
    D: {
        description: 'Balance (CHE, DEU), Defective (JP)',
        api: {
            type: 'Associate',
            status: 'Defective',
            priceLevel: null
        },
        allowed: allowLogin(location, 'D'),
        showPv: false
    },
    E: {
        description: 'Employee',
        api: {
            type: 'Employee',
            status: 'Active',
            priceLevel: priceLevel(location, 'E')
        },
        allowed: allowLogin(location, 'E'),
        showPv: false
    },
    F: {
        description: 'Family Pricing',
        api: {
            type: 'Family',
            status: 'Active',
            priceLevel: priceLevel(location, 'F')
        },
        allowed: allowLogin(location, 'F'),
        showPv: false
    },
    H: {
        description: 'Wholesale Customer',
        api: {
            type: 'WholesaleCustomer',
            status: 'Active',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'H'),
        showPv: false
    },
    M: {
        description: 'Member',
        api: {
            type: 'Member',
            status: 'Active',
            priceLevel: 'wholesale_price'
        },
        allowed: allowLogin(location, 'M'),
        showPv: false
    },
    P: {
        description: 'Preferred Customer',
        api: {
            type: 'PreferredCustomer',
            status: 'Active',
            priceLevel: 'preferred_price'
        },
        allowed: allowLogin(location, 'P'),
        showPv: false
    },
    R: {
        description: 'Retired',
        api: {
            type: 'Associate',
            status: 'Retired',
            priceLevel: null
        },
        allowed: allowLogin(location, 'R'),
        showPv: false
    },
    S: {
        description: 'Suspended',
        api: {
            type: 'Associate',
            status: 'Suspended',
            priceLevel: null
        },
        allowed: allowLogin(location, 'S'),
        showPv: false
    },
    T: {
        description: 'Terminated',
        api: {
            type: 'Associate',
            status: 'Terminated',
            priceLevel: null
        },
        allowed: allowLogin(location, 'T'),
        showPv: false
    }
}

export function allowLogin(country, type) {
    let allowed = false
    switch (type) {
        case 'A': allowed = 'profile'
            break
        case 'B': allowed = false
            break
        case 'C': allowed = false
            break
        case 'D': allowed = false
            break
        case 'E': allowed = false
            break
        case 'F': allowed = false
            break
        case 'H': allowed = false
            break
        case 'M': allowed = false
            break
        case 'N': allowed = false
            break
        case 'P': allowed = false
            break
        case 'R': allowed = false
            break
        case 'S': allowed = false
            break
        case 'T': allowed = false
            break
        default: allowed = false
            break
    }

    // extra allowed status for each country
    switch (country) {
        case 'australia':
            if (justOneOf(type, '===', 'B', 'P', 'M')) {
                allowed = 'profile'
            }
            break
        case 'newzealand':
            if (justOneOf(type, '===', 'P', 'M')) {
                allowed = 'profile'
            }
            /*  if (justOneOf(type, '===', 'F')) {
                allowed = 'profile'
            } */
            break
        case 'korea':
            if (justOneOf(type, '===', 'B'/* , 'P', 'E' */)) { // พี่อ้นบอกให้เอา E, P ออก // 14 JAN 2020
                allowed = 'profile'
            }
            break
        case 'hongkong':
            if (justOneOf(type, '===', 'B', 'N')) {
                allowed = 'profile'
            }
            break
        case 'japan': 
            if(justOneOf(type, '===', 'H')) {
                allowed = 'profile'
            }
            break
        default: break
    }

    // if (isProduction() === false) {
        if (allowed !== false) {
            console.log(`Status[${type}] > [${allowed}] allowed.`)
        }
    // }

    return allowed
}


export function priceLevel(country, status) {
    
    const list = {
        A: 'wholesale_price',
        B: null,
        C: null,
        D: null,
        E: 'employee_price',
        F: 'employee_price',
        H: null,
        M: null,
        N: null,
        P: null,
        R: null,
        S: null,
        T: null,
    }

    return list[status]
}

export const AllowList = {
    full: true,
    public: {
        sideBar: ['home', 'product_catalogue', 'shopping', 'enroll', 'feedback', 'faq']
    },
    profile: {
        sideBar: ['home', 'product_catalogue', 'shopping', 'enroll', 'feedback', 'faq', 'profile', 'autoship', 'referral', 'announcement', 'logout']
    },
    inPageMenu: {
        profile: profileMenu(location)
    }
}

export function profileMenu() {

    return [
        {
            key: 'profile',
            dictionary: 'profile',
            title: 'Profile',
            link: '/profile',
            icon: 'svg profile-dashboard',
            allowed: ['full', 'profile']
        },
        /* {
            key: 'announcement',
            dictionary: 'announcement',
            title: 'Announcement',
            link: '/announcement',
            icon: 'fa fa-bullhorn',
            allowed: ['full', 'profile'],
            countries: ['AU','NZ', 'PH', 'JP', 'KR']
        }, */
        {
            key: 'autoship',
            dictionary: 'autoship',
            title: 'Autoship',
            link: '/autoship',
            icon: 'fa fa-gg',
            allowed: ['full', 'profile'],
            countries: ['AU', 'NZ'],
            onlyStatus: ['M']
        },
        {
            key: 'order_history',
            dictionary: 'order_history',
            title: 'Order History',
            link: '/orders',
            icon: 'svg profile-order-history',
            allowed: ['full', 'profile']
        },
        {
            key: 'referral',
            dictionary: 'x_all_profile_menu_referral_links',
            title: 'Referral Links', 
            link: '/referral-links',
            icon: 'svg profile-referral-links',
            allowed: ['full', 'profile'],
            countries: ['AU','NZ']
        }
    ]
}

export function HeaderMenu() {

    return [
        {
            key: 'home',
            title: dictionary('home').toUpperCase(),
            path: `/`,
            children: [],
            auth: false,
            
        },
        {
            key: 'product_catalogue',
            title: 'Product Catalogue',
            path: `https://ushop-media.unicity.com/flip/AUS/aus-catalogue`,
            children: [],
            auth: true,
            countries: ['AU']
        },
        {
            key: 'shopping',
            title: dictionary('shopping').toUpperCase(),
            path: `/${location}/shop`,
            children: [],
            auth: false
        },
        {
            key: 'enroll',
            title: dictionary('enroll').toUpperCase(),
            path: `/${location}/enroll`,
            children: [],
            auth: false,
            countries: ['AU','NZ'],
            exceptStatus: ['M']
        },
        /* {
            key: 'contact_us',
            title: language.contact_us.toUpperCase(),
            path: `/${location}/contactus`,
            children: [],
            auth: false,
            countries: ['SG','PH','TH','VN','ID','AE', 'JP', 'IN', 'US', 'BH','HK']
        }, */
        {
            key: 'feedback',
            title: dictionary('feedback').toUpperCase(),
            path: `/${location}/feedback`,
            children: [],
            auth: false,
            onClick: () => {  }
        },
    ]
}

export function Menu(language) {
    let path = pathname

    const result = [
        {
            key: 'home',
            title: 'home',
            path: `/`,
            children: [],
            auth: false
        },
        {
            key: 'product_catalogue',
            title: 'product_catalogue',
            path: `https://ushop-media.unicity.com/flip/AUS/aus-catalogue`,
            children: [],
            auth: true,
            countries: ['AU']
        },
        {
            key: 'shopping',
            title: 'shopping',
            path: `/shop`,
            children: [],
            auth: false
        },
        {
            key: 'enroll',
            title: 'enroll',
            path: `/enroll`,
            children: [],
            auth: false,
            countries: ['AU','NZ'],
            exceptStatus: ['M']
        },
        {
            key: 'profile',
            title: 'profile',
            path: `/profile`,
            children: [],
            auth: true
        },
        {
            key: 'autoship',
            dictionary: 'autoship',
            title: 'autoship',
            path: `/autoship`,
            children: [],
            auth: true,
            countries: ['AU', 'NZ'],
            onlyStatus: ['A', 'M']
        },
        {
            key: 'referral',
            dictionary: 'x_all_profile_menu_referral_links',
            title: 'referral',
            path: `/referral-links`,
            children: [],
            auth: true,
            countries: ['AU','NZ']
        },
        {
            key: 'announcement',
            title: 'announcement',
            path: '/announcement',
            children: [],
            auth: true,
            countries: []
        },
        {
            key: 'feedback',
            title: 'feedback',
            path: `/feedback`,
            children: [
                {
                    title: '',
                    path: '',
                    children: [],
                    auth: true,
                },
            ],
            auth: false
        },
        
    ]

    if (isCountryJapan()) {
        result.push(
            {
                key: 'faq',
                title: 'x_all_sidebar_faq',
                path: `/faq`,
                children: [],
                auth: false
            }
        )
    }

    result.push(
        {
            key: 'logout',
            title: 'logout',
            path: `/logout`,
            children: [],
            auth: true
        }
    )

    return result
}

/** 
 * @deprecated since we need to avoid jQuery usage. **This method will be removed soon.**
 * Please use `testArray()` instead. 
 * @see Utils 
 */
export const inArray = (needle, arrayList) => {
    return $.inArray(needle, arrayList) > -1 ? true : false
}

export const exceptCountry = (countryCode) => {
    let country = {
        KH: true,
        LA: true,
        MM: true

    }
    return country[countryCode]
}
export const showInputRange = (status) => {
    if(status.id !== undefined){
        switch(status.type){
            case 'Customer': {
                return false;
            }
            case 'PreferredCustomer':{
                return false;
            }
            default:{
                return true;
            }
        }
    }else{
        return false
    }
}

export const currencyBehindPrice = (countryCode) => {
    let countries = {
        MY: 'right',
        ID: 'left',
        PH: 'right',
        AU: 'right',
        NZ: 'right',
        JP: 'right'
    }

    return countries[countryCode]
}

export const momentLocale = (country, language) => {
    const defaultLocale = 'en'
    const locales = {
        australia:   {'EN': 'en-au'},
        cambodia:    {'EN': defaultLocale, 'KH': 'km'},
        hongkong:    {'EN': defaultLocale, 'CN': 'zh-hk', 'HK': 'zh-hk'},
        indonesia:   {'EN': defaultLocale, 'ID': 'id'},
        japan:       {'EN': defaultLocale, 'JP': 'ja'},
        malaysia:    {'EN': defaultLocale},
        myanmar:     {'EN': defaultLocale, 'MY': 'ms-my'},
        newzealand:  {'EN': 'en-nz'},
        philippines: {'EN': defaultLocale, 'PH': defaultLocale},
        singapore:   {'EN': defaultLocale},
        thailand:    {'EN': defaultLocale, 'TH': 'th'},
        vietnam:     {'EN': defaultLocale, 'VN': 'vi'}
    }
    return isNullOrUndefined(locales[country]) ? defaultLocale : 
        isNullOrUndefined(locales[country][language]) ? defaultLocale : 
        locales[country][language]
}

export const momentDateFormat = (options = {}) => {
    let country = getCountry()
    let language = getStoreLanguage()
    if (isUndefined(options.country) === false) {
        country = options.country
    }
    if (isUndefined(options.language) === false) {
        language = options.language
    }

    const defaultFormat = 'DD MMM YYYY'
    const format = {
        australia:   {'EN': defaultFormat},
        cambodia:    {'EN': defaultFormat, 'KH': defaultFormat},
        hongkong:    {'EN': defaultFormat, 'CN': defaultFormat, 'HK': defaultFormat},
        indonesia:   {'EN': defaultFormat, 'ID': defaultFormat},
        japan:       {'EN': defaultFormat, 'JP': 'DD日 M月 YYYY年'},
        malaysia:    {'EN': defaultFormat},
        myanmar:     {'EN': defaultFormat, 'MY': defaultFormat},
        newzealand:  {'EN': defaultFormat},
        philippines: {'EN': defaultFormat, 'PH': defaultFormat},
        singapore:   {'EN': defaultFormat},
        thailand:    {'EN': defaultFormat, 'TH': defaultFormat},
        vietnam:     {'EN': defaultFormat, 'VN': 'DD MMMM YYYY'}
    }
    return isNullOrUndefined(format[country]) ? defaultFormat : 
        isNullOrUndefined(format[country][language]) ? defaultFormat : 
        format[country][language]
}

export const momentTimeFormat = (options = {}) => {
    let country = getCountry()
    let language = getStoreLanguage()
    if (isUndefined(options.country) === false) {
        country = options.country
    }
    if (isUndefined(options.language) === false) {
        language = options.language
    }

    let defaultFormat = 'hh:mm A'
    let separator = ':'
    
    const listFormat = {
        australia:   {'EN': defaultFormat},
        cambodia:    {'EN': defaultFormat, 'KH': defaultFormat},
        hongkong:    {'EN': defaultFormat, 'CN': defaultFormat, 'HK': defaultFormat},
        indonesia:   {'EN': defaultFormat, 'ID': defaultFormat},
        japan:       {'EN': defaultFormat, 'JP': 'HH:mm'},
        malaysia:    {'EN': defaultFormat},
        myanmar:     {'EN': defaultFormat, 'MY': defaultFormat},
        newzealand:  {'EN': defaultFormat},
        philippines: {'EN': defaultFormat, 'PH': defaultFormat},
        singapore:   {'EN': defaultFormat},
        thailand:    {'EN': defaultFormat, 'TH': defaultFormat},
        vietnam:     {'EN': defaultFormat, 'VN': defaultFormat}
    }

    let format = isNullOrUndefined(listFormat[country]) ? defaultFormat : 
        isNullOrUndefined(listFormat[country][language]) ? defaultFormat : 
        listFormat[country][language]

    if (isUndefined(options.format) === false) {
        if (options.format === '12') {
            format = format.replace('HH', 'hh')
        } else if (options.format === '24') {
            format = format.replace('hh', 'HH')
        }
    }

    if (isUndefined(options.second) === false) {
        if (options.second === true) {
            if (isWordExist(format, `${separator}ss`) === false) {
                if (isWordExist(format, 'mm')) {
                    format = format.replace('mm', `mm${separator}ss`)
                }
            }
        } else {
            if (isWordExist(format, `${separator}ss`)) {
                format = format.replace(`${separator}ss`, '')
            }
        }
    }

    if (isUndefined(options.separator) === false) {
        format = format.replace(separator, options.separator)
    }

    if (isUndefined(options.meridiem) === false) {
        format = format.replace('A', '')
    }

    format = format.trim()
    
    return format
}

export const fullDateFormat = (country) => {
    let fullDate = {
        SG: 'DD MMM YYYY',
        TH: 'DD MMM YYYY',
        PH: 'DD MMM YYYY',
        JP: 'DD日 M月 YYYY年',
        EN: 'DD MMM YYYY',
        MY: 'DD MMM YYYY',
        VN: 'DD MMMM YYYY',
        CN: 'DD MMM YYYY',
        AE: 'DD MMM YYYY',
        ID: 'DD MMM YYYY',
        TW: 'DD MMM YYYY',
        HK: 'DD MMM YYYY',
        KH: 'DD MMM YYYY',
        LA: 'DD MMM YYYY',
        MM: 'DD MMM YYYY',
        KR: 'DD MMM YYYY',
    }

    return fullDate[country]
}

export const monthYearFormat = (language) => {
   
    let monthYear = {
        SG: 'YYYY-MMM', // MM = 01, 02 ; MMM = JAN, NOV
        TH: 'YYYY-MM',
        PH: 'YYYY-MM',
        JP: 'YYYY-MMM', // MMM = 1月, 2月
        EN: 'YYYY-MM',
        MY: 'YYYY-MM',
        VN: 'YYYY-MM',
        CN: 'YYYY-MM',
        AE: 'YYYY-MM',
        ID: 'YYYY-MM',
        TW: 'YYYY-MM',
        HK: 'YYYY-MM',
        KH: 'YYYY-MM',
        LA: 'YYYY-MM',
        MM: 'YYYY-MM',
        KR: 'YYYY-MM',
    }

    return monthYear[language]
}

export const rankNames = (rank, language) => {
    const ranks = [
        '-',
        'Dst',
        'Ph1',
        'Mgr',
        'SrM',
        'ExM',
        'Dir',
        'SrD',
        'ExD',
        'PrD',
        'PrS',
        'PrR',
        'DIA',
    ]

    return ranks[rank]
}

export const fullRanks = () => {
    return [
        'Dst',
        'Ph1',
        'Mgr',
        'SrM',
        'ExM',
        'Dir',
        'SrD',
        'ExD',
        'PrD',
        'PrS',
        'PrR',
        'DIA',
    ]
}

export const TimeZones = (country) => {
    const timeZones = {
        thailand: 'Asia/Bangkok',
        singapore: 'Asia/Singapore',
        japan: 'Asia/Tokyo',
        malaysia: 'Asia/Kuala_Lumpur',
        philippines: 'Asia/Manila',
        vietnam: 'Asia/Ho_Chi_Minh',
        indonesia: 'Asia/Jakarta',
        uae: 'Asia/Dubai',
        taiwan: 'Asia/Taipei',
        hongkong: 'Asia/Hong_Kong',
        cambodia: 'Asia/Bangkok',
        laos: 'Asia/Bangkok',
        myanmar: 'Asia/Bangkok',
        australia: 'Australia/Sydney',
        newzealand: 'Pacific/Auckland',
        korea: 'Asia/Seoul'
    }

    return timeZones[country]
}

export const bestSelling = (countryCode) => {
    /* let countries = {
        EN: true,
        MY: true,
        PH: true,
        JP: true,
        ID: true,
        AU: true,
        NZ: true,
        ME: true
    } */
    return true//ountries[countryCode]
}