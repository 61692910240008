import React, { Component } from 'react'
import { ReactComponent as UpIcon } from '../assets/up-icon-new.svg'
import { getCountryCode } from './GlobalHelpers'

class ScrollButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            intervalId: 0,
            scrollBtnClasses: 'topsclicked',
            showFilter: false,
            exceptCountry: ['AU', 'NZ']
        }

        this.onFilterClick = this.onFilterClick.bind(this)
    }

    onFilterClick() {
        this.setState({ showFilter: !this.state.showFilter })
        setTimeout(() => {
            this.props.onFilterClick(this.state.showFilter);
        }, 200);
    }

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentDidMount() {
        
        if (this.props.filter !== undefined) {
            if (this.props.filter) {
                this.setState({ scrollBtnClasses: 'topsclicked visible' })
            }
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this))
        clearInterval(this.state.intervalId);
    }

    shouldComponentUpdate(nextProps, nextState) {
        clearInterval(this.state.intervalId);
        return true
    }

    handleScroll(event) {
        let scrollTop = window.pageYOffset
        
        if(this.props.filter){
            if (scrollTop > -150) {
                this.setState({
                    scrollBtnClasses: 'topsclicked visible'
                })

            } else {
                this.setState({
                    scrollBtnClasses: 'topsclicked'
                })
            }
        }else{
            
            if (scrollTop > 200) {
                this.setState({
                    scrollBtnClasses: 'topsclicked visible'
                })
            } else {
                this.setState({
                    scrollBtnClasses: 'topsclicked'
                })
            }
        }
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId)
        }
        window.scroll(0,0)
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    render() {
        if (this.state.exceptCountry.find(item => item === getCountryCode())) {
            return <></>
        } else {
            return <UpIcon className={this.state.scrollBtnClasses} onClick={() => this.scrollToTop()}/>
        }
    }
}
export default ScrollButton